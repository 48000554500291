import React, { useEffect, useState } from "react";
// material
import {
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  AppBar,
  Container,
  Paper
} from '@mui/material';
import PropTypes from 'prop-types';

//components
import AssetDashboard from './AssetDashboard';
import BatteryDashboard from './BatteryDashboard'
import Trips from './Trips';
import ChargeSessions from "./ChargeSessions";
import Page from '../../components/Page';
import { styled } from '@mui/material/styles';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography sx={{mt:2}}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const StyledTabs = styled((props)  => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    position:'sticky',
    justifyContent: 'center',
    height:'4px',
    borderTopLeftRadius:'8px',
    borderTopRightRadius:'8px',
    backgroundColor: theme.palette.tabColors.selectedMenuBorder,
  },
  '& .MuiTabs-indicatorSpan': {
    width: '3px'
  },
})
);
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'capitalize',
    fontWeight: 500,
    fontSize:'14px',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    marginRight: theme.spacing(1),
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    color: theme.palette.subtitle1.main,
    backgroundColor: 'transparent',
    '&.Mui-selected': {
      color: theme.palette.tabColors.slectedText,
      backgroundColor: 'transparent',

    },
    '&.Mui-focusVisible': {
      color: 'red',
      backgroundColor: 'red'
    },
  }),
);;

export default function DashbaordTabs(props) {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title={'AssetDashboard'}>
        <Container maxWidth="xl">
          <Box sx={{ width: '100%' }}>
            <Paper  sx={{backgroundColor:'primary.contrastText', width:'70%', margin: '0 auto', boxShadow: '0px 0px 10px rgba(212, 224, 231, 0.5)', borderRadius:'0px',clipPath: 'polygon(0% 0%,20px 0%,calc(100% - 20px) 0%,100% 0,100% calc(30% - 20px),calc(100% - 20px) 100%,20px 100%,0% calc(30% - 20px))'}}>
             <StyledTabs
                value={value}
                centered
                onChange={handleChange}
                aria-label="styled tabs example"
              >
                <StyledTab label="Dashboard" />
                {props.type === 'battery' ?  <StyledTab label="Charge Sessions" /> : <StyledTab label="Journey Analysis" />} 
                <StyledTab label="Command & Control" />
                <StyledTab label="Configuration & Settings" />
              </StyledTabs>
              </Paper>
              <TabPanel value={value} index={0}>
               {props.type === 'battery' ? <BatteryDashboard /> : <AssetDashboard />} 
              </TabPanel>
              <TabPanel value={value} index={1}>
              {props.type === 'battery' ? <ChargeSessions /> : <Trips />} 
               </TabPanel>
               <TabPanel value={value} index={2}>
                <Box sx={{width: '100%', height: '80vh', display:"flex", justifyContent: 'center', alignItems:'center'}}>
                     <Typography  component="h2"  variant="div" color="text.primary">Coming Soon...</Typography>
                </Box>
               </TabPanel>
               <TabPanel value={value} index={3}>
                <Box sx={{width: '100%', height: '80vh', display:"flex", justifyContent: 'center', alignItems:'center'}}>
                     <Typography  component="h2"  variant="div" color="text.primary">Coming Soon...</Typography>
                </Box>
               </TabPanel>
          </Box>
        </Container>
    </Page>
  );
}
