import React, { useEffect, useState } from "react";

// material
import {
  Grid,
  Box,
  Typography,
  Card,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  TextField,
  Stack,
} from '@mui/material';

import Enterprise from '../../../Services/enterprise';
import Autocomplete from '@mui/material/Autocomplete';


function DetailsItem({ name, value }) {
  return (
    <>
     <Paper elevation={24} sx={{width:'100%', px:2, py:2, mt:1}}>
      <Grid container> 
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="subtitle">{name}</Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>{value}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default function AllocateDevice({ openForm, handleCreateModal, details, updateData, title, totalEnterprises}) {
  const [value, setValue] = useState(null);
 
  const handleClose = () => {
    handleCreateModal(false);
  }
  const handleAllocate = () =>{
    let body = {
      commGatewayBID: details.commGatewayBID,
       commGatewayID : {
        ...details.commGatewayID
      },
       connectionDetail: {
        ...details.connectionDetail
      },
      enterpriseID: value.enterpriseID
    }
    Enterprise.allocateDevice(body).then((result) => {
       handleClose();
       updateData();
    },
    () => {
      handleClose();
    });
  }
  const handleEnterpriseChange = (event, newValue) => {
    setValue(newValue);  
  }

   useEffect(() => {
    if (totalEnterprises.length > 0) {
      if(details.enterpriseID){
        let index = 0;
        for (let i = 0; i < totalEnterprises.length; i++) {
          if (totalEnterprises[i].enterpriseID === details.enterpriseID) {
              index = i;
              break;
          }
        }
        setValue(totalEnterprises[index]);
      } else {
        setValue(totalEnterprises[0]);
      }
    }
  }, [totalEnterprises, details]);

  return (
    <Dialog fullWidth maxWidth={'sm'} open={openForm} onClose={handleClose} onBackdropClick={handleClose} transitionDuration={{ enter: 500, exit:0 }}>
       <DialogTitle>{title} Device</DialogTitle>
      <DialogContent  sx={{ overflowY: 'hidden'}}>
         <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Card sx={{ px: 2, py: 2 }}>
                 <CardHeader title={details.assetInfo  && details.commGatewayID ? `${details.assetInfo.assetCategory} | ${details.assetInfo.assetType}` : ''}/>
                 <DetailsItem name={'Comm Gateway BID'} value={details.assetInfo  && details.commGatewayID ? details.commGatewayBID : ''} />
                 <DetailsItem name={'RSN'} value={details.assetInfo  && details.commGatewayID ? details.commGatewayID.rsn : ''} />
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Card sx={{ px: 2, py: 1 }}>
            <Grid container>
              <Grid item xs={12} md={12} lg={12}>
                <Typography sx={{fontSize:'15px', fontWeight:'400' }} color={'subtitle1.main'} variant="subtitle3" gutterBottom>
                  Select Enterprise
                </Typography>
                <Stack spacing={2}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} sx={{width:'100%'}}>
                      <Autocomplete
                        id="allocate-select"
                        sx={{width:'100%'}}
                        value={value}
                        onChange={handleEnterpriseChange}
                        isOptionEqualToValue={(option, value) => (option.enterpriseName === value.enterpriseName) || (option.enterpriseID === value.enterpriseID) }
                        getOptionLabel={(option) => `${option.enterpriseName} | ${option.enterpriseID}`}
                        options={totalEnterprises}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            placeholder={`Select Enterprise To ${title}`}
                          />
                        )}
                      />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Card>    
          </Grid>
        </Grid> 
      </DialogContent>
        <Box sx={{px:1, py:1 ,backgroundColor:'transparent'}}>
          <Grid container>
            <Grid item xs={6} sm={6} md={6} align='center'>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
              >
                Close
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={6} align='center'>
              <Button
                variant="contained"
                color="primary"
                disabled={!value}
                onClick={handleAllocate}
              >
                {title}
              </Button>
            </Grid>
          </Grid>
        </Box>
    </Dialog>
  );
}
