import React, { useState, useEffect } from 'react';

// ----------------------------------------------------------------------
import ReactApexChart from 'react-apexcharts';


export default function PolarChart({seriesData, labels}) {
  const state = {
    options: {
      chart: {
        type: 'polarArea',
      },
      labels: labels,
      stroke: {
        colors: ['#fff']
      },
      legend: {
        position: 'right',
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex]
        }
      },
      colors: ['rgb(0, 143, 251)', 'rgb(119, 93, 208)','rgb(0, 227, 150)', 'rgb(254, 176, 25)'],
      fill: {
        opacity: 0.8
      },
      yaxis: {
        forceNiceScale: true,
        labels: {
          formatter: function (val, index) {
            return val.toFixed(1);
          }
        }

      }
    },
  };

  return (<ReactApexChart options={state.options} series={seriesData} type="polarArea" height={250}/>)
}
