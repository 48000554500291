
let API_BASE_URL = 'https://api.muvnw.com';

module.exports = {
  baseUrl: `${API_BASE_URL}`,
  dev:{
    login:`${API_BASE_URL}/login`,
    verify: `${API_BASE_URL}/verifyotp`,
    domain: `${API_BASE_URL}/domain`,
    refreshToken: `${API_BASE_URL}/refresh`,
    loggedInAAAURL: `/aaa/iotapi`,
    loggedInAssetURL: `/asset/iotapi`,
    loggedInDataURL: `/data/iotapi`,
    getProfileData: `${API_BASE_URL}/aaa/iotapi/users/myprofile`,
    getEnterpriseStats: `${API_BASE_URL}/aaa/iotapi/enterprisestat`,
    createEnterprise:`${API_BASE_URL}/aaa/iotapi/enterprises`,
    getEnterpriseList: `${API_BASE_URL}/aaa/iotapi/enterprises`,
    deleteEnterprise: `${API_BASE_URL}/aaa/iotapi/enterprises`,
    updateEnterprise: `${API_BASE_URL}/aaa/iotapi/enterprises`,
    getEnterpriseDetails: `${API_BASE_URL}/aaa/iotapi/enterprises`,
  },
  map:{
    commonMapUrl:'https://maps.google.com/?q='
  }
}
