import DashboardIcon from '@mui/icons-material/Dashboard';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import BarChartIcon from '@mui/icons-material/BarChart';
import { SvgIcon } from '@mui/material';
import {ReactComponent as Dashboard} from '../../Assets/SidebarIcons/dashboard.svg';
import {ReactComponent as Enterprise} from '../../Assets/SidebarIcons/enterprise.svg';
import {ReactComponent as User} from '../../Assets/SidebarIcons/user.svg';
import {ReactComponent as Settings} from '../../Assets/SidebarIcons/settings.svg';
import {ReactComponent as Support} from '../../Assets/SidebarIcons/support.svg';
import {ReactComponent as Factory} from '../../Assets/SidebarIcons/factory.svg';
// ----------------------------------------------------------------------


export const Org_Admin = [
  {
    title: 'dashboard',
    path: '/dashboard/organization',
    icon: <SvgIcon><Dashboard/></SvgIcon>
  },
  {
    title: 'Factory Statistics',
    path: '/dashboard/devices',
    icon: <SvgIcon><Factory/></SvgIcon>
  },
  {
    title: 'Enterprise Management',
    path: '/dashboard/enterpriseManagement',
    icon: <SvgIcon><Enterprise/></SvgIcon>
  },
  {
    title: 'User Management',
    path: '/dashboard/user',
    icon: <SvgIcon><User/></SvgIcon>
  },
  {
    title: 'Settings',
    path: '/dashboard/settings',
    icon: <SvgIcon><Settings/></SvgIcon>
  },
  {
    title: 'Support Tickets',
    path: '',
    icon: <SvgIcon><Support/></SvgIcon>
  },
];

export const enterprise_Admin = [
  {
    title: 'dashboard',
    path: '/dashboard/enterprise',
    icon: <DashboardIcon />
  },
  {
    title: 'Inventory Statistics',
    path: '/dashboard/inventoryStats',
    icon: <BarChartIcon />
  },
  {
    title: 'User Management',
    path: '/dashboard/user',
    icon: <ManageAccountsIcon />
  },
  {
    title: 'Settings',
    path: '',
    icon: <SettingsIcon />
  },
  {
    title: 'Support Tickets',
    path: '',
    icon: <SupportAgentIcon />
  },
];

export const factory_manager = [
  {
    title: 'Factory Statistics',
    path: '/dashboard/devices',
    icon: <BarChartIcon />
  }
];

export const logistic_manager = [
  {
    title: 'Dashboard',
    path: '/dashboard/logisticDashboard',
    icon: <DashboardIcon />
  },
  {
    title: 'Factory Statistics',
    path: '/dashboard/devices',
    icon: <BarChartIcon />
  },
  {
    title: 'Enterprise Statistics',
    path: '/dashboard/enterpriseManagement',
    icon: <SupervisedUserCircleIcon />
  },
];

