import React, { Fragment, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';

import { useDispatch } from "react-redux";
import {getDomainList} from '../../actions/auth';

import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  TextField,
} from '@mui/material';

const useStyles = makeStyles({
 form:{
   width:'70%'
 }
});


export default function SelectDomain(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [apiError, setApiError] = useState('');
  const validationSchema = Yup.object().shape({
    orgId: Yup.string().required('Organization ID is required')
      .min(6, 'Organization ID must be at least 6 characters')
      .max(6, 'Organization ID must not exceed 6 characters'),
    email: Yup.string().required('Email is required').email('Email is invalid')
  });
  const onSubmit = (data) => {
    setApiError('');
    dispatch(getDomainList(data.orgId, data.email))
        .then(() => {
          props.handleNext();
        })
        .catch((error) => {
          setApiError(error);
        });
  };
  const formik = useFormik({
    initialValues: {
      orgId: '',
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Fragment>
      <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}  className={classes.form}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="text"
                  label="Organization ID"
                  {...getFieldProps('orgId')}
                  error={Boolean(touched.orgId && errors.orgId)}
                  helperText={touched.orgId && errors.orgId}
                />
              </Stack>
              <Box mt={3}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} align='center'>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Get Domain
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} align='center'>
                      <Typography variant="inherit" color="error">
                        {apiError ? apiError : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
          </Form>
      </FormikProvider>
    </Fragment>
  );
};