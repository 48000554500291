import React, { useEffect, useState } from "react";

import { Icon, Typography } from '@mui/material';
import Geocode from "react-geocode";


// ----------------------------------------------------------------------
//Geocode.setApiKey("AIzaSyDVWUjE352HjckcdylCZarP7fHyxuNbJ4o");
Geocode.setApiKey("AIzaSyDcEEPOExGTY4Ql684WA55DitZfUpmE17M");
Geocode.setLanguage("en");
Geocode.enableDebug();



export default function GeocodeToAddress({ lat, long }) {
const [address, setAddress] = useState('');

function callGeoCodeApi(){
  Geocode.fromLatLng(lat,long).then(
    (response) => {
      const address = response.results[0].formatted_address;
      let city, state, country;
      for (let i = 0; i < response.results[0].address_components.length; i++) {
        for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
          switch (response.results[0].address_components[i].types[j]) {
            case "locality":
              city = response.results[0].address_components[i].long_name;
              break;
            case "administrative_area_level_1":
              state = response.results[0].address_components[i].long_name;
              break;
            case "country":
              country = response.results[0].address_components[i].long_name;
              break;
          }
        }
      }
      setAddress(`${city} ${state} ${country}`);
    },
    (error) => {
      console.error(error);
      setAddress('');
    }
  );
  
}

  useEffect(()=>{
    if(lat && long){
      callGeoCodeApi();
    }
  },[lat, long])

  return <Typography component={'span'} variant="span" sx={{fontWeight: '600', opecity:1}}>{address}</Typography>

}
