import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

// material
import { Card, Box, Stack, TextField, MenuItem, Grid, Button, Typography } from '@mui/material';

import EnterpriseService from '../../../Services/enterprise';
// ----------------------------------------------------------------------



export default function FactoryStats(props) {
  const [noData, setNoData] = useState(false);
  const [selectedAssetCategory, setSelectedAssetCategory] = useState('');
  const [assetCategoryList, setAssetCategoryList] = useState([]);
  const [totalDevices, setTotalDevices] = useState('');
  const [soldDevices, setSoldDevices] = useState('');
  const [percentage, setPercentage] = useState([0]);
  const [totalUnits, setTotalUnits] = useState([]);
  const [currentUnits, setCurrentUnits] = useState([]);
  const [soldUnits, setSoldUnits] = useState([]);

  const [xLabels, setXlabels] = useState([]);


  const BarChartData = [
    {
      name: 'Total Units',
      data: totalUnits
    }, {
      name: 'Current Units',
      data: currentUnits
    },
    {
      name: 'Sold Units',
      data: soldUnits
    },
  ];
  const BarchartOptions = {
    chart: {
      type: 'bar',
      height: 250,
      toolbar:{
        export:{
          csv:{
            headerCategory: 'Asset Category',
            filename: `Inventory Stats`
          },
          svg:{
            filename: `Inventory Stats`
          },
          png:{
            filename: `Inventory Stats`
          },
        }
      }
    },
    stroke: { width: [0, 2, 3] },
    plotOptions: { 
      bar: {
      columnWidth: '35%', 
      borderRadius: 0 
    }
    },
    fill: {
      opacity: 1
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: xLabels,
    },
    yaxis: {
      title: {
        text: 'Number Of Units'
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    }
  };

  let finalOptions = {
    chart: {
      height: 250,
      type: "radialBar",
    },
  
    series: percentage,
    colors: ["#20E647"],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "70%",
          background: "#293450"
        },
        track: {
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.15
          }
        },
        dataLabels: {
          name: {
            offsetY: -10,
            color: "#fff",
            fontSize: "13px"
          },
          value: {
            color: "#fff",
            fontSize: "30px",
            show: true
          }
        }
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        gradientToColors: ["#87D4F9"],
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: "round"
    },
    labels: ["Stock"]
  };
  

  const handleAssetCategory = (event) => {
    setSelectedAssetCategory(event.target.value);
    plotRadialChart(event.target.value);
  };
  function plotRadialChart(data){
   setTotalDevices(data.total);
   setSoldDevices(data.userProvisioned + data.assetProvisioned);

   if(data.total !== 0){
    let total = data.total;
    let sold = data.enterpriseProvisioned;
    let percentage = ((sold/total) * 100).toFixed(1);
    setPercentage([percentage]);
   } else {
     setPercentage([0]);
   }
  }
  function getLabels(data){
    let labels = [];
    labels = data.map((v)=>{
      return `${v.assetCategory} | ${v.assetType}`
    });
    return labels;
  }
  function getTotalUnits(data){
    let total = [];
    total = data.map((v)=>{
      return v.total
    });
    return total;
  }
  function getCurrentUnits(data){
    let current = [];
    current = data.map((v)=>{
      return v.enterpriseProvisioned
    });
    return current;
  }
  function getSoldUnits(data){
    let sold = [];
    sold = data.map((v)=>{
      return v.userProvisioned + v.assetProvisioned
    });
    return sold;
  }
  function formatBarChartData(data){
    setXlabels(getLabels(data.categorySummary));
    setTotalUnits(getTotalUnits(data.categorySummary));
    setCurrentUnits(getCurrentUnits(data.categorySummary));
    setSoldUnits(getSoldUnits(data.categorySummary));
  }
  function getInventoryStats() {
    EnterpriseService.getIndividualEnterpriseStats({}).then((result) => {
      setNoData(false);
      formatBarChartData(result);
      let aggergateData = {
        assetCategory: 'All',
        assetType: 'All',
        ...result
      }
      setAssetCategoryList([aggergateData,...result.categorySummary]);
      setSelectedAssetCategory(aggergateData);
      plotRadialChart(result);
    },
      () => {
         setNoData(true);
      });
  }

  useEffect(() => {
      getInventoryStats();
  }, [])

  return (
    <Card>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }}>
                <Grid container>
                    <Grid item xs={8} md={8} lg={8}>
                    <TextField
                        select
                        sx={{ width: 150, mr: 1 , mt: 1 }}
                        size={'small'}
                        label="Select Asset Category"
                        variant="outlined"
                        value={selectedAssetCategory}
                        onChange={handleAssetCategory}
                      >
                        {assetCategoryList.map((option, index) => (
                          <MenuItem key={`${index} -:`} value={option}>{option.assetCategory === 'All' ? 'All' :`${option.assetCategory} | ${option.assetType}`}</MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                </Grid>
              </Stack>
            </Stack>
          </Grid>
         {!noData ? <>
           <Grid item xs={12} md={8} lg={8}>
            <ReactApexChart type="bar" series={BarChartData} options={BarchartOptions} height={250} />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
             <ReactApexChart options={finalOptions} series={finalOptions.series} type="radialBar" height={250} />
             <Grid container>
                 <Grid item xs={12} md={12} lg={12}>
                     <Stack>
                       <Stack direction="row" alignItems="center" justifyContent="center">
                       <span className='customMarkerBlue'></span> 
                        <Typography sx={{fontSize: '12px', fontWeight: 400, mr:1}} gutterBottom>
                           Total Units: {totalDevices}
                        </Typography>
                        <span className='customMarkerGreen'></span> 
                        <Typography sx={{fontSize: '12px', fontWeight: 400}} gutterBottom>
                          Total Sold Units: {soldDevices}
                        </Typography>
                       </Stack>
                     </Stack>
                 </Grid>
             </Grid>
          </Grid>  </> : ''}
          {noData ? <Grid item xs={12} md={12} lg={12} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <Typography variant='h5' gutterBottom>No Data Available</Typography>
          </Grid> : '' }
        </Grid>
      </Box>
    </Card>
  );
}
