import React, {useEffect} from 'react';
// material
import { Box, Grid, Container } from '@mui/material';

// components
import Page from '../../components/Page';
import ProfileUserForm from '../../components/_dashboard/profile/ProfileUserForm';
import ProfileUserDetails from '../../components/_dashboard/profile/ProfileUserDetails';

//Service
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getTitle } from '../../actions/dashboardTitle';


export default function Profile() {
  const dispatch = useDispatch();
  const formData = useSelector(state => state.profile);

  useEffect(()=>{
    dispatch(getTitle('User Profile'))
  },[])
  return (
    <Page title="User Profile">
      <Container maxWidth="xl">
        {/* <Box sx={{ pb: 5 }}>
          <Typography variant="h4">User Profile</Typography>
        </Box> */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <ProfileUserForm formData={formData} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ProfileUserDetails formData={formData} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}