import React, { useState, useEffect } from 'react';

// material
import { Card, 
  CardContent, 
  Paper, 
  Box, 
  Stack, 
  TextField, 
  MenuItem, 
  Grid, 
  Button, 
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
 } from '@mui/material';

import { useTheme } from '@emotion/react';

import AssetSelection from '../../common/AssetSelection';
import Scrollbar from '../../Scrollbar';

import Settings from '../../../Services/settings';

const TABLE_HEAD = [
  { id: 'evi', label: 'Event ID', alignRight: false },
  { id: 'dt', label: 'Data Type', alignRight: false },
  { id: 'evt', label: 'Event Type', alignRight: false },
  { id: 'pe', label: 'Periodicity', alignRight: false },
];


export default function EventConfiguration(props) {
  const theme = useTheme();
  const [activeAsset, setActiveAsset] = useState(null);
  const [configList, setConfigList] = useState([]);

  const assetChanged = (asset) =>{
    setActiveAsset(asset);
  }
function getEventConfigurationDetails(){
  if(activeAsset){
    let params = {
      assetCategory: activeAsset.assetCategory,
      assetType:activeAsset.assetType
    }
    Settings.getEventConfig(params).then((result) => {
      setConfigList(result.eventConfigs);
     },
     () => {
      setActiveAsset(null);
      setConfigList([]);
     });
  }
}
  useEffect(()=>{
    getEventConfigurationDetails()
  },[activeAsset])

  return (<Card sx={{p:3}}>
    <AssetSelection  assetChanged={assetChanged}/>
    <CardContent sx={{py:1,px:0}}>
       <Scrollbar>
           <TableContainer>
               <Table>
                <TableHead>
                  <TableRow sx={{bgcolor: theme.palette.table.bg}}>
                  {TABLE_HEAD.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={'center'}
                          sx={{color: theme.palette.table.headerText}}
                        >{headCell.label}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                {configList.map((row, index) => {
                    const { eventID, dataType, eventType, periodicity } = row;

                    return (
                      <TableRow
                        key={`${index}+->`}
                      >
                        <TableCell align="center">{`${eventID}`}</TableCell>
                        <TableCell align="center">{`${dataType}`}</TableCell>
                        <TableCell align="center">{`${eventType}`}</TableCell>
                        <TableCell align="center">{eventType === 'PERIODIC' ? periodicity : 'N/A'}</TableCell>
                      </TableRow>
                    );
                  })}
                  {configList.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={12}>No Configuration Data Present</TableCell>
                    </TableRow>
                  ) : ''}
                </TableBody>
               </Table>
           </TableContainer>
       </Scrollbar>
    </CardContent>
  </Card>)
}
