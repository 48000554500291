import React, { useState, useEffect } from 'react';

// ----------------------------------------------------------------------
import ReactApexChart from 'react-apexcharts';


export default function TripChart({vehicleSpeedData, batteryPackTempData, vehicleEcuTemp, motorTemp, motorControllerTemp}) {
  let state = {
    series: [
    {
      name: 'Vehicle Speed',
      type: 'line',
      data: vehicleSpeedData
    },
    {
      name: 'Battery Pack Temp',
      type: 'line',
      data: batteryPackTempData
    },
    {
      name: 'Vehicle ECU Temp',
      type: 'line',
      data: vehicleEcuTemp
    },
    {
      name: 'Motor Controller Temp',
      type: 'line',
      data: motorControllerTemp
    },
    {
      name: 'Motor Temp',
      type: 'line',
      data: motorTemp
    }],
    options: {
      chart: {
        type: 'line',
        stacked: false
      },
      colors: ['rgb(0, 227, 150)', 'rgb(255, 69, 96)', 'rgb(254, 176, 25)', 'rgb(119, 93, 208)', 'rgb(0, 143, 251)'],
      noData: {
        text: 'Loading',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: 'gray',
          fontSize: '26px',
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [3, 3, 3, 3, 3],
        curve: 'straight',
        dashArray: [0, 2, 2, 2, 2]
      },
      xaxis: {
        type: 'datetime',
        labels: {
          formatter: function(value) {
            let date = new Date(value);
            let yr = date.getFullYear();
            let mo = date.getMonth() + 1;
            let day = date.getDate();
            let hours = date.getHours();
            let hr = hours < 10 ? '0' + hours : hours;
            let minutes = date.getMinutes();
            let min = (minutes < 10) ? '0' + minutes : minutes;
            let newDateString = day + '/' + mo  + '/' + yr;
            let newTimeString = hr + ':' + min;
            let excelDateString = newDateString + ' ' + newTimeString;
            return excelDateString
          }
        }
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
  
          },
          labels: {
            style: {
            }
          },
          title: {
            text: "Temperature In Degree Celsius",
          },
          tooltip: {
            enabled: true
          }
        },
        {
          seriesName: 'Vehicle Speed',
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          labels: {
            style: {
            },
          },
          title: {
            text: "Kilometer / Hour",
            style: {
            }
          }
        },
      ],
      tooltip: {
      },
      legend: {
        horizontalAlign: 'center',
        itemMargin: {
          vertical: 15
      },
      }
    }
  }
  return (<ReactApexChart options={state.options} series={state.series} type="line" height={'100%'} />)
}
