import auth from './auth';
import axiosApiInstance from './axiosInstance';
import { dev } from '../config';

const getAssetCategories = () => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/assetCategory`, {headers: headers})
    .then((response) => {
      return response.data;
    })
};

const getEventConfig = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/eventconfig`, 
    {
      headers: headers,
      params: params
    })
    .then((response) => {
      return response.data;
    })
};

const getCommandConfig = (assetCategory, assetType) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/cmdref/${assetCategory}/${assetType}`, 
    {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getAssetConfig = (assetCategory, assetType) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/assetMetaRepo/${assetCategory}/${assetType}`, {headers: headers})
    .then((response) => {
      return response.data;
    })
};


let modules  =  {
  getAssetCategories,
  getEventConfig,
  getCommandConfig,
  getAssetConfig
};
export default modules