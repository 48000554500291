import React, { useState, useEffect } from 'react';

// material
import { Card, Box, Stack, TextField, MenuItem, Grid, Button, Typography } from '@mui/material';

//Service
import Settings from '../../Services/settings';

export default function AssetSelection({assetChanged}) {
  const [assetCategoryList, setCategoryList] = useState([]);
  const [selectedAssetCategory, setSelectedAssetCategory] = useState('');
  const handleAssetCategory = (event) => {
    setSelectedAssetCategory(event.target.value);
    assetChanged(event.target.value);
  };
  function getAssetCategories(){
    Settings.getAssetCategories().then((result) => {
     setCategoryList(result.AssetCategories);
     setSelectedAssetCategory(result.AssetCategories[0]);
     assetChanged(result.AssetCategories[0]);
    },
    () => {
      setCategoryList([]);
    });
  }
  useEffect(()=>{
   getAssetCategories();
  },[])
  return (<Stack>
              <Stack direction="row"  justifyContent="space-between">
                  <TextField
                      select
                      sx={{ width: 150, mr: 1 , mt: 1 }}
                      size={'small'}
                      label="Select Asset Category"
                      variant="outlined"
                      value={selectedAssetCategory}
                      onChange={handleAssetCategory}
                    >
                      {assetCategoryList.map((option, index) => (
                        <MenuItem key={`${index} -iip`} value={option}>{`${option.assetCategory} | ${option.assetType}`}</MenuItem>
                      ))}
                    </TextField>
                </Stack>
          </Stack>)
}
