import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import refresh from '@iconify/icons-eva/refresh-outline';
import { Icon } from '@iconify/react';
// material
import { Card, Box, Stack, TextField, MenuItem, Grid, Button, Typography } from '@mui/material';

import { useSelector } from "react-redux";

import StatsTarget from './StatsTarget';

import DevicesService from '../../../Services/devices';
// ----------------------------------------------------------------------
const fullmonthList = ['All', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];



export default function FactoryStats({assetCategoryList,lastUpdateTime, updateData}) {
  const profile = useSelector(state => state.profile);
  const currentRole = profile ? profile.roles[0].roleName : ''
  const yearList = ['2024','2023','2022'];
  const [open, setOpen] = useState(false);
  const [statData, setStatData] = useState([]);
  const [title, setTitle] = useState('Edit Target');
  const [noData, setNoData] = useState(false);
  const [selectedAssetCategory, setSelectedAssetCategory] = useState('');
  const [produced, setProduced] = useState('');
  const [target, setTarget] = useState('');
  const [percentage, setPercentage] = useState([]);
  const [targetCommGateways, setTargetCommGateways] = useState([]);
  const [totalCommGateways, setTotalCommGateways] = useState([]);
  const [xLabels, setXlabels] = useState([]);
  const [selectedYear, setSelectedYear] = useState('2024');
  // let currentMonth = new Date().getMonth();
  // const monthList = fullmonthList.slice(0, currentMonth + 2);
  const [selectedMonth, setSelectedMonth] = useState('All');


  const BarChartData = [
    {
      name: 'Target Units',
      data: targetCommGateways
    }, {
      name: 'Produced Units',
      data: totalCommGateways
    },
  ];
  const BarchartOptions = {
    chart: {
      type: 'bar',
      height: 250,
      toolbar:{
        export:{
          csv:{
            headerCategory: 'Period',
            filename: selectedMonth === 'All' ? `Factory Stats ${selectedYear}` : `Factory Stats ${selectedMonth}/${selectedYear}`
          },
          svg:{
            filename: selectedMonth === 'All' ? `Factory Stats ${selectedYear}` : `Factory Stats ${selectedMonth}/${selectedYear}`
          },
          png:{
            filename: selectedMonth === 'All' ? `Factory Stats ${selectedYear}` : `Factory Stats ${selectedMonth}/${selectedYear}`
          },
        }
      }
    },
    stroke: { width: [0, 2, 3] },
    plotOptions: { 
      bar: {
      columnWidth: '35%', 
      borderRadius: 0 
    }
    },
    fill: {
      opacity: 1
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: xLabels,
    },
    yaxis: {
      title: {
        text: 'Number Of Units'
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    }
  };

  let finalOptions = {
    chart: {
      height: 250,
      type: "radialBar",
    },
  
    series: percentage,
    colors: ["#20E647"],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "70%",
          background: "#293450"
        },
        track: {
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.15
          }
        },
        dataLabels: {
          name: {
            offsetY: -10,
            color: "#fff",
            fontSize: "13px"
          },
          value: {
            color: "#fff",
            fontSize: "30px",
            show: true
          }
        }
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        gradientToColors: ["#87D4F9"],
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: "round"
    },
    labels: ["Progress"]
  };
  

  const handleAssetCategory = (event) => {
    setSelectedAssetCategory(event.target.value);
  };
  
  const handleTargetModal = (state) => {
    setOpen(state);
  }

  function formatAllMonthsData(data) {
    if (data.monthlyStats.length > 0) {
      let targetCommGateways = data.monthlyStats.map((v) => {
        return v.targetCommGateways
      });
      let totalCommGateways = data.monthlyStats.map((v) => {
        return v.totalCommGateways
      });
      let xLabels = data.monthlyStats.map((v) => {
        return v.month
      });
      setTargetCommGateways(targetCommGateways);
      setTotalCommGateways(totalCommGateways);
      setXlabels(xLabels);
      let percentageAchieved = parseFloat(data.totalCommGateways/data.targetCommGateways * 100).toFixed(1);
      setPercentage([percentageAchieved]);
      setProduced(data.totalCommGateways);
      setTarget(data.targetCommGateways);
      setStatData(data.monthlyStats);
    }

  }
  function formatAllDaysData(data) {
    if (data.cgDailyStats.length > 0) {
      let totalCommGateways = data.cgDailyStats.map((v) => {
        return v.numberOfCommGateways
      });
      let xLabels = data.cgDailyStats.map((v) => {
        return `${v.dayOfMonth}-${data.month}`
      });
      let targetCommGateways = [];
      targetCommGateways.length = totalCommGateways.length;
      targetCommGateways.fill(0);
      setTargetCommGateways(targetCommGateways);
      setTotalCommGateways(totalCommGateways);
      setXlabels(xLabels);
      let percentageAchieved = parseFloat(data.totalCommGateways/data.targetCommGateways * 100).toFixed(1);
      setPercentage([percentageAchieved]);
      setProduced(data.totalCommGateways);
      setTarget(data.targetCommGateways);
      setStatData([data]);
    }

  }

  function getFactoryStats() {
    let params = {
      assetCategory: selectedAssetCategory.assetCategory,
      assetType: selectedAssetCategory.assetType
    }
    if (selectedMonth !== 'All') {
      params['month'] = selectedMonth
    }
    DevicesService.getFactoryStats(selectedYear, params).then((result) => {
      setNoData(false);
      setTitle('Edit Target');
      if (selectedMonth === 'All') {
        formatAllMonthsData(result)
      } else {
        formatAllDaysData(result);
      }
    },
      () => {
         setNoData(true);
         setStatData([]);
         setTitle('Create Target');
      });
  }

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  }

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  }

  const triggerUpdate = ()=> {
    getFactoryStats();
    updateData();
  }

  useEffect(()=>{
    if(assetCategoryList.length > 0){
      setSelectedAssetCategory(assetCategoryList[0]);
    }
  },[assetCategoryList])
  useEffect(() => {
    if(Object.keys(selectedAssetCategory).length > 0 && selectedYear !== '' && selectedMonth !== ''){
      getFactoryStats();
    }
  }, [selectedAssetCategory, selectedYear, selectedMonth])

  return (
    <Card>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }}>
                <Grid container>
                    <Grid item xs={8} md={8} lg={8}>
                    <TextField
                        select
                        sx={{ width: 150, mr: 1 , mt: 1 }}
                        size={'small'}
                        label="Select Asset Category"
                        variant="outlined"
                        value={selectedAssetCategory}
                        onChange={handleAssetCategory}
                      >
                        {assetCategoryList.map((option, index) => (
                          <MenuItem key={index} value={option}>{`${option.assetCategory} | ${option.assetType}`}</MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        select
                        sx={{  width: 150, mr: 1,  mt: 1 }}
                        size={'small'}
                        label="Select Year"
                        variant="outlined"
                        value={selectedYear}
                        onChange={handleYearChange}
                      >
                        {yearList.map((option) => (
                          <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        select
                        sx={{  width: 150, mr: 1, mt: 1 }}
                        size={'small'}
                        label="Select Month"
                        variant="outlined"
                        value={selectedMonth}
                        onChange={handleMonthChange}
                      >
                        {fullmonthList.map((option) => (
                          <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                      </TextField>
                     {currentRole === 'ROLE_SYS_ORG_ADMIN' ?  <Button
                        sx={{ mt: 1}}
                        onClick={()=> setOpen(true)}
                        disabled={selectedMonth !== 'All'}
                        variant="outlined"
                      >
                       {title} 
                      </Button> : '' }
                    </Grid>
                    <Grid item xs={4} md={4} lg={4} sx={{display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
                       <Button
                        sx={{ mt: 1}}
                        onClick={triggerUpdate}
                        variant="outlined"
                        startIcon={<Icon icon={refresh} />}
                      >
                        Refresh
                      </Button>
                      <Typography variant='subtitle' sx={{fontSize: '12px', fontWeight: 400,  mt: 1}}>Last Update: {lastUpdateTime}</Typography>
                     
                    </Grid>
                </Grid>
              </Stack>
            </Stack>
          </Grid>
         {!noData ? <>
           <Grid item xs={12} md={8} lg={8}>
            <ReactApexChart type="bar" series={BarChartData} options={BarchartOptions} height={250} />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
             <ReactApexChart options={finalOptions} series={finalOptions.series} type="radialBar" height={250} />
             <Grid container>
                 <Grid item xs={12} md={12} lg={12}>
                     <Stack>
                       <Stack direction="row" alignItems="center" justifyContent="center">
                       <span className='customMarkerBlue'></span> 
                        <Typography sx={{fontSize: '12px', fontWeight: 400, mr:1}} gutterBottom>
                           Target Units: {target}
                        </Typography>
                        <span className='customMarkerGreen'></span> 
                        <Typography sx={{fontSize: '12px', fontWeight: 400}} gutterBottom>
                          Produced Units: {produced}
                        </Typography>
                       </Stack>
                     </Stack>
                 </Grid>
             </Grid>
          </Grid>  </> : ''}
          {noData ? <Grid item xs={12} md={12} lg={12} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <Typography variant='h5' gutterBottom>No Data Available</Typography>
          </Grid> : '' }
        </Grid>
      </Box>
      <StatsTarget open={open} handleTargetModal={handleTargetModal} isCreate={noData} statData={statData} title={title} selectedAssetCategory={selectedAssetCategory} selectedYear={selectedYear} updateData={triggerUpdate}/>
    </Card>
  );
}
