import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import {useNavigate} from "react-router-dom";
import personFill from '@iconify/icons-eva/person-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, IconButton, Stack } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import Avatar from 'react-avatar';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { roleDisplayName } from '../../utils/formatRoles';
//Services
import { useDispatch } from "react-redux";
import {logout} from '../../actions/auth';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Profile',
    icon: personFill,
    linkTo: '/dashboard/profile'
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover({profile}) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
console.log('profile',profile);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () =>{
    dispatch(logout())
    navigate('/login');
  }

  return (
    <>
    <Stack display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'}>
          <Box  onClick={handleOpen} sx={{cursor:'pointer'}}>
          <Typography variant='span' sx={{ mb: 0, maxWidth: '8rem', overflow: 'hidden', textOverflow: 'ellipsis', fontStyle: 'normal', fontWeight: 700, fontSize: '16px', color: 'primary.main' }} gutterBottom>{profile.userProfile.firstName}</Typography>
            <IconButton
                ref={anchorRef}
                sx={{
                  ml:0.5,
                  padding: 0,
                  width: 10,
                  height: 10,
                }}
              >  <ArrowDropDownIcon />
          </IconButton>
          </Box>
           <Typography sx={{mb: 0, fontStyle: 'normal', fontWeight: 400, fontSize: '11px', color: '#65708D' }} gutterBottom>{roleDisplayName(profile.roles[0].roleName)}</Typography>
    </Stack>
     

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
             {profile.userProfile.firstName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
           {profile.username}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
