import {
  GET_DEVICE_DETAILS_SUCCESS,
  GET_DEVICE_DETAILS_FAIL
} from "../actions/type";



const initialState = {
                         enterpriseBID : null,
                         commGatewayBID :'',
                         commGatewayID : {
                           imei :'',
                           rsn :'',
                           macid :''
                        },
                         connectionDetail : {
                           imsi :'',
                           msisdn :'',
                           planCode :'',
                           planName :'',
                           planDescription :'',
                           planStartDate :'',
                           planEndDate :'' ,
                           serviceStatus :''
                        },
                         onboardingState :'',
                         assetInfo : {
                           assetCategory :'',
                           assetType :''
                        },
                         commGatewayState :'',
                         firstAuthenticatedTime : null,
                         lastConnectedTime : null,
                         lastInReachTime : null,
                         notReportingTime : null,
                         orgID : '' ,
                         enterpriseID : null,
                         uniqueUserID : null,
                         createdBy :'',
                         createdDate :'',
                         updatedBy : null,
                         updatedDate : null,
                         deleted : false
                      }


  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_DEVICE_DETAILS_SUCCESS:
        return {
          ...state,
          ...payload
        };
      case GET_DEVICE_DETAILS_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
  }
