import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Drawer } from '@mui/material';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import {Org_Admin, enterprise_Admin, factory_manager, logistic_manager} from './SidebarConfig';

import { useSelector } from "react-redux";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));
// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const profile = useSelector(state => state.profile);
  const currentRole = profile.roles[0].roleName;
  const [activeSideMenu, setActiveSideMenu] = useState([]);

  function getActiveSideMenu(){
    switch (currentRole){
    case 'ROLE_SYS_ORG_ADMIN':
      setActiveSideMenu(Org_Admin);
      break;
    case 'ROLE_SYS_B2B2C_ADMIN':
      setActiveSideMenu(enterprise_Admin);
      break;
    case 'ROLE_SYS_B2B_ADMIN':
      setActiveSideMenu(enterprise_Admin);
      break;
    case 'ROLE_SYS_FACTORY_MANAGER':
      setActiveSideMenu(factory_manager);
      break;
    case 'ROLE_SYS_LOGISTIC_MANAGER':
      setActiveSideMenu(logistic_manager);
      break;
    }
  }
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(()=>{
    getActiveSideMenu()
  },[profile])

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 2.5, }} textAlign={'center'}>
        <Box sx={{ display: 'inline-flex' }}>
          <Logo sx={{width: '150px', height: '53px'}} />
        </Box>
      </Box>
      <NavSection navConfig={activeSideMenu} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH,
                  bgcolor: 'primary.main' 
                }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'primary.main'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
