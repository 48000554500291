import {
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
} from "./type";

import UserService from '../Services/user';

export const  getUserDetails = (username) => (dispatch) => {
  return UserService.getUserDetails(username).then(
    (response) => {
      dispatch({
        type: GET_USER_DETAILS_SUCCESS,
        payload: response
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
      (error.response && error.response.data && error.response.data.error_description) ||
        error.response.data.message ||
        error.toString();

      dispatch({
        type: GET_USER_DETAILS_FAIL,
      });

      return Promise.reject();
    }
  );
};