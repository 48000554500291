import React, { Fragment, useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import {login} from '../../actions/auth';

import { makeStyles } from '@mui/styles';

import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  MenuItem
} from '@mui/material';

const useStyles = makeStyles({
  form:{
    width:'70%'
  }
 });
export default function SignIn(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [apiError, setApiError] = useState('');
  const [domains, setDomains] = useState([]);
  const [selectedDoamin, setSelectedDomain] = useState('');

  const handleChange = (event) => {
    setSelectedDomain(event.target.value);
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(20, 'Password must not exceed 40 characters'),
  });
  const onSubmit = (data) => {
    setApiError('');
    let userName = sessionStorage.getItem('email');
    let body = {
      username: userName,
      password: data.password,
      grant_type: 'password'
    }
    dispatch(login(selectedDoamin, body))
    .then((message) => {
     props.handleSignIn(message);
    })
    .catch((error) => {
      setApiError(error);
    });
  };
  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  }
  useEffect(() => {
    let storedDomains = JSON.parse(sessionStorage.getItem("domains"));
    setDomains(storedDomains);
    if (storedDomains.length === 1) {
      setSelectedDomain(storedDomains[0].domainID)
    }
  }, [])
  return (
    <Fragment>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit} className={classes.form}>
            <Stack spacing={3}>
            <TextField
                id="outlined-select-currency"
                select
                label="Domain"
                value={selectedDoamin}
                onChange={handleChange}
                fullWidth
                helperText="Please select your domain"
              >
                {domains.map((option) => (
                  <MenuItem key={option.domainID} value={option.domainID}>{option.domainID} | {option.domainName}</MenuItem>
                ))}
             </TextField>
             <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Stack>
            <Box mt={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} align='center'>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    SignIn
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} align='center'>
                  <Typography variant="inherit" color="error">
                    {apiError ? apiError : ''}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
        </Form>
      </FormikProvider>
    </Fragment>
  );
};