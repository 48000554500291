import { dev } from "../config";
import auth from './auth';
import {refreshToken} from '../actions/auth';
import {setLoading} from '../actions/loading';

import axiosApiInstance from './axiosInstance';
import store from '../store';


  axiosApiInstance.interceptors.request.use(
    (config) => {
      store.dispatch(setLoading(true))
      return config;
    },
    (error) => {
      store.dispatch(setLoading(false))
      return Promise.reject(error);
    }
  );
  axiosApiInstance.interceptors.response.use(
    (res) => {
      store.dispatch(setLoading(false))
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      store.dispatch(setLoading(false))
      if (originalConfig.url !== dev.verify && originalConfig.url !== dev.login && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const rs = await auth.getRefreshToken();
            store.dispatch(refreshToken(rs));
            let newConfig = {...originalConfig};
            newConfig.headers.Authorization = `Bearer ${rs.access_token}`
            return axiosApiInstance(newConfig);
          } catch (_error) {
            console.log('in refresh token expired');
            const message =
            (_error.response && _error.response.data && _error.response.data.error_description) ||
            _error.response.data.message ||
            _error.toString();
            return Promise.reject(message);
          }
        }
      }
      return Promise.reject(err);
    }
  );
export default axiosApiInstance;