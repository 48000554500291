import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import arrowBAck from '@iconify/icons-eva/arrow-back-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
// components
import AccountPopover from './AccountPopover';

import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 0;
const APPBAR_MOBILE = 56;
const APPBAR_DESKTOP = 60;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow:'0px 0px 10px rgba(212, 224, 231, 0.5)',
  backgroundColor: theme.palette.primary.contrastText,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  goBack: PropTypes.func,
  profile: PropTypes.object
};

export default function DashboardNavbar({ goBack, type }) {
  const profile = useSelector(state => state.profile);

  return (
    <RootStyle>
      <ToolbarStyle>
          <Stack direction="row" alignItems="center" justifyContent="center">
              <IconButton onClick={goBack} sx={{ mr: 1, color: 'background.primary.contrastText' }}>
                <Icon icon={arrowBAck} />
              </IconButton>
              <Typography  sx={{ mb: 0,fontWeight: 700, fontSize: '20px', color: 'primary.main' }} gutterBottom>
                  { type === 'battery' ? 'Battery Dashboard' : 'Asset Dashbaord'}
              </Typography>
          </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover profile={profile} />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
