import React from 'react';
// material
import {
  Grid,
  Box,
  Typography,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Paper
} from '@mui/material';


//Utils
import {roleDisplayName} from '../../../utils/formatRoles';
import {fDate} from '../../../utils/formatTime';

function DetailsItem({ name, value }) {
  return (
    <>
     <Paper elevation={24} sx={{width:'100%', px:2, py:2, mt:1}}>
        <Grid container> 
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="subtitle">{name}</Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>{value}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default function UserDetails({ openForm, handleCreateModal, details }) {
  const handleClose = () => {
    handleCreateModal(false);
  }
  return (
    <Dialog fullWidth maxWidth={'sm'} open={openForm} onClose={handleClose} onBackdropClick={handleClose} transitionDuration={{ enter: 500, exit: 500 }}>
       <DialogTitle>User Details</DialogTitle>
      <DialogContent>
        {details.userProfile ? <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={12}>
            {/* <Card sx={{ px: 2, py: 2 }}> */}
              {/* <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>User Details</Typography> */}
              {/* <Grid container sx={{ textAlign: 'center', border: 1 }}> */}
                <DetailsItem name={'Name'} value={`${details.userProfile.firstName} ${details.userProfile.lastName}` || ''} />
                <DetailsItem name={'User name'} value={details.username || ''} />
                <DetailsItem name={'Email ID'} value={details.userProfile.emailID || ''} />
                <DetailsItem name={'Organization ID'} value={details.orgID || ''} />
                <DetailsItem name={'Enterprise ID'} value={details.enterpriseID || ''} />
                <DetailsItem name={'Country Code'} value={details.userProfile.countryCode || ''} />
                <DetailsItem name={'Mobile Number'} value={details.userProfile.mobileNumber || ''} />
                <DetailsItem name={'Role'} value={roleDisplayName(details.roles[0].roleName)} />
                <DetailsItem name={'2FAEnabled'} value={details[`2FAEnabled`] ? 'Enabled' : 'Disabled' || ''} />
                <DetailsItem name={'Marital Status'} value={details.userProfile.maritalStatus} />
               {details.userProfile.dateOfBirthSelf ? <DetailsItem name={'Date of Birth'} value={fDate(details.userProfile.dateOfBirthSelf)} /> : ''}
               {details.userProfile.maritalStatus === 'MARRIED' ? <DetailsItem name={'Date of Birth Spouse'} value={fDate(details.userProfile.dateOfBirthSpouse)} /> : ''}
               {details.userProfile.maritalStatus === 'MARRIED' ? <DetailsItem name={'Date of Anniversary'} value={fDate(details.userProfile.dateAnniversary)} /> : ''}
              {/* </Grid> */}
            {/* </Card> */}
          </Grid>
        </Grid> : ''}
      </DialogContent>
        <Box sx={{px:1, py:1 ,backgroundColor:'transparent'}}>
          <Grid container >
            <Grid item xs={12} sm={12} md={12} align='center' >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
    </Dialog>
  );
}
