import {
SET_TITLE
} from "../actions/type";



const initialState = ''


  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case SET_TITLE:
        return payload
      default:
        return state;
    }
  }
