import { SET_LOADING_FALSE, SET_LOADING_TRUE } from "../actions/type";

const initialState = false

export default function (state = initialState, action) {
  const { type } = action;

  switch (type) {
    case SET_LOADING_TRUE:
      return true;

    case SET_LOADING_FALSE:
      return false;

    default:
      return state;
  }
}
