import auth from './auth';
import axiosApiInstance from './axiosInstance';
import { dev } from '../config';

const getUserList = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAAAURL}/users`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getFullUserList = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAAAURL}/users/all`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const createUser = (body) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .post(`${dev.loggedInAAAURL}/users`, body, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};


const getUserDetails = (username) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAAAURL}/users/user/${username}`, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const deleteUser = (username) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .delete(`${dev.loggedInAAAURL}/users/${username}`, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const updateUser = (body) => {
  let accessToken = auth.getCurrentAccessToken();

  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .put(`${dev.loggedInAAAURL}/users`, body, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getUserSearch = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAAAURL}/users/search`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};


let modules  =  {
  getUserList,
  createUser,
  getUserDetails,
  deleteUser,
  updateUser,
  getUserSearch,
  getFullUserList
};
export default modules