export const GET_DOMAIN_SUCCESS = "GET_DOMAIN_SUCCESS";
export const GET_DOMAIN_FAIL = "GET_DOMAIN_FAIL";

export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAIL = "SEND_OTP_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT = "LOGOUT";

export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const GET_ENTERPRISE_DETAILS_SUCCESS = "GET_ENTERPRISE_DETAILS_SUCCESS";
export const GET_ENTERPRISE_DETAILS_FAIL = "GET_ENTERPRISE_DETAILS_FAIL";

export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";

export const GET_DEVICE_DETAILS_SUCCESS = "GET_DEVICE_DETAILS_SUCCESS";
export const GET_DEVICE_DETAILS_FAIL = "GET_DEVICE_DETAILS_FAIL";

export const GET_REFRESH_TOKEN_SUCCESS = "GET_REFRESH_TOKEN_SUCCESS";

export const REFRESH_TOKEN_EXPIRED = "REFRESH_TOKEN_EXPIRED";

export const GET_ASSET_DASHBOARD_SUCCESS = "GET_ASSET_DASHBOARD_SUCCESS";
export const GET_ASSET_DASHBOARD_FAIL = "GET_ASSET_DASHBOARD_FAIL";

export const GET_BATTERY_DASHBOARD_SUCCESS = "GET_BATTERY_DASHBOARD_SUCCESS";
export const GET_BATTERY_DASHBOARD_FAIL = "GET_BATTERY_DASHBOARD_FAIL";

export const SET_LOADING_TRUE = "SET_LOADING_TRUE";
export const SET_LOADING_FALSE = "SET_LOADING_FALSE";

export const SET_TITLE = "SET_TITLE";
