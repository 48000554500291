import axios from "axios";
import {dev, baseUrl} from '../config';

const axiosApiInstance = axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    "Content-Type": "application/json",
  },
});


export default axiosApiInstance;