import React, { useEffect, useState } from "react";
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';

// material
import {
  Grid,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  MenuItem,
  InputBase,
  Box
} from '@mui/material';

// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { EnterpriseListHead } from '../../components/_dashboard/enterprise';
import CreateUser from '../../components/_dashboard/user/CreateUser';
import UserDetails from '../../components/_dashboard/user/UserDetails';
import ConformationPopUp from '../../components/_dashboard/enterprise/ConformationPopUp';
import EditUser from '../../components/_dashboard/user/EditUser';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import Paper from '@mui/material/Paper';

//Services
import User from '../../Services/user';
import { useDispatch } from "react-redux";
import { getTitle } from '../../actions/dashboardTitle';

//Utils
import {roleDisplayName} from '../../utils/formatRoles';

import { getUserDetails } from '../../actions/user';
import { useSelector } from "react-redux";



// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'userName', label: 'User Name', alignRight: false },
  { id: 'mobileNo', label: 'Mobile Number', alignRight: false },
  { id: 'emailId', label: 'Email', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------



function UserManagement() {
  const formData = useSelector(state => state.user);
  const profile = useSelector(state => state.profile);
  const currentRole = profile ? profile.roles[0].roleName : ''
  const [filterType, setFilterType] = useState('roleName');
  const [searchText, setSearchText] = useState('');
  const [userRole, setUserRole] = useState('All');
  const filterTypeOptions = [{ name: 'Role', value: 'roleName' }, { name: 'Username', value: 'username' }, { name: 'Email Id', value: 'emailID' }, { name: 'Mobile Number', value: 'mobileNumber' }];
  let userRoleOptions = [];
  if(currentRole === 'ROLE_SYS_B2B2C_ADMIN') {
    userRoleOptions = [{name:'All',value:'All'}, {name:'Admin',value:'ROLE_SYS_B2B2C_ADMIN'}, {name:'Support Manager',value:'ROLE_SYS_B2B2C_SUPPORT'}, {name:'User',value:'ROLE_SYS_B2B2C_USER'}];
  } else if (currentRole === 'ROLE_SYS_B2B2C_ADMIN') {
    userRoleOptions = [{name:'All',value:'All'}, {name:'Admin',value:'ROLE_SYS_B2B_ADMIN'}, {name:'Support Manager',value:'ROLE_SYS_B2B_SUPPORT'}, {name:'User',value:'ROLE_SYS_B2B_USER'}];
  } else {
    userRoleOptions = [{name:'All',value:'All'}, {name:'Organization Admin',value:'ROLE_SYS_ORG_ADMIN'}, {name:'Factory Manager',value:'ROLE_SYS_FACTORY_MANAGER'}, {name:'Logistic Manager',value:'ROLE_SYS_LOGISTIC_MANAGER'}, {name:'Support User',value:'ROLE_SYS_ORG_SUPPORT'}];
  }
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [details, setDetails] = useState({});
  const [openDetailsModal, setDetailsModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userList, setUserList] = useState([]);
  const [activeUserName, setActiveUserName] = useState('');
  const [openConform, setConform] = useState(false);


  const handleFilterType = (event) => {
    setSearchText('');
    if(event.target.value === 'roleName'){
      setUserRole('All')
    }
    setFilterType(event.target.value);
  };

  const handleRoleType = (event) => {
    if(event.target.value === 'All'){
      setSearchText('');
    } else {
      setSearchText(event.target.value);
    }
     setUserRole(event.target.value);
  };
  const handleCreateModal = (state) => {
    setOpen(state);
  }
  const handleDetailsModal = (state) => {
    setDetailsModal(state);
  }
  const openModal = () => {
    setOpen(true);
  }
  const handleEditModal = (state) => {
    setOpenEdit(state);
  }
  const openEditModal = () => {
    setOpenEdit(true);
  }
  const handleSearchChange = (event) => {
    setSearchText(event.target.value)
  }
  const goToDetails = (username) => {
    User.getUserDetails(username).then((result) => {
      setDetails(result);
      setDetailsModal(true);
    });
  }
  const editUser = (username) => {
    dispatch(getUserDetails(username))
      .then(() => {
        openEditModal(true);
      })
  }
  const deleteUser = (username) => {
    User.deleteUser(username).then((result) => {
      updateUserManagement();
    });
  }
  const openConformPopUp = (username) => {
    setActiveUserName(username);
    setConform(true);
  }
  const handleConformClose = (event) => {
    setConform(event);
  }
  const handleDeleteUser = (username) => {
    deleteUser(username);
    handleConformClose(false);
  }

  function getUserList() {
    let params = {
      pageSize: rowsPerPage,
      pageNumber: page
    }
    User.getUserList(params).then((result) => {
      setUserList(result.users);
      setTotalCount(result.page.totalRecords);
    },
    () => {
        setUserList([]);
    });
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  function getSearchResults() {
    let params = {};
    params[filterType] = searchText;
    params.pageSize = rowsPerPage;
    params.pageNumber = page;

    User.getUserSearch(params).then((result) => {
      setUserList(result.users);
      setTotalCount(result.page.totalRecords);
    },
    () => {
        setUserList([]);
    });
  }
  function updateUserManagement() {
    if (searchText !== '') {
      getSearchResults()
    } else {
      getUserList()
    }
  }
  useEffect(() => {
    setFilterType(filterTypeOptions[0].value)
  }, [])
  useEffect(() => {
    if (searchText !== '') {
      getSearchResults()
    } else {
      getUserList()
    }
  }, [page, userRole, searchText, rowsPerPage])

  useEffect(()=>{
    dispatch(getTitle('User Management'))
  },[])
  return (
    <Page title="User Management">
      <Container maxWidth="xl">
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            User Management
          </Typography>
          <Button
            variant="outlined"
            onClick={openModal}
            startIcon={<Icon icon={plusFill} />}
          >
            Create User
          </Button>
        </Stack> */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
               <Box>
               <Typography sx={{fontSize:'15px', fontWeight:'400' }} color={'subtitle1.main'} variant="subtitle3" gutterBottom>
                      Filter Type
                    </Typography>
                    <Paper
                      component="form"
                      sx={{ display: 'flex', alignItems: 'center', width: 400 }}
                    >
                      <TextField
                        sx={{ mr: 1, flex: 1 }}
                        select
                        value={filterType}
                        onChange={handleFilterType}
                        size="small"
                      >
                        {filterTypeOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                        ))}
                      </TextField>
                    {filterType === 'roleName' ? <TextField
                        sx={{ flex: 1 }}
                        select
                        value={userRole}
                        onChange={handleRoleType}
                        size="small"
                      >
                        {userRoleOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                        ))}
                      </TextField> :
                      <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search"
                      value={searchText}
                      inputProps={{ 'aria-label': 'Search' }}
                      onChange={handleSearchChange}
                    /> }
                    </Paper>
               </Box>
               <Box>
                    <Button
                        variant="outlined"
                        onClick={openModal}
                        startIcon={<Icon icon={plusFill} />}
                      >
                        Create User
                    </Button>
               </Box>
            </Stack>
            
          </Grid>
        </Grid>
        <Card sx={{ my: 3 }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <EnterpriseListHead
                  headLabel={TABLE_HEAD}
                />
                <TableBody>
                  {userList.map((row) => {
                    const { userProfile, username, roles } = row;

                    return (
                      <TableRow
                        key={username}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="center">{`${userProfile.firstName} ${userProfile.lastName}`}</TableCell>
                        <TableCell align="center">{username}</TableCell>
                        <TableCell align="center">{`${userProfile.countryCode ? userProfile.countryCode :''}${userProfile.mobileNumber}`}</TableCell>
                        <TableCell align="center">{userProfile.emailID}</TableCell>
                        <TableCell align="center">{roleDisplayName(roles[0].roleName)}</TableCell>
                        <TableCell align="center" style={{ width: 100 }}>
                          <Stack direction="row" spacing={1}>
                            <Button onClick={() => goToDetails(username)} size="small" variant="outlined" color="success" endIcon={<InfoIcon />}>
                              Details
                            </Button>
                            <Button onClick={() => editUser(username)}  disabled={roles[0].roleName === 'ROLE_SYS_ORG_ADMIN' || roles[0].roleName === 'ROLE_SYS_B2B2C_ADMIN' || roles[0].roleName === 'ROLE_SYS_B2B_ADMIN'} size="small" variant="outlined" color="secondary" endIcon={<EditIcon />}>
                              Edit
                            </Button>
                            <Button onClick={() => openConformPopUp(username)}  disabled={roles[0].roleName === 'ROLE_SYS_ORG_ADMIN' || roles[0].roleName === 'ROLE_SYS_B2B2C_ADMIN' || roles[0].roleName === 'ROLE_SYS_B2B_ADMIN'} size="small" variant="outlined" color="error" endIcon={<DeleteIcon />}>
                              Delete
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {userList.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={12}>No User Present</TableCell>
                    </TableRow>
                  ) : ''}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <CreateUser openForm={open} handleCreateModal={handleCreateModal} updateData={() => updateUserManagement()} profile={profile} onlyForUser={false} />
        <UserDetails openForm={openDetailsModal} handleCreateModal={handleDetailsModal} details={details}  profile={profile}/>
        <EditUser openForm={openEdit} handleEditModal={handleEditModal} updateData={() => updateUserManagement()} formData={formData}  profile={profile} />
        <ConformationPopUp open={openConform} id={activeUserName} handleConformClose={handleConformClose} handleDelete={handleDeleteUser} titleText={'user'} />
      </Container>
    </Page>
  );
}

export default UserManagement;
