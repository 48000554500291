import {
  GET_BATTERY_DASHBOARD_SUCCESS,
  GET_BATTERY_DASHBOARD_FAIL
} from "../actions/type";



const initialState = {
                        assetCategory:'',
                        assetFriendlyName: '',
                        assetID: '',
                        assetType: '',
                        commGatewayUID:'',
                        createdBy: "",
                        createdDate: null,
                        deleted: null,
                        enterpriseID: "",
                        imei: "",
                        macid: null,
                        metaData: [],
                        mqttConnectionDetails: null,
                        orgID: "",
                        rsn: '',
                        uniqueUserID: "",
                        updatedBy: null,
                        updatedDate: null,
                      }


  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_BATTERY_DASHBOARD_SUCCESS:
        return {
          ...state,
          ...payload
        };
      case GET_BATTERY_DASHBOARD_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
  }
