import React, { useState, useEffect } from 'react';

// material
import { Card, 
  CardContent, 
  Paper, 
  Box, 
  Stack, 
  TextField, 
  MenuItem, 
  Grid, 
  Button, 
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
 } from '@mui/material';

import { useTheme } from '@emotion/react';

import AssetSelection from '../../common/AssetSelection';
import Scrollbar from '../../Scrollbar';

import Settings from '../../../Services/settings';

const TABLE_HEAD = [
  { id: 'fieldName', label: 'Field Name', alignRight: false },
  { id: 'fieldType', label: 'Field Type', alignRight: false },
  { id: 'regex', label: 'Regex', alignRight: false },
  { id: 'mandatory', label: 'Mandatory', alignRight: false },
  { id: 'filter', label: 'Filter', alignRight: false },
];

export default function AssetConfiguration(props) {
  const theme = useTheme();
  const [activeAsset, setActiveAsset] = useState(null);
  const [configList, setConfigList] = useState([]);

  const assetChanged = (asset) =>{
    setActiveAsset(asset);
  }
function getAssetConfigurationDetails(){
  if(activeAsset){
    let params = {
      assetCategory: activeAsset.assetCategory,
      assetType:activeAsset.assetType
    }
    Settings.getAssetConfig(activeAsset.assetCategory, activeAsset.assetType).then((result) => {
      console.log('res',result);
      setConfigList(result.metaDataRepo);
     },
     () => {
      setActiveAsset(null);
      setConfigList([]);
     });
  }
}
  useEffect(()=>{
    getAssetConfigurationDetails()
  },[activeAsset])

  return (<Card sx={{p:3}}>
    <AssetSelection  assetChanged={assetChanged}/>
    <CardContent sx={{py:1,px:0}}>
       <Scrollbar>
           <TableContainer>
               <Table>
                <TableHead>
                <TableRow sx={{bgcolor: theme.palette.table.bg}}>
                {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={'center'}
                        sx={{color: theme.palette.table.headerText}}
                      >{headCell.label}
                      </TableCell>
                    ))}
                </TableRow>
                </TableHead>
                <TableBody>
                {configList.map((row, index) => {
                    const { fieldName, fieldType, filter, regex, mandatory } = row;

                    return (
                      <TableRow
                        key={`${index}--->`}
                      >
                        <TableCell align="center">{`${fieldName}`}</TableCell>
                        <TableCell align="center">{`${fieldType}`}</TableCell>
                        <TableCell align="center">{regex}</TableCell>
                        <TableCell align="center">{mandatory ? 'Yes' : 'No'}</TableCell>
                        <TableCell align="center">{filter ? 'Yes' : 'No'}</TableCell>
                      </TableRow>
                    );
                  })}
                  {configList.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={12}>No Configuration Data Present</TableCell>
                    </TableRow>
                  ) : ''}
                </TableBody>
               </Table>
           </TableContainer>
       </Scrollbar>
    </CardContent>
  </Card>)
}
