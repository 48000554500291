import {
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
} from "./type";

import ProfileService from '../Services/profile';

export const getProfileData = () => (dispatch) => {
  return ProfileService.getProfileData().then(
    (response) => {
      dispatch({
        type: GET_PROFILE_SUCCESS,
        payload: response
      });
      return Promise.resolve(response);
    },
    (error) => {
      const message =
      (error.response && error.response.data && error.response.data.error_description) ||
        error.response.data.message ||
        error.toString();

      dispatch({
        type: GET_PROFILE_FAIL,
      });

      return Promise.reject(error);
    }
  );
};

export const updateProfileData = (body) => (dispatch) => {
  return ProfileService.updateProfileData(body).then(
    (response) => {
      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: response
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
      (error.response && error.response.data && error.response.data.error_description) ||
        error.response.data.message ||
        error.toString();
      if(message.includes('Invalid access token')){
        message = 'Invalid access token';
      }
      dispatch({
        type: UPDATE_PROFILE_FAIL,
      });
      return Promise.reject(message);
    }
  );
};
