import React, {useState, useEffect} from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';

import enterprise from '../../../Services/enterprise'
import { useTheme } from '@emotion/react';
// material
import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  TextField,
  Card,
  CardHeader,
  MenuItem,
  Dialog,
  DialogContent
} from '@mui/material';

export default function CreateEnterprise({openForm, handleCreateModal, updateData, details, goToCreateAdmin}) {
  const theme = useTheme();
  const [apiError, setApiError] = useState('');
  const [apiSuccess, setApiSuccess] = useState('');
  const enterpriseTypeOptins = ['B2B2C', 'B2B'];
  const [selectedType, setSelectedType] = useState('');
  const validationSchema = Yup.object().shape({
    enterpriseName: Yup.string().required('Enterprise Name is required').max(50, 'Enterprise Name must not exceed 50 characters').min(3, 'Enterprise Name must be atleast 3 characters').matches(/^[a-zA-Z0-9\s]/, "Only alphanumeric characters are allowed"),
    phoneNumber: Yup.string().required('Phone No. is required').max(15, 'Phone Number must not exceed 15 digits').min(10, 'Phone Number must be at least 10 digits').matches(/^[0-9-+]/, "Only numeric characters are allowed"),
    address: Yup.string().required('Address is required').max(100, 'Address must not exceed 100 characters').min(3, 'Address must be at least 3 characters').matches(/^[a-zA-Z0-9\s,#]/, "Only alphanumeric characters are allowed"),
    city: Yup.string().required('City is required').max(50, 'City must not exceed 50 characters').min(3, 'City must be at least 3 characters').matches(/^[a-zA-Z]/, "Only alphabets are allowed"),
    country: Yup.string().required('Country is required').max(10, 'Country must not exceed 10 characters').min(3, 'Country must be at least 3 characters').matches(/^[a-zA-Z]/, "Only alphabets are allowed"),
    pinCode: Yup.string().required('Pin Code is required').max(10, 'Pin Code must not exceed 10 characters'),
    lat: Yup.number().required('Latitude is required'),
    long: Yup.number().required('Longitude is required'),
  });

  const handleClose = () => {
    handleCreateModal(false);
    resetForm();
    setApiError('');
    setApiSuccess('');
  };

  const handleEnterpriseType = (event)=> {
      setSelectedType(event.target.value);
  }

  const onSubmit = (data) => {
    setApiError('');
    setApiSuccess('');
    let body = {
      enterpriseName: data.enterpriseName,
      enterpriseType: selectedType,
      phoneNumber: data.phoneNumber,
      address: data.address,
      city: data.city,
      country: data.country,
      pinCode: data.pinCode,
      latitude:  data.lat,
      longitude: data.long
    }
    enterprise.createEnterprise(body).then((result) => {
      setApiSuccess('Enterprise Created Successfully');
      goToCreateAdmin(result.enterpriseID, result.enterpriseType, result.enterpriseName);
      updateData();
      handleClose();
    },
      (error) => {
        const resMessage =
        (error.response && error.response.data && error.response.data.error_description) ||
         error.response.data.message ||
          error.toString();
        setApiError(resMessage);
      });
  };
  const formik = useFormik({
    initialValues:{
      enterpriseName: '',
      phoneNumber: '',
      address: '',
      city: '',
      country: '',
      pinCode: '',
      lat:'',
      long: '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: onSubmit
  });
  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(()=>{
    setSelectedType('B2B');
  },[]);

  useEffect(()=>{
  },[details]);


  return (
      <Dialog open={openForm} onClose={handleClose} onBackdropClick={handleClose}  transitionDuration={{ enter: 500, exit: 500 }}>
        <DialogContent>
          <Card>
          <CardHeader title="Create Enterprise" subheader=""/>
            <Box sx={{ mx: 3, my: 3 }}>
              <FormikProvider value={formik}>
                 <Form onSubmit={handleSubmit}>
                     <Stack spacing={3}>
                        <Stack  direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                             <TextField
                              fullWidth
                              autoComplete="enterpriseName"
                              type="text"
                              label="Enterprise Name"
                              {...getFieldProps('enterpriseName')}
                              error={Boolean(touched.enterpriseName && errors.enterpriseName)}
                              helperText={touched.enterpriseName && errors.enterpriseName}
                               />
                              <TextField
                                fullWidth
                                autoComplete="phoneNumber"
                                type="text"
                                label="Phone Number"
                                {...getFieldProps('phoneNumber')}
                                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                helperText={touched.phoneNumber && errors.phoneNumber}
                              />
                        </Stack>
                        <Stack  direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                             <TextField
                              select
                              label="Enterprise Type"
                              value={selectedType}
                              onChange={handleEnterpriseType}
                              fullWidth
                             >
                              {enterpriseTypeOptins.map((option) => (
                                <MenuItem key={option} value={option}>{option}</MenuItem>
                              ))}
                            </TextField>
                        </Stack>
                        <Stack  direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                             <TextField
                              fullWidth
                              autoComplete="address"
                              type="text"
                              label="Address"
                              {...getFieldProps('address')}
                              error={Boolean(touched.address && errors.address)}
                              helperText={touched.address && errors.address}
                               />
                              <TextField
                                fullWidth
                                autoComplete="city"
                                type="text"
                                label="City"
                                {...getFieldProps('city')}
                                error={Boolean(touched.city && errors.city)}
                                helperText={touched.city && errors.city}
                              />
                        </Stack>
                        <Stack  direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                             <TextField
                              fullWidth
                              autoComplete="country"
                              type="text"
                              label="Country"
                              {...getFieldProps('country')}
                              error={Boolean(touched.country && errors.country)}
                              helperText={touched.country && errors.country}
                               />
                              <TextField
                                fullWidth
                                autoComplete="pinCode"
                                type="text"
                                label="Pin Code"
                                {...getFieldProps('pinCode')}
                                error={Boolean(touched.pinCode && errors.pinCode)}
                                helperText={touched.pinCode && errors.pinCode}
                              />
                        </Stack>
                        <Stack  direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                             <TextField
                              fullWidth
                              autoComplete="lat"
                              type="number"
                              label="Latitude"
                              {...getFieldProps('lat')}
                              error={Boolean(touched.lat && errors.lat)}
                              helperText={touched.lat && errors.lat}
                               />
                              <TextField
                                fullWidth
                                autoComplete="long"
                                type="number"
                                label="Longitude"
                                {...getFieldProps('long')}
                                error={Boolean(touched.long && errors.long)}
                                helperText={touched.long && errors.long}
                              />
                        </Stack>
                     </Stack>
                     <Box mt={3}>
                      <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} align='center'>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        <Grid item xs={6} sm={6} align='center'>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} align='center'>
                          <Typography variant="inherit" color="error">
                            {apiError ? apiError : ''}
                          </Typography>
                          <Typography variant="subtitle2" sx={{ color: theme.palette.success.light }}>
                            {apiSuccess ? apiSuccess : ''}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                 </Form>
              </FormikProvider>
            </Box>
          </Card>
        </DialogContent>
      </Dialog>
  );
}
