import React, { useEffect, useState } from "react";
// material
import {
  Grid,
  Card,
  Stack,
  Typography,
  Paper,
  Box,
  Button,
  CardContent,
  Avatar,
  CardActions,
  List,
  ListSubheader,
  Collapse,
  CircularProgress,
  TextField,
  Pagination
} from '@mui/material';


import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// components
import { useNavigate, useParams } from 'react-router-dom';
import {fDateTimeWithSuffix } from '../../utils/formatTime';
import MapListItem from "../../components/common/MapListItem";
import TripMap from "../../components/common/TripMap";
import TripChart from "../../components/common/TripLineChart";
import Dashboard from '../../Services/dashboard';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "75vh"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  }
}));




function DetailsItem({ category, value, lat, long}) {
  return (
    <>
     <Paper variant="outlined" component={'div'} sx={{p: 1}}>
        <Typography variant="p" sx={{ opacity: 0.72, fontSize: '14px'}}>
            {category}
        </Typography>
        <Stack direction={'row'} spacing={1} justifyContent="space-around">
          <Typography  sx={{ fontSize: '10px', fontWeight: '600' }}>{lat}</Typography> 
          <Typography  sx={{ fontSize: '10px', fontWeight: '600' }}>{long}</Typography> 
        </Stack>
      </Paper>
    </>
  );
}

function Trips() {
  const [pageSize, setPageSize] = useState(5);
  const [displayPagination, setShowPagination] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tripList, setTripList] = useState([]);
  const [tripData, setTripData] = useState({});
  const [isTripItemSelected, setTripItem] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [vehicleSpeedData, setVehicleSpeedData] = useState([]);
  const [batteryPackTempData, setBatteryPackTempData] = useState([]);
  const [vehicleEcuTemp, setVehicleEcuTempData] = useState([]);
  const [motorTemp, setMotorTempData] = useState([]);
  const [motorControllerTemp, setMotorControllerTempData] = useState([]);

  const classes = useStyles();
  let { id } = useParams();

  async function getTripChartData(eventId, assetId, startTime, endTime){
      let params = {
        assetID: assetId,
        eventID: eventId,
        startTime: startTime,
        endTime: endTime,
        pageSize: 5000,
        pageNumber: 0, 
        direction: 'ASC'
      }
      return new Promise((resolve) => {
        Dashboard.getTripChartData(params).then((result) => {
          resolve(result.eventData);
          },
          () => {
            resolve([]);
          });
      });
    }
  async function makeEventDataCall(session){
    let vehicleSpeed = await getTripChartData('vehicleSpeed', id,session.sessionStartTime,session.sessionEndTime);
    let batteryPackTemp = await getTripChartData('batteryPackTemp', id,session.sessionStartTime,session.sessionEndTime);
    let vehicleEcuTemp = await getTripChartData('vehicleEcuTemp', id,session.sessionStartTime,session.sessionEndTime);
    let motorTemp = await getTripChartData('motorTemp', id,session.sessionStartTime,session.sessionEndTime);
    let motorControllerTemp = await getTripChartData('motorControllerTemp', id,session.sessionStartTime,session.sessionEndTime);
    vehicleSpeed = vehicleSpeed.map((v)=> {
      return [ v.timeStamp, v.data.value]
    });
    batteryPackTemp = batteryPackTemp.map((v)=> {
      return [ v.timeStamp, v.data.value]
    });
    vehicleEcuTemp = vehicleEcuTemp.map((v)=> {
      return [ v.timeStamp, v.data.value]
    });
    motorTemp = motorTemp.map((v)=> {
      return [ v.timeStamp, v.data.value]
    });
    motorControllerTemp = motorControllerTemp.map((v)=> {
      return [ v.timeStamp, v.data.value]
    });
    setVehicleSpeedData(vehicleSpeed);
    setBatteryPackTempData(batteryPackTemp);
    setVehicleEcuTempData(vehicleEcuTemp);
    setMotorTempData(motorTemp);
    setMotorControllerTempData(motorControllerTemp);
  }
  function  getTripsData(){
    let params = {
      numberOfSessions: 10
    }
    Dashboard.getTripsData(id, params).then((result) => {
      setTripList(result.sessions);
      if(!isTripItemSelected){
        tripSelected(result.sessions[0].sessionSpecifics);
        makeEventDataCall(result.sessions[0]);
        setSelectedIndex(0);
      }
    },
    () => {
      setTripList([]);
      setTripItem(true);
    });
  }

  function  getPaginatedTripData(){
    let params = {
      startTime: new Date(startDate).getTime(),
      endTime : new Date(endDate).getTime(),
      pageSize: pageSize,
      pageNumber: page - 1
    }
    Dashboard.getPaginatedTripsData(id, params).then((result) => {
     setShowPagination(true);
     setTotalCount(Math.ceil(result.page.totalRecords / pageSize));
     setTripList(result.Sessions);
     tripSelected(result.Sessions[0].sessionSpecifics);
     makeEventDataCall(result.Sessions[0]);
     setSelectedIndex(0);
    },
    () => {
      setTripList([]);
      setTripItem(true);
    });
  }
  const tripSelected = (data) =>{
   setTripItem(false);
    let timeOut =  setTimeout(() => {
      setTripData(data);
      setTripItem(true);
      clearInterval(timeOut);
    },500);
  }
  
  const getNewData = (index, tripData) => {
    setSelectedIndex(index);
    tripSelected(tripData.sessionSpecifics);
    makeEventDataCall(tripData);
  };

  
  const handlePaginationChange = (event, page) => {
    setPage(page);
  };

  const handleDatePicker = () =>{
    setTripItem(false);
    setShowPagination(false);
    setPage(1);
    setTotalCount(0);
    setStartDate(null);
    setEndDate(null);
  }

  useEffect(()=>{
    if(startDate && endDate){
      getPaginatedTripData();
    } else {
      getTripsData();
    }
  },[startDate, endDate, page])

  return (
        <Grid container spacing={2}>
           <Grid item xs={8} md={8} lg={8}>
             <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                {tripList.length > 0 ?  <Card  variant="outlined" sx={{ height: '40vh'}}>
                      {isTripItemSelected ? <TripMap tripData={tripData} /> : <Box sx={{width: '100%', height: '40vh', display: 'flex', justifyContent:"center", alignItems: 'center'}}> 
                      <CircularProgress /></Box>}
                  </Card> : ''}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                 {tripList.length > 0 ? <Card  variant="outlined" sx={{height: '40vh'}}>
                      {isTripItemSelected ? <TripChart vehicleSpeedData={vehicleSpeedData} batteryPackTempData={batteryPackTempData} vehicleEcuTemp={vehicleEcuTemp} motorTemp={motorTemp} motorControllerTemp={motorControllerTemp} /> : <Box sx={{width: '100%', height: '40vh', display: 'flex', justifyContent:"center", alignItems: 'center'}}> 
                      <CircularProgress /></Box>}
                  </Card> : ''}
                </Grid>
                {!tripList.length > 0 ? <Grid item xs={12} md={12} lg={12}>
                        <Box sx={{width: '100%', height: '82vh', display:"flex", justifyContent: 'center', alignItems:'center'}}>
                               <Typography  component="h2"  variant="div" color="text.primary">No Data Present</Typography>
                         </Box>
                </Grid> : '' }
             </Grid>
            
          </Grid> 
          <Grid item xs={4} md={4} lg={4}>
             <Card  variant="outlined" className={classes.root} style={{ display: "flex", flexDirection: "column", height: '82vh' }}>
                <CardContent style={{ marginTop: "auto", height: '92%', overflow: 'auto', padding: 0, borderBottom: '0.5px solid lightgray'}}>
              {tripList.length > 0 ?  <List subheader={<React.Fragment>
                                    <ListSubheader sx={{fontSize: '18px',color:'text.primary', fontWeight: '600'}}>Trips</ListSubheader>
                                    <Stack direction="row" justifyContent="space-between" spacing={2} sx={{mb:1.5}}>
                                       <LocalizationProvider dateAdapter={AdapterDateFns}>
                                          <DatePicker
                                            label="Start Date"
                                            value={startDate}
                                            onChange={(newValue) => {
                                              setStartDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField size="small" {...params} />}
                                          />
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                          <DatePicker
                                            label="End Date"
                                            value={endDate}
                                            onChange={(newValue) => {
                                              setEndDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField size="small" {...params} />}
                                          />
                                        </LocalizationProvider>
                                        <Button disabled={startDate === null || endDate === null} size="small" onClick={handleDatePicker}>Clear</Button>
                                      </Stack>
                                </React.Fragment>} sx={{p: 1}}>
                          
                           {tripList.map((tripItem, i) => (
                                 <MapListItem key={`${tripItem.sessionID}`} itemIndex={i} changeSelection={getNewData} selectedIndex={selectedIndex} tripData={tripItem} tripSelected={tripSelected}/>
                            ))}  
                    </List>:  <Box sx={{width: '100%', height: '82vh', display:"flex", justifyContent: 'center', alignItems:'center'}}>
                               <Typography  component="h2"  variant="div" color="text.primary">No Trips Present</Typography>
                            </Box> }
                   </CardContent>
                   <CardActions sx={{ height: '8%' }}>
                     {displayPagination ? <Pagination count={totalCount} color="primary" page={page} onChange={handlePaginationChange} /> : '' }
                   </CardActions>
             </Card>
          </Grid> 
      </Grid>
  );
}

export default Trips;
