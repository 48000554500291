import PropTypes from 'prop-types';
// material
import { Box, Typography } from '@mui/material';

import AppLogo from '../Assets/sidebar_logo.svg'
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
  <>
    <Box component="img" src={AppLogo} sx={{ width: '100%', ...sx }} />
  </>)
}
