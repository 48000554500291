import React, {useState, useEffect} from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';

import User from '../../../Services/user';
import { useSelector } from "react-redux";
import { useTheme } from '@emotion/react';
// material
import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  TextField,
  Card,
  CardHeader,
  MenuItem,
  Dialog,
  DialogContent,
  InputAdornment,
} from '@mui/material';

// material-lab
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import MuiPhoneNumber from 'material-ui-phone-number';

export default function EditUser({openForm, handleEditModal, updateData, formData, profile}) {
  const theme = useTheme();
  const [userRoleOptions, setUserRoleOptions] = useState([]);
  const [countryCode, setCountryCode] = useState('+91');
  const [isMarried, setMarried] = useState(false);
  const [dobself, setDobSelf] = useState(new Date());
  const [dobspouse, setDobSpouse] = useState('');
  const [dobAnniversary, setDobAnniversary] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('SINGLE');
  const marriedStatusOptions = ['MARRIED', 'SINGLE'];
  const [apiError, setApiError] = useState('');
  const [apiSuccess, setApiSuccess] = useState('');
  const authOptions = [{name:'Enabled', value:true},{name:'Disabled', value:false}];
  const [selectedAuthType, setSelectedAuthType] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const validationSchema = Yup.object().shape({
    firstName:  Yup.string().required('First Name is required').max(30, 'First Name must not exceed 15 characters').min(3, 'First Name must be atleast 3 characters').matches(/^[a-zA-Z]/, "Only alphabets are allowed"),
    lastName: Yup.string().required('Last Name is required').max(30, 'Last Name must not exceed 15 characters').min(3, 'Last Name must be atleast 3 characters').matches(/^[a-zA-Z]/, "Only alphabets are allowed"),
    emailID: Yup.string().required('Email is required').email('Email is invalid'),
    mobileNumber: Yup.string().required('Mobile No. is required').max(15, 'Mobile Number must not exceed 15 digits').min(10, 'Mobile Number must be at least 10 digits').matches(/^[0-9]/, "Only numeric characters are allowed"),
  });

  const handleClose = () => {
    handleEditModal(false);
    resetForm();
    setApiError('');
    setApiSuccess('');
  };

  const handleCountryCodeChange = (countryCode) => {
    setCountryCode(countryCode);
  }
  const handleRoleType = (event)=> {
      setSelectedType(event.target.value);
  }
  const handleAuthType = (event)=> {
    setSelectedAuthType(event.target.value);
}

  const onSubmit = (data) => {
    setApiError('');
    setApiSuccess('');
    let roles = [{
      roleName: selectedType
    }];
    let userProfile = {
      ...data,
      countryCode: countryCode,
      maritalStatus: selectedStatus,
      dateOfBirthSelf:dobself.toISOString(),
      dateOfBirthSpouse: selectedStatus === 'MARRIED' ? new Date(dobspouse).toISOString(): '',
      dateAnniversary: selectedStatus === 'MARRIED'? new Date(dobAnniversary).toISOString(): ''
    }
    let body = {
      username: formData.username,
      roles: [...roles],
      userProfile:{...userProfile},
      is2FEnabled: selectedAuthType
    }
    User.updateUser(body).then((result) => {
      setApiSuccess('User Updated Successfully');
      updateData();
      handleClose();
    },
      (error) => {
        const resMessage =
        (error.response && error.response.data && error.response.data.error_description) ||
         error.response.data.message ||
          error.toString();
        setApiError(resMessage);
      });
  };
  const formik = useFormik({
    initialValues:{
      firstName: formData.userProfile.firstName,
      lastName: formData.userProfile.lastName,
      emailID: formData.userProfile.emailID,
      mobileNumber: formData.userProfile.mobileNumber
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: onSubmit
  });
  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;

  const handleChange = (event) => {
    setSelectedStatus(event.target.value);
    if (event.target.value === 'MARRIED') {
      setMarried(true);
      setDobSpouse(formData.userProfile.dateOfBirthSpouse ? formData.userProfile.dateOfBirthSpouse : new Date());
      setDobAnniversary(formData.userProfile.dateAnniversary ? formData.userProfile.dateAnniversary : new Date());
    } else {
      setMarried(false);
      setDobSpouse(formData.userProfile.dateOfBirthSpouse ? formData.userProfile.dateOfBirthSpouse : new Date());
      setDobAnniversary(formData.userProfile.dateAnniversary ? formData.userProfile.dateAnniversary : new Date());
    }
  };

  useEffect(()=>{
    let userRoleOptions = [];
    let currentRole = profile.roles[0].roleName;
    if(currentRole === 'ROLE_SYS_B2B2C_ADMIN') {
      userRoleOptions = [{name:'Admin',value:'ROLE_SYS_B2B2C_ADMIN'}, {name:'Support Manager',value:'ROLE_SYS_B2B2C_SUPPORT'}, {name:'User',value:'ROLE_SYS_B2B2C_USER'}];
    } else if(currentRole === 'ROLE_SYS_B2B_ADMIN') {
      userRoleOptions = [{name:'Admin',value:'ROLE_SYS_B2B_ADMIN'}, {name:'Support Manager',value:'ROLE_SYS_B2B_SUPPORT'}, {name:'User',value:'ROLE_SYS_B2B_USER'}];
    } else {
      userRoleOptions = [{name:'Factory Manager',value:'ROLE_SYS_FACTORY_MANAGER'}, {name:'Logistic Manager',value:'ROLE_SYS_LOGISTIC_MANAGER'}, {name:'Support User',value:'ROLE_SYS_ORG_SUPPORT'}];
    }
    let countryCode = formData.userProfile.countryCode;
    setCountryCode(countryCode);
    setUserRoleOptions(userRoleOptions);
    setSelectedAuthType(formData[`2FAEnabled`]);
    setSelectedType(formData.roles[0].roleName);
    setSelectedStatus(formData.userProfile.maritalStatus);
    setDobSelf(new Date(formData.userProfile.dateOfBirthSelf));
    if(formData.userProfile.maritalStatus === 'MARRIED'){
      setMarried(true);
      setDobSpouse(formData.userProfile.dateOfBirthSpouse ? formData.userProfile.dateOfBirthSpouse : new Date());
      setDobAnniversary(formData.userProfile.dateAnniversary ? formData.userProfile.dateAnniversary : new Date());
    } else {
      setMarried(false);
      setDobSpouse(formData.userProfile.dateOfBirthSpouse ? formData.userProfile.dateOfBirthSpouse : new Date());
      setDobAnniversary(formData.userProfile.dateAnniversary ? formData.userProfile.dateAnniversary : new Date());
    }
  },[formData, profile])

  return (
      <Dialog open={openForm} onClose={handleClose} onBackdropClick={handleClose}  transitionDuration={{ enter: 500, exit: 500 }}>
        <DialogContent>
         <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Card>
          <CardHeader title="Edit User" subheader=""/>
            <Box sx={{ mx: 3, my: 3 }}>
              <FormikProvider value={formik}>
                 <Form onSubmit={handleSubmit}>
                     <Stack spacing={3}>
                          <Stack  direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                             <TextField
                              select
                              label="Role"
                              disabled={true}
                              value={selectedType}
                              onChange={handleRoleType}
                              fullWidth
                             >
                              {userRoleOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                              ))}
                            </TextField>
                            <TextField
                              select
                              disabled={true}
                              label="2FAuthentication"
                              value={selectedAuthType}
                              onChange={handleAuthType}
                              fullWidth
                             >
                              {authOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                              ))}
                              </TextField>
                           </Stack>
                           <Stack  direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                   <TextField
                                    fullWidth
                                    autoComplete="firstname"
                                    type="text"
                                    label="First Name"
                                    {...getFieldProps('firstName')}
                                    error={Boolean(touched.firstName && errors.firstName)}
                                    helperText={touched.firstName && errors.firstName}
                                  />
                                  <TextField
                                    fullWidth
                                    autoComplete="lastname"
                                    type="text"
                                    label="Last Name"
                                    {...getFieldProps('lastName')}
                                    error={Boolean(touched.lastName && errors.lastName)}
                                    helperText={touched.lastName && errors.lastName}
                                  />
                           </Stack>
                        <Stack  direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                               <TextField
                                fullWidth
                                autoComplete="username"
                                type="email"
                                label="Email"
                                {...getFieldProps('emailID')}
                                error={Boolean(touched.emailID && errors.emailID)}
                                helperText={touched.emailID && errors.emailID}
                              />
                              <TextField
                                fullWidth
                                autoComplete="mobileNumber"
                                type="text"
                                label="Mobile Number"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                     <MuiPhoneNumber sx={{width: 70}}  value={countryCode} countryCodeEditable={false} inputClass="mobile__country--code" defaultCountry={'in'} onChange={handleCountryCodeChange}/>
                                    </InputAdornment>
                                  ),
                                }}
                                {...getFieldProps('mobileNumber')}
                                error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                                helperText={touched.mobileNumber && errors.mobileNumber}
                              />
                        </Stack>
                        <Stack  direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <TextField
                            select
                            label="Marital status"
                            value={selectedStatus}
                            onChange={handleChange}
                            fullWidth
                             >
                            {marriedStatusOptions.map((option) => (
                              <MenuItem key={option} value={option}>{option}</MenuItem>
                            ))}
                            </TextField>
                            <DatePicker
                              disableFuture
                              label="Date Of Birth Self"
                              openTo="day"
                              views={['year', 'month', 'day']}
                              value={dobself}
                              onChange={(newValue) => {
                                setDobSelf(newValue);
                              }}
                              renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </Stack>
                        {isMarried ? <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <DatePicker
                          disableFuture
                          label="Date Of Birth Spouse"
                          openTo="day"
                          views={['year', 'month', 'day']}
                          value={dobspouse}
                          onChange={(newValue) => {
                            setDobSpouse(newValue);
                          }}
                          renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                        <DatePicker
                          disableFuture
                          label="Anniversary Date"
                          openTo="day"
                          views={['year', 'month', 'day']}
                          value={dobAnniversary}
                          onChange={(newValue) => {
                            setDobAnniversary(newValue);
                          }}
                          renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                      </Stack> : ''}
                     </Stack>
                     <Box mt={3}>
                      <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} align='center'>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        <Grid item xs={6} sm={6} align='center'>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} align='center'>
                          <Typography variant="inherit" color="error">
                            {apiError ? apiError : ''}
                          </Typography>
                          <Typography variant="subtitle2" sx={{ color: theme.palette.success.light }}>
                            {apiSuccess ? apiSuccess : ''}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                 </Form>
              </FormikProvider>
            </Box>
          </Card>
          </LocalizationProvider>
        </DialogContent>
      </Dialog>
  );
}
