import React, { useEffect, useState } from "react";
// material
import {
  Grid,
  Card,
  Stack,
  Typography,
  Paper,
  Box,
  Switch,
  Divider,
  Slider
} from '@mui/material';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

// components
import { useNavigate, useParams } from 'react-router-dom';
import Dashboard from '../../Services/dashboard';
import TempMap from "../../components/common/TempMap";
import SpeedRpmMeter from "../../components/common/SpeedRpmMeter";
import TemperatureSlider from "../../components/common/TemperatureSlider";
import { styled } from '@mui/material/styles';
import BatteryCharging50Icon from '@mui/icons-material/BatteryCharging50';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import { useSelector } from "react-redux";
import { makeStyles } from '@mui/styles';
import LineChart from "../../components/common/LineChart";
import AlertFaults from "../../components/common/AlertFaults";
import BoltIcon from '@mui/icons-material/Bolt';
import { ReactComponent as BatteryPack } from "../../Assets/AssetDashboard/icon_battery_pack.svg";
import { ReactComponent as EcuTemp } from "../../Assets/AssetDashboard/icon_ecu_temp.svg";
import { ReactComponent as MotorCurrent } from "../../Assets/AssetDashboard/icon_motor_current.svg";
import { ReactComponent as MotorTorque } from "../../Assets/AssetDashboard/icon_motor_torque.svg";
import { ReactComponent as MotorTemp } from "../../Assets/AssetDashboard/icon_motor_temp.svg";
import { ReactComponent as Session } from "../../Assets/AssetDashboard/icon_seesion.svg";
import { ReactComponent as Connected } from "../../Assets/AssetDashboard/icon_connected.svg";
import { ReactComponent as DriveMode } from "../../Assets/AssetDashboard/icon_drive_mode.svg";
import { ReactComponent as DistanceToEmpty } from "../../Assets/AssetDashboard/icon_distance_to_empty.svg";
import { ReactComponent as ChargingCurrent } from "../../Assets/AssetDashboard/icon_charging_current.svg";
import { ReactComponent as Current } from "../../Assets/chargingIcon.svg";
import { ReactComponent as IconOn } from "../../Assets/AssetDashboard/icon_on.svg";


import AssetBg from "../../Assets/assetDashboardBg.svg";
import BikeImg from "../../Assets/bikeImg.svg";
import { withStyles } from '@mui/styles';


import BatteryHealthIcon from '@mui/icons-material/BatterySaverOutlined';
import Iconify from "../../components/common/Iconify";

const IconMapping = {
 percentage: <BatteryCharging50Icon color="info" />,
 health: <HealthAndSafetyIcon  color="success"/>,
 distance: <SocialDistanceIcon  color="info"/>,
 motorCurrent: <MotorCurrent width='40px' height='40px'/>,
 motorCurrentSmall: <MotorCurrent width='35px' height='35px'/>,
 session: <Session />,
 connected: <Connected />,
 charge: <BoltIcon />,
 distanceToEmpty: <DistanceToEmpty />,
 motorTorque: <MotorTorque width='40px' height='40px'/>,
 motorTemp: <MotorTemp />,
 battery_saver_outlined: <BatteryHealthIcon />,
 ecuTemp: <EcuTemp width='30px' height='30px'/>,
 batteryPack: <BatteryPack />,
 driveMode: <DriveMode />,
 chargingCurrent: <ChargingCurrent width='35px' height='35px'/>
}

const CustomSlider = withStyles({
  root: {
    height: 3,
    padding: '4px 0'
  },
  thumb: {
    height: 0,
  },
  track: {
    height: 3,
  },
  rail: {
    color: "#DCDCDC"
  }
})(Slider);

function valuetext(value, unit) {
  return `${value}${unit}`;
}

function DtoE({title, value, unit}) {
  return (
    <>
    <Box sx={{width:'60%', margin:'5px auto 0px auto'}}>
                      <Stack direction="row" alignItems='center' justifyContent={'space-between'}>
                         <Box>
                            <Box component={'span'}><Typography component={'span'} sx={{fontSize:'11px',fontWeight:400, color:'subtitle1.main'}}>{title}</Typography></Box>
                         </Box>
                         <Typography component={'span'} sx={{ fontSize:'13px',fontWeight:400, color:'table.headerText'}}>{value} {unit}</Typography>
                      </Stack>
                      <Box>
                                  <CustomSlider
                                    aria-label={title}
                                    value={value}
                                    color={'slider'}
                                    getAriaValueText={(value) => valuetext(value, unit)}
                                    step={1}
                                    valueLabelDisplay="auto"
                                    // marks={marksForTemperatureSlider[iconName]}
                                  />
                     </Box>
    </Box>
    </>
  );
}

const vehicleDriveModes = ['Eco', 'Sports', 'Insane'];
 const batteyStyle = makeStyles({
  batteryOuter: {
    position:'relative',
    display: 'flex',
    flexDirection: 'row',
    height: '35px',
    width: '100%',
    border: '1px solid #DCDCDC',
    padding: '3px',
    borderRadius: '10px',
   },
   batteryInner:{
    width: '100%',
    margin: '0 2px',
    backgroundColor: '#FFFFFF',
   },
   batteryStrokeFill: {
    width: '100%',
     margin: '0 2px 0 1px', 
     backgroundColor: '#07B27F', 
     flex: '1 1 auto',
     zIndex: 500
   },
   batteryStrokeEmpty: {
     width: '100%',
     margin: '0 2px 0 1px',
     backgroundColor: 'transparent', 
     flex: '1 1 auto',
     zIndex: 500
   },
   curverdLeftEdge:{
    borderTopLeftRadius:'10px',
    borderBottomLeftRadius:'10px'
   },
   curverdRightEdge:{
    borderTopRightRadius:'10px',
    borderBottomRightRadius:'10px'
   },
   batteryValue:{
    position:'absolute',
    right: '0px',
    top:'6px',
    zIndex: 1000
   }
 });

 const mainStyle = makeStyles({
  mainDiv:{
    height: '28rem', 
    backgroundColor:'transparent', 
    backgroundImage:  `url(${AssetBg})`,
    backgroundRepeat:'no-repeat',
    backgroundSize: 'auto',
    backgroundPosition: 'center',
  },
  bikeImg:{
    margin: '0 auto'
  }
 })

 function BatteryStroke({visible, item}) { 
  const classes = batteyStyle();
  return (
  <div
      className={`${
          visible ? classes.batteryStrokeFill : classes.batteryStrokeEmpty
      } ${item === 0 ? classes.curverdLeftEdge : ''} ${item === 19 ? classes.curverdRightEdge : ''}`}
      
  ></div>
);
}
function TopInfoSetion({name, value, border}) {
  const RootStyle = styled('div')(({ theme }) => ({
    boxShadow: 'none',
    padding: theme.spacing(1, 4),
    color: theme.palette.primary,
    borderRight: 'none',
  }));
  return (
    <RootStyle>
       <Typography mb={1}  variant="subtitle1" component={'div'} sx={{textTransform: 'capitalize', color: 'subtitle1.main', fontSize:'12px',fontWeight:400}}>
        {name}
      </Typography>
      <Typography variant="subtitle1" component={'div'} sx={{textTransform: 'capitalize',color: 'subtitle1.secondary', fontSize:'13px',fontWeight:400}}>{value}</Typography>
    </RootStyle>
  );
}


function FlexDetailsItem({ title, value, iconName, custom, unit, width='100%',  height='100px', isMotor }) {
  return (
    <>
     <Paper variant="outlined" component={'div'} sx={{ py:isMotor ? 0 : 1, textAlign: 'center', width: width, height:height }}>
        <Box>{custom ? IconMapping[iconName] : <Iconify icon={iconName} width="11px" height="22px"/>}</Box>
        <Typography variant="body2" sx={{fontSize:'11px',fontWeight:400, color: 'subtitle1.main', mt: isMotor ? 0 : 0.5 }}>
          {title}
        </Typography>
        <Typography  sx={{ fontSize: '14px', fontWeight:400, color: 'subtitle1.secondary' }}>{value === 'N/A' ? value : `${value} ${unit}`}</Typography> 
      </Paper>
    </>
  );
}


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 43,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: '#9391A0',
      '& + .MuiSwitch-track': {
        backgroundColor: '#FFFFFF',
        opacity: 1,
        border: '1px solid #9391A0',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        // opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#9391A0',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    backgroundColor: '#C4C4C4',
    opacity: 1,
    border: '1px solid #9391A0',
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function AssetDashboard() {
  const profile = useSelector(state => state.profile);
  const currentRole = profile.roles[0].roleName;
  const classes = mainStyle();
  const batteryClasses = batteyStyle();
  let { id } = useParams();
  let navigate = useNavigate();
  const [batteryStrokes, setBatteryStrokes] = useState(Array(20).fill(true));
  const [batteryState, setBatteryState] = useState(0);
  const [showMap, setShowMap] = useState(false);
  const dashboardData = useSelector(state => state.assetDashboard);
  const [assetData, setAssetData] = useState({});
  const [eventData, setEventData] = useState({});
  const [vehicleRegNumb, setVehicleRegNumb] = useState('');
  const [vehicleMake, setVehicleMake] = useState('');
  const [vehicleSpeedData, setVehicleSpeedData] = useState([]);
  const [batteryPackTempData, setBatteryPackTempData] = useState([]);
  const [vehicleEcuTemp, setVehicleEcuTempData] = useState([]);
  const [motorTemp, setMotorTempData] = useState([]);
  const [motorControllerTemp, setMotorControllerTempData] = useState([]);
  function  getAssetDashboardData(){
    Dashboard.getAssetDashboardDetails(id).then((result) => {
     setAssetData(result.assetData);
     setEventData(result.eventData);
     setBatteryState(result.eventData?.batterySoc?.value);
     let number = result.assetData.metaData.filter((v)=>{
       return v.fieldName === 'vehicleRegNumb'
     });
     setVehicleRegNumb(number[0].fieldValue);
     let make = result.assetData.metaData.filter((v)=>{
      return v.fieldName === 'vehicleMake'
    });
    setVehicleMake(make[0].fieldValue)
    },
    () => {
       
    });
  }

  function getLineChartData(eventId, assetId){
    let params = {
      assetID: assetId,
      eventID: eventId,
      count: 720,
      direction: 'ASC'
    }
    return new Promise((resolve) => {
      Dashboard.getLineChartData(params).then((result) => {
        resolve(result.eventData);
        },
        () => {
          resolve([]);
        });
    });
  }

const calculateBatteryStrokes = level => {
  if(level > 0){
    let totalStrokesCount = Math.ceil((level * 20) / 100) - 1;
    setBatteryStrokes(
        Array(20)
            .fill(true)
            .map((d, i) => (i <= totalStrokesCount ? true : false))
    );
  }
};

async function graphDataMapping() {
  let vehicleSpeed = await getLineChartData('vehicleSpeed', id);
  let batteryPackTemp = await getLineChartData('batteryPackTemp', id);
  let vehicleEcuTemp = await getLineChartData('vehicleEcuTemp', id);
  let motorTemp = await getLineChartData('motorTemp', id);
  let motorControllerTemp = await getLineChartData('motorControllerTemp', id);
  vehicleSpeed = vehicleSpeed.map((v)=> {
    return [ v.timeStamp, v.data.value]
  });
  batteryPackTemp = batteryPackTemp.map((v)=> {
    return [ v.timeStamp, v.data.value]
  });
  vehicleEcuTemp = vehicleEcuTemp.map((v)=> {
    return [ v.timeStamp, v.data.value]
  });
  motorTemp = motorTemp.map((v)=> {
    return [ v.timeStamp, v.data.value]
  });
  motorControllerTemp = motorControllerTemp.map((v)=> {
    return [ v.timeStamp, v.data.value]
  });
  setVehicleSpeedData(vehicleSpeed);
  setBatteryPackTempData(batteryPackTemp);
  setVehicleEcuTempData(vehicleEcuTemp);
  setMotorTempData(motorTemp);
  setMotorControllerTempData(motorControllerTemp);
 };
 const handleShowMapChange = (event) => {
  setShowMap(event.target.checked);
};
  useEffect(()=>{
      getAssetDashboardData();
      graphDataMapping();
  },[])
  useEffect(() => {
    const interval = setInterval(async () => {
      getAssetDashboardData();    
      graphDataMapping();
    }, 10000);

    return () => {
      clearInterval(interval);
    }
  }, []);
  useEffect(()=>{
    calculateBatteryStrokes(batteryState);
  },[batteryState])
  return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12} display={'flex'} justifyContent={'center'}>
              <TopInfoSetion name={dashboardData?.assetCategory} value={dashboardData?.assetType}  border={false}></TopInfoSetion>
              <Divider sx={{ my:1, opacity:0.2, borderRadius:'14px',borderWidth: '1px', backgroundColor:'subtitle1.main'}} variant="vertical" flexItem></Divider>
              <TopInfoSetion name={'Vehicle Make'} value={vehicleMake}  border={false}></TopInfoSetion>
              <Divider sx={{ my:1,opacity:0.2, borderRadius:'14px',borderWidth: '1px', backgroundColor:'subtitle1.main'}} variant="vertical" flexItem></Divider>
              <TopInfoSetion name={'RSN'} value={dashboardData?.rsn}  border={false}></TopInfoSetion>
              <Divider sx={{my:1,opacity:0.2, borderRadius:'14px',borderWidth: '1px', backgroundColor:'subtitle1.main'}} variant="vertical" flexItem></Divider>
              <TopInfoSetion name={'IMEI'} value={dashboardData?.imei}  border={false}></TopInfoSetion>
              <Divider sx={{my:1,opacity:0.2, borderRadius:'14px',borderWidth: '1px', backgroundColor:'subtitle1.main'}} variant="vertical" flexItem></Divider>
              <TopInfoSetion name={'Service Status'} value={assetData?.commGatewayStatus?.serviceStatus ? assetData.commGatewayStatus.serviceStatus : 'N/A'}  border={false}></TopInfoSetion>
          </Grid>
           <Grid item xs={3.5} md={3.5} lg={3.5}>
               <Box sx={{p:2,height: '28rem', backgroundColor:'transparent', borderRadius:'15px', border:'1px solid #9391A0'}}>
                 <Grid container spacing={1.5}>
                   <Grid item xs={12} md={12} lg={12}>                   
                      <Typography component={'span'} sx={{fontSize:'18px',textTransform: 'capitalize', fontWeight: 400, fontStyle:'normal',color:'subtitle1.main'}}>Battery Id</Typography>
                      <Typography component={'span'} sx={{ ml:1, fontSize:'18px',textTransform: 'lowercase', fontWeight: 400, fontStyle:'normal',color:'tabColors.slectedText'}}>{eventData?.batteryId?.value}</Typography>
                      <Divider sx={{my:2,opacity:0.2, borderRadius:'14px',borderWidth: '1px', backgroundColor:'subtitle1.main'}} variant="fullWidth" flexItem></Divider>
                   </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                        <Stack direction={'row'} justifyContent="space-between" spacing={2}>
                           <FlexDetailsItem title="Charging Current" width="70%"  value={eventData?.chargingStatus?.value ? eventData?.chargingCurrent?.value : 'N/A'} iconName={'chargingCurrent'}  custom={true}  unit={'mA'} />
                           <FlexDetailsItem title="Discharging Current" width="70%"  value={eventData?.dischargingCurrent ? eventData?.dischargingCurrent?.value : 'N/A'} iconName={'chargingCurrent'}   custom={true} unit={'mA'}/>
                        </Stack>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TemperatureSlider title={'ECU Temperature'} iconName={'batteryPack'} value={eventData?.vehicleEcuTemp ? eventData?.vehicleEcuTemp?.value : 0} unit={'°C'} />
                 </Grid>
                 <Grid item xs={12} md={12} lg={12}>
                    <TemperatureSlider title={'Battery Pack Temp'} iconName={'batteryPack'} value={eventData?.batteryPackTemp ? eventData?.batteryPackTemp?.value : 0} unit={'°C'} />
                 </Grid>
                 <Grid item xs={12} md={12} lg={12}>
                    <TemperatureSlider title={'Battery State of Health'} iconName={'batteryHealth'} value={eventData?.batterySoh ? eventData?.batterySoh.value : 0} unit={'%'} />
                 </Grid>
                 </Grid>
              </Box>
          </Grid>
          <Grid item xs={5} md={5} lg={5} sx={{position:'relative'}}> 
              <Box sx={{p:2}} className={classes.mainDiv}>
                  <Box textAlign={'center'} mt={1}>
                    <Typography component="div" sx={{fontSize:'12px', fontWeight:400, color:'subtitle1.main',textTransform:'capitalize' }}>{assetData?.assetFriendlyName}</Typography>
                    <Typography component="div" sx={{mt: 0.2, fontSize:'13px', fontWeight:500, color:'subtitle1.secondary',textTransform:'uppercase' }}>{vehicleRegNumb}</Typography>
                  </Box>
                  <Box>
                       <img className={classes.bikeImg} src={BikeImg} />
                  </Box>
                  <Box  sx={{width:'60%', margin:'0 auto'}}>
                      <Typography  variant="subtitle1" component={'span'} sx={{fontSize:'11px',fontWeight:400, color:'subtitle1.main'}}>Battery Charge</Typography>
                        <div className={batteryClasses.batteryOuter}>
                            {batteryStrokes.map((flag, idx) => (
                                <BatteryStroke key={`${idx}--%%`} item={idx}  visible={flag} />
                            ))}
                             <div className={batteryClasses.batteryValue}>
                              <Stack direction="row" alignItems='center'  justifyContent="space-between">
                                  <Current style={{width: '6px', height: '13px'}} className={eventData?.chargingStatus?.value ? 'pulse-animation' : ''} />
                                  <Typography component={'span'}  sx={{mx: 0.7, fontSize:'14px',fontWeight:'500', color:'slider.light'}}>{batteryState}%</Typography>
                              </Stack>
                             </div>
                        </div>
                  </Box>
                  <Box>
                      <DtoE title={'Distance To Empty'} value={eventData?.batteryDte ? eventData?.batteryDte?.value : 0} unit={'KMS'}/>
                  </Box>
                  <Box sx={{width:'60%', margin:'0 auto'}}>
                      <Stack direction="row" alignItems='center' justifyContent={'space-evenly'}>
                           <Box sx={{px:2}} textAlign='center'>
                              <IconOn />
                              <Typography component="div" sx={{fontSize:'11px', fontWeight:400, color:'subtitle1.main',textTransform:'capitalize' }}>Ignition State</Typography>
                              <Typography component="div" sx={{fontSize:'13px', fontWeight:400, color:'table.headerText',textTransform:'capitalize' }}>{eventData?.ignitionState?.value ? 'On' : 'N/A'}</Typography>
                          </Box>
                         <Divider sx={{ my:1, opacity:0.2, borderRadius:'14px',borderWidth: '1px', backgroundColor:'subtitle1.main'}} variant="vertical" flexItem></Divider>
                          <Box sx={{px:2}} textAlign='center'>
                              <DriveMode />
                              <Typography component="div" sx={{fontSize:'11px', fontWeight:400, color:'subtitle1.main',textTransform:'capitalize' }}>Drive Mode</Typography>
                              <Typography component="div" sx={{fontSize:'13px', fontWeight:400, color:'table.headerText',textTransform:'capitalize' }}>{eventData?.vehicleDriveMode ? vehicleDriveModes[eventData.vehicleDriveMode.value-1] : 'N/A'}</Typography>
                          </Box>
                          <Divider sx={{ my:1, opacity:0.2, borderRadius:'14px',borderWidth: '1px', backgroundColor:'subtitle1.main'}} variant="vertical" flexItem></Divider>
                          <Box sx={{px:2}} textAlign='center'>
                              <Connected />
                              <Typography component="div" sx={{fontSize:'11px', fontWeight:400, color:'subtitle1.main',textTransform:'capitalize' }}>Status</Typography>
                              <Typography component="div" sx={{fontSize:'13px', fontWeight:400, color:'table.headerText',textTransform:'capitalize' }}>{assetData?.commGatewayStatus ? assetData?.commGatewayStatus?.commGatewayState : 'N/A'}</Typography>
                          </Box>
                      </Stack>
                  </Box>
              </Box>
              <Box sx={{position:'absolute',top:'48%',left:'50px'}}>
                 <SpeedRpmMeter type={'speed'} value={eventData?.vehicleSpeed ? eventData?.vehicleSpeed?.value : 0} unit={'KM/H'} />
              </Box>
              <Box sx={{position:'absolute',top:'48%',right:'50px'}}>
                 <SpeedRpmMeter type={'rpm'} value={eventData?.vehicleRpm ? eventData?.vehicleRpm?.value : 0} unit={'RPM'} />
              </Box>
          </Grid>
            <Grid item xs={3.5} md={3.5} lg={3.5}>
               <Box sx={{p:2,height: '28rem', backgroundColor:'transparent', borderRadius:'15px', border:'1px solid #9391A0'}}>
                 <Grid container spacing={1.5}>
                   <Grid item xs={12} md={12} lg={12}>                   
                      <Typography component={'span'} sx={{fontSize:'18px',textTransform: 'capitalize', fontWeight: 400, fontStyle:'normal',color:'subtitle1.main'}}>Motor Type</Typography>
                      <Typography component={'span'} sx={{ ml:1, fontSize:'18px',textTransform: 'lowercase', fontWeight: 400, fontStyle:'normal',color:'tabColors.slectedText'}}>{eventData?.motorType?.value ? eventData?.motorType?.value : 'N/A'}</Typography>
                      <Divider sx={{my:2,opacity:0.2, borderRadius:'14px',borderWidth: '1px', backgroundColor:'subtitle1.main'}} variant="fullWidth" flexItem></Divider>
                   </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                        <Stack direction={'row'} justifyContent="space-between" spacing={2}>
                           <FlexDetailsItem title="Motor Torque" width="70%"  isMotor={true}  value={eventData?.motorTorque ? eventData?.motorTorque?.value : 'N/A'} custom={true} iconName={'motorTorque'}  unit={'Nm'}/>
                           <FlexDetailsItem title="Motor Current" isMotor={true} width="70%" value={eventData?.motorCurrent ? eventData?.motorCurrent?.value : 'N/A'} custom={true} iconName={'motorCurrent'}  unit={'mA'}/>
                        </Stack>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TemperatureSlider title={'Motor Temp'} isExtra={true} iconName={'motorTemp'} value={eventData?.motorTemp ? eventData?.motorTemp?.value : 0} unit={'°C'} />
                 </Grid>
                 <Grid item xs={12} md={12} lg={12}>
                    <TemperatureSlider title={'Motor Controller Temp'} isExtra={true} iconName={'motorTemp'} value={eventData?.motorControllerTemp ? eventData?.motorControllerTemp?.value : 0} unit={'°C'} />
                 </Grid>
                 </Grid>
              </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
                  <AlertFaults assetId={id} />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
                <Card variant="outlined" sx={{textAlign: 'center'}}>
                       { currentRole !== 'ROLE_SYS_B2B_ADMIN' ?  <FormControl component="fieldset" sx={{ textAlign: 'center', margin:2}}>
                            <FormControlLabel
                              value="start"
                              control={<IOSSwitch color="primary" sx={{m:1}} checked={showMap} onChange={handleShowMapChange}/>}
                              label="Show Location On Map"
                              labelPlacement="start"
                              sx={{fontSize:'18px',fontStyle:'normal',fontWeight:400, color:'subtitle1.main'}}
                            /> 
                        </FormControl> :  '' } 
                   {showMap ? eventData?.location ? <TempMap latitude={eventData?.location.latitude} longitude={eventData?.location?.longitude} /> : '' : <LineChart vehicleSpeedData={vehicleSpeedData} batteryPackTempData={batteryPackTempData} vehicleEcuTemp={vehicleEcuTemp} motorTemp={motorTemp} motorControllerTemp={motorControllerTemp} /> }
                </Card>
          </Grid>
      </Grid>
  );
}

export default AssetDashboard;
