import auth from './auth';
import axiosApiInstance from './axiosInstance';
import { dev } from '../config';


const getDevicesList = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInDataURL}/search/dashboard`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getEnterpriseDevicesList = (enterpriseID, params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInDataURL}/search/dashboard/${enterpriseID}`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getAssetDashboardDetails = (assetId) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInDataURL}/dashboard/asset/${assetId}`, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};


const getAssetId = (rsn, params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/assetmanagement/asset/rsn/${rsn}`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getLineChartData = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInDataURL}/asset/latesteventdata`, {
      headers: headers,
      params: params
    })
    .then((response) => {
      return response.data;
    })
};

const getTripChartData = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInDataURL}/asset/eventdata`, {
      headers: headers,
      params: params
    })
    .then((response) => {
      return response.data;
    })
};

const getCurrentVsTempData = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInDataURL}/asset/batchargedata`, {
      headers: headers,
      params: params
    })
    .then((response) => {
      return response.data;
    })
};

const getDischargeCurrentVsTempData = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInDataURL}/asset/batdischargedata`, {
      headers: headers,
      params: params
    })
    .then((response) => {
      return response.data;
    })
};

const getAlertFaults = (params, assetId) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInDataURL}/alerts/asset/${assetId}`, {
      headers: headers,
      params: params
    })
    .then((response) => {
      return response.data;
    })
};


const markAlertFaultRead = (params, assetId, alertUID, body) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .put(`${dev.loggedInDataURL}/alerts/asset/${assetId}/${alertUID}`, body, {
      headers: headers,
      params: params
    })
    .then((response) => {
      return response.data;
    })
};

const getTripsData = (assetId, params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInDataURL}/session/latest/asset/${assetId}`, {
      headers: headers,
      params: params
    })
    .then((response) => {
      return response.data;
    })
};

const getPaginatedTripsData = (assetId, params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInDataURL}/session/asset/${assetId}`, {
      headers: headers,
      params: params
    })
    .then((response) => {
      return response.data;
    })
};


const getGlobalDashboardData = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInDataURL}/global/dashboard`, {
      headers: headers,
      params: params
    })
    .then((response) => {
      return response.data;
    })
};

const getGlobalDashboardOrgData = (params, enterpriseID) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInDataURL}/global/dashboard/${enterpriseID}`, {
      headers: headers,
      params: params
    })
    .then((response) => {
      return response.data;
    })
};

const getGlobalDashboardSearchData = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInDataURL}/search/dashboard`, {
      headers: headers,
      params: params
    })
    .then((response) => {
      return response.data;
    })
};

const getGlobalDashboardSearchOrgData = (params, enterpriseID) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInDataURL}/search/dashboard/${enterpriseID}`, {
      headers: headers,
      params: params
    })
    .then((response) => {
      return response.data;
    })
}

let modules  =  {
  getDevicesList,
  getEnterpriseDevicesList,
  getAssetDashboardDetails,
  getAssetId,
  getLineChartData,
  getAlertFaults,
  markAlertFaultRead,
  getTripsData,
  getPaginatedTripsData,
  getTripChartData,
  getGlobalDashboardData,
  getGlobalDashboardOrgData,
  getGlobalDashboardSearchData,
  getGlobalDashboardSearchOrgData,
  getCurrentVsTempData,
  getDischargeCurrentVsTempData
};
export default modules