import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockClockOutlined';
import Typography from '@mui/material/Typography';

import SignIn from './SignIn';
import Verify from './Verify';
import SelectDomain from './SelectDomain';


import LoginBg from '../../Assets/LoginBg.jpeg'
import { useTheme } from '@emotion/react';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    padding: '3rem',
    backgroundImage: `url(${LoginBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto',
    backgroundPosition: 'center',
    backgroundClip: 'padding-box'
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  innerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});


export default function Login() {
  const theme = useTheme();
  const classes = useStyles();
  const [serverMessage, setServerMessage] = useState('');
  const [activeStep, setActiveStep] = useState(0);

  function stepSelector(step) {
    switch (step) {
      case 0:
        return <SelectDomain handleNext={handleNext} />;
      case 1:
        return <SignIn handleSignIn={handleSignIn} />;
      case 2:
        return <Verify goBackToSignIn={goBackToSignIn} />;
      default:
        return <SelectDomain />;
    }
  }



  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  const handleSignIn = (message) => {
    setServerMessage(message);
    handleNext();
  };
  const goBackToSignIn = () => {
    setServerMessage('');
    setActiveStep(activeStep - 1);
  };

  return (
    <Grid container component="main"  title="Login" className={classes.root}
      sx={{
        [theme.breakpoints.up('md')]: {
          justifyContent: 'flex-end'
        },
        [theme.breakpoints.down('md')]: {
          justifyContent: 'center'
        },
      }}>
      <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} >
        <Box className={classes.box} sx={{ margin: theme.spacing(4, 0) }} >
          <React.Fragment>
            <Avatar sx={{
              margin: theme.spacing(1),
              backgroundColor: theme.palette.secondary.main
            }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Typography sx={{
              marginTop: theme.spacing(2),
              color: theme.palette.success.light,
              width: '70%'
            }}
              variant="subtitle2">
              {serverMessage ? serverMessage : ''}
            </Typography>
            {stepSelector(activeStep)}
          </React.Fragment>
        </Box>
      </Grid>
    </Grid>
  );
}