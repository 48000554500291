import React, { useEffect, useState } from "react";
// material
import {
  Grid,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  MenuItem,
  InputBase
} from '@mui/material';

// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { EnterpriseListHead } from '../../components/_dashboard/enterprise';
import DeviceDetails from '../../components/_dashboard/devices/DeviceDetails';
import AllocateDeviceUser from "../../components/_dashboard/devices/AllocateDeviceUser";
import FactoryStatus from '../../components/_dashboard/devices/FactoryStatus';
import InventoryStats from '../../components/_dashboard/devices/InventoryStats';


import InfoIcon from '@mui/icons-material/Info';
import FactoryIcon from '@mui/icons-material/Factory';
import AddCardIcon from '@mui/icons-material/AddCard';
import Paper from '@mui/material/Paper';

import { useTheme } from '@emotion/react';


//Services
import CommGateway from '../../Services/devices';
import Enterprise from '../../Services/enterprise';
import User from '../../Services/user';

import { useDispatch } from "react-redux";
import { getTitle } from '../../actions/dashboardTitle';

import { useSelector } from "react-redux";



// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'commGatewayBID', label: 'Comm Gateway BID', alignRight: false },
  { id: 'rsn', label: 'RSN', alignRight: false },
  { id: 'assetCategory', label: 'Asset Category', alignRight: false },
  { id: 'assetType', label: 'Asset Type', alignRight: false },
  { id: 'service status', label: 'Service Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------



function InventoryStatistics() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const profile = useSelector(state => state.profile);
  const currentRole = profile ? profile.roles[0].roleName : ''
  const [filterType, setFilterType] = useState('rsn');
  const [totalUsers, setTotalUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [inventoryCount, setInventoryCount] = useState(0);
  const statusList = ['ACTIVE','SUSPEND','RESUME','DEACTIVATE'];
  const [assetCategoryList, setAssetCategoryList] = useState([]);
  const [filterCategoryList, setFilterCategoryList] = useState([]);
  const [selectedAssetCategory, setSelectedAssetCategory] = useState({});
  const [selectedStatus, setSelectedStatus] = useState('');
  const filterTypeOptions = [{ name: 'RSN', value: 'rsn' }, { name: 'Asset Category', value: 'assetCategory' }, { name: 'IMEI', value: 'imei' }, { name: 'MACID', value: 'macid' }, { name: 'Service Status', value: 'serviceStatus' }];
  const [details, setDetails] = useState({});
  const [factoryDetails, setFactoryDetails] = useState({});
  const [openDetailsModal, setDetailsModal] = useState(false);
  const [openAllocateModal, setAllocateModal] = useState(false);
  const [openStatusModal, setStatusModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deviceList, setDeviceList] = useState([]);


  const handleFilterType = (event) => {
    setSearchText('');
    setFilterType(event.target.value);
    setSelectedStatus('');
    setSelectedAssetCategory(filterCategoryList[0]);
  };

  const handleServiceStatus = (event) => {
    setSelectedStatus(event.target.value);
    setSearchText(event.target.value);
  };

  const handleAssetCategory = (event) => {
    setSelectedAssetCategory(event.target.value);
    if(event.target.value.assetCategory === 'All'){
      setSearchText('');
    } else {
      setSearchText(event.target.value);
    }
  };
  const handleDetailsModal = (state) => {
    setDetailsModal(state);
  }
  const handleAllocateModal = (state) => {
    setTotalUsers([]);
    setDetails({});
    setAllocateModal(state);
  }
  const handleStatusModal = (state) => {
    setStatusModal(state);
  }
  const handleSearchChange = (event) => {
    setSearchText(event.target.value)
  }
  const goToDetails = (details) => {
    setDetails(details);
    setDetailsModal(true);
  }
  const openFactoryStatus = (rsn) => {
    CommGateway.getFactoryStatus(rsn).then((result) => {
     setFactoryDetails(result);
     setStatusModal(true);
    },
    () => {
     setFactoryDetails({});
     setStatusModal(true);
    });
  }
  const openAllocateDevice = (details) => {
    getTotalUsers(details);
  }
  function getInventoryCount(){
    let params = {};
    if(selectedAssetCategory.assetCategory !== 'All'){
      params = {
        assetCategory: selectedAssetCategory.assetCategory,
        assetType: selectedAssetCategory.assetType
      }
    }
    Enterprise.getIndividualEnterpriseStats(params).then((result) => {
      setInventoryCount(result.enterpriseProvisioned);
    },
    () => {
     setInventoryCount(0);
    });
  }
  function getTotalUsers(details) {
    let role = '';
    if(currentRole === 'ROLE_SYS_B2B2C_ADMIN'){
      role = 'ROLE_SYS_B2B2C_USER';
    } else if(currentRole === 'ROLE_SYS_B2B_ADMIN'){
      role = 'ROLE_SYS_B2B_USER';
    }
    let params = {
      roleName: role
    }
    User.getFullUserList(params).then((result) => {
         setTotalUsers(result.users);
         setDetails(details);
         setAllocateModal(true);
     });
   }
  function getAssetCategoryList(){
    CommGateway.getAssetCategoryList().then((result) => {
      setAssetCategoryList(result.AssetCategories);
      let filterCategoryList = [{assetCategory: 'All', assetType:'All' },...result.AssetCategories];
      setFilterCategoryList(filterCategoryList);
       setSelectedAssetCategory(filterCategoryList[0]);
    },
    () => {
      setAssetCategoryList([]);
      setFilterCategoryList([]);
      setSelectedAssetCategory({});
    });
  }

  function getCommGatewayList() {
    let params = {
      onboardingState: 'ENTERPRISEPROVISIONED',
      pageSize: rowsPerPage,
      pageNumber: page
    };
    if(searchText !== ''){
      if(filterType === 'assetCategory'){
        params['assetCategory'] = searchText.assetCategory;
        params['assetType'] = searchText.assetType;
      } else {
        params[filterType] = searchText;
      }
    }
    Enterprise.getCommGatewayList(params).then((result) => {
      setDeviceList(result.CommGateways);
      setTotalCount(result.page.totalRecords);
    },
    () => {
        setDeviceList([]);
    });
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  function updateDeviceManagement() {
    getCommGatewayList();
    getInventoryCount();
  }
  function updateUsers(){
    let role = '';
    if(currentRole === 'ROLE_SYS_B2B2C_ADMIN'){
      role = 'ROLE_SYS_B2B2C_USER';
    } else if(currentRole === 'ROLE_SYS_B2B_ADMIN'){
      role = 'ROLE_SYS_B2B_USER';
    }
    let params = {
      roleName: role
    }
    User.getFullUserList(params).then((result) => {
         setTotalUsers(result.users);
     });
  }
   useEffect(() => {
    setFilterType(filterTypeOptions[1].value);
    getAssetCategoryList();
  }, [])
  useEffect(() => {
      getCommGatewayList();
  }, [page, searchText, rowsPerPage])
  useEffect(() => {
    if(Object.keys(selectedAssetCategory).length > 0){
      getInventoryCount();
    }
  }, [selectedAssetCategory])
  useEffect(()=>{
    dispatch(getTitle('Inventory Statistics'))
  },[])
  return (
    <Page title="Inventory Statistics">
      <Container maxWidth="xl">
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Inventory Statistics
          </Typography>
        </Stack> */}
        <Grid container spacing={3}>
           <Grid item xs={12} md={12} lg={12}>
            <InventoryStats/>
            </Grid>
          <Grid item xs={12} sm={12} md={12}>
             <Typography sx={{fontSize:'15px', fontWeight:'400' }} color={'subtitle1.main'} variant="subtitle3" gutterBottom>
              Filter Type
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Paper
              component="form"
              sx={{ display: 'flex', alignItems: 'center', width: 320 }}
            >
              <TextField
                sx={{ flex: 1 }}
                select
                value={filterType}
                onChange={handleFilterType}
                size="small"
              >
                {filterTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                ))}
              </TextField>
              {filterType === 'serviceStatus' ? 
               <TextField
                sx={{ flex: 1 }}
                select
                value={selectedStatus}
                onChange={handleServiceStatus}
                size="small"
              >
               {statusList.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                ))}
              </TextField> : '' }
              {filterType === 'assetCategory' ? 
               <TextField
                sx={{ flex: 1 }}
                select
                value={selectedAssetCategory}
                onChange={handleAssetCategory}
                size="small"
              >
               {filterCategoryList.map((option, index) => (
                <MenuItem key={index} value={option}>{ option.assetCategory === 'All' ? `All`:`${option.assetCategory} | ${option.assetType}`}</MenuItem>
                ))}
              </TextField> : '' }
              {filterType !== 'serviceStatus' && filterType !== 'assetCategory' ? 
               <InputBase
               sx={{ ml: 1, flex: 1 }}
               placeholder="Search"
               value={searchText}
               inputProps={{ 'aria-label': 'Search' }}
               onChange={handleSearchChange}
             /> : ''}
            </Paper>
            <Typography variant="subtitle" sx={{ml: 1, color: theme.palette.success.light, fontWeight: 'bold' }} gutterBottom>
              Current Inventory : {inventoryCount}
            </Typography>
          </Stack>
          </Grid>
        </Grid>
        <Card sx={{ my: 3 }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <EnterpriseListHead
                  headLabel={TABLE_HEAD}
                />
                <TableBody>
                  {deviceList.map((row) => {
                    const { commGatewayID, commGatewayBID, assetInfo, connectionDetail } = row;

                    return (
                      <TableRow
                        key={commGatewayID.rsn}
                      >
                        <TableCell align="center">{`${commGatewayBID}`}</TableCell>
                        <TableCell align="center">{`${commGatewayID.rsn}`}</TableCell>
                        <TableCell align="center">{`${assetInfo.assetCategory}`}</TableCell>
                        <TableCell align="center">{assetInfo.assetType}</TableCell>
                        <TableCell align="center">{connectionDetail.serviceStatus}</TableCell>
                        <TableCell align="center" style={{ width: 100 }}>
                          <Stack direction="row" spacing={1}>
                             <Button size="small" onClick={() => goToDetails(row)} variant="outlined" color="success" endIcon={<InfoIcon />}>
                              Details
                            </Button>
                            <Button  size="small" onClick={() => openFactoryStatus(commGatewayID.rsn)} variant="outlined" color="primary" endIcon={<FactoryIcon />}>
                              Status
                            </Button>
                            <Button  size="small" onClick={() => openAllocateDevice(row)} variant="outlined" color="secondary" endIcon={<AddCardIcon />}>
                              Allocate
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {deviceList.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={12}>No Device Present</TableCell>
                    </TableRow>
                  ) : ''}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <FactoryStatus  openForm={openStatusModal} handleCreateModal={handleStatusModal} details={factoryDetails}/>
        <DeviceDetails openForm={openDetailsModal} handleCreateModal={handleDetailsModal} details={details}/>
        <AllocateDeviceUser totalUsers={totalUsers} title="Allocate" openForm={openAllocateModal} handleCreateModal={handleAllocateModal} details={details} updateData={() => updateDeviceManagement()} updateUsers={() => updateUsers()}  profile={profile}/>
      </Container>
    </Page>
  );
}

export default InventoryStatistics;
