import React, { useEffect, useState } from "react";
// material
import {
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  AppBar,
  Container
} from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';


// components
import Page from '../../components/Page';
import EventConfiguration from "../../components/_dashboard/settings/EventConfiguration";
import CommandConfiguration from "../../components/_dashboard/settings/CommandConfiguration";
import AssetConfiguration from "../../components/_dashboard/settings/AssetConfiguration";

import { getTitle } from '../../actions/dashboardTitle';
import { useDispatch } from "react-redux";
//Service


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const StyledTabs = styled((props)  => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    position:'sticky',
    justifyContent: 'center',
    height:'5px',
    borderTopLeftRadius:'10px',
    borderTopRightRadius:'10px',
    backgroundColor: theme.palette.tabColors.selectedMenuBorder,
  },
  '& .MuiTabs-indicatorSpan': {
    width: '3px'
  },
})
);
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize:'1.125rem',
    marginRight: theme.spacing(1),
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    color: theme.palette.tabColors.normalText,
    backgroundColor: 'transparent',
    '&.Mui-selected': {
      color: theme.palette.tabColors.slectedText,
      backgroundColor: 'transparent',

    },
    '&.Mui-focusVisible': {
      color: 'red',
      backgroundColor: 'red'
    },
  }),
);

export default function Settings() {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(()=>{
    dispatch(getTitle('Settings'))
  },[])
  return (
    <Page title="Settings">
      <Container maxWidth="xl">
        {/* <Box sx={{ pb: 5, position: 'static' }}>
          <Typography variant="h4">Settings</Typography>
        </Box> */}
        <Box sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 2 }}>
             <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="styled tabs example"
              >
                <StyledTab label="Event Configuration" />
                <StyledTab label="Command Configuration" />
                <StyledTab label="Asset Metadata" />
              </StyledTabs>
              <TabPanel value={value} index={0}>
                   <Box sx={{width: '100%'}}>
                    <EventConfiguration />
                  </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                   <Box sx={{width: '100%'}}>
                    <CommandConfiguration />
                  </Box>
               </TabPanel>
               <TabPanel value={value} index={2}>
                   <Box sx={{width: '100%'}}>
                    <AssetConfiguration />
                  </Box>
               </TabPanel>
          </Box>
      </Container>
    </Page>
  );
}