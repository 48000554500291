import { useState } from 'react';
import { Outlet } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';

import {useNavigate} from "react-router-dom";


// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 80;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  backgroundColor: theme.palette.background.current,
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: 60,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function AssetLayout(props) {
  let navigate = useNavigate();
  return (
    <RootStyle>
      <DashboardNavbar type={props.type} goBack={() =>  navigate('/dashboard/organization')} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
