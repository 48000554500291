import PropTypes from 'prop-types';
// icons
// import { Icon } from '@iconify/react';
// @mui
import { Icon } from '@mui/material';

// ----------------------------------------------------------------------

Iconify.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object
};

export default function Iconify({ icon, sx, ...others }) {
  return <Icon sx={{ ...sx }} {...others}>{icon}</Icon>;
}
