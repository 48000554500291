import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import Dashboard from '../../Services/dashboard';
import IconButton from '@mui/material/IconButton';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { fDateTime } from '../../utils/formatTime';
import TablePagination from '@mui/material/TablePagination';
import Pagination from '@mui/material/Pagination';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import RightButton from "../../Assets/rightButton.svg";
import { ReactComponent as Alert } from "../../Assets/alert.svg";
import { ReactComponent as LocationPeg } from "../../Assets/locationPeg.svg";



import Carousel from 'react-grid-carousel'

import {map} from '../../config';
import { textTransform } from '@mui/system';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p:1}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const vehicleDriveModes = ['Eco', 'Sports', 'Insane'];

const NxtButton = (props) => (
  <span style={{position: 'absolute',
    top: 'calc(50% - 17.5px)',
    background: '#fff',
    borderRadius:'50%',
    boxShadow: '0 0 5px 0 #0009',
    zIndex: '10',
    cursor: 'pointer',
    fontSize: '10px',
    opacity: '0.6',
    transition: 'opacity 0.25s',
    right: '-15px'}}>
      <img src={RightButton}></img>
  </span>
)

const PrevButton = (props) => (
  <span style={{position: 'absolute',
    top: 'calc(50% - 17.5px)',
    background: '#fff',
    borderRadius:'50%',
    boxShadow: '0 0 5px 0 #0009',
    zIndex: '10',
    cursor: 'pointer',
    fontSize: '10px',
    opacity: '0.6',
    transition: 'opacity 0.25s',
    transform:'rotate(180deg)',
    left: '-15px'}}>
      <img src={RightButton}></img>
  </span>
)

export default function AlertFaults({assetId}) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [carouselPages, setCarousalPages] = useState(4);
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [alertsList, setAlertsList] = useState([]);
  const [faultsList, setFaultsList] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    getAlerts(newValue);
  };
  function  getAlerts(tabValue){
    let params = {
      assetID: assetId,
      alertCategory: tabValue === 0 ? 'ALERT': 'FAULT',
      pageSize: pageSize,
      pageNumber: page - 1
    }
    Dashboard.getAlertFaults(params,assetId).then((result) => {
           console.log('result',result);
           if(tabValue === 0){
            setAlertsList(result.Alerts);
            setTotalCount(Math.ceil(result.page.totalRecords / pageSize));
           }else {
            setFaultsList(result.Alerts);
            setTotalCount(Math.ceil(result.page.totalRecords / pageSize));
           }
    },
    () => {
      if(tabValue === 0){
        setAlertsList([]);
       }else {
        setFaultsList([])
       }
    });
  }

  function  markAlertFaultRead(alertUID, state){
    let params = {
      assetID: assetId,
      alertUID : alertUID
    }
    let body = {
        alertState: state
    }
    Dashboard.markAlertFaultRead(params, assetId, alertUID, body).then((result) => {
           setPage(1);
           getAlerts(value);
    },
    () => {
    });
  }

  function goToLocation(lat, long){
    let url = `${map.commonMapUrl}${lat},${long}`;
    window.open(url, '_blank');
  }

  const handlePaginationChange = (event, page) => {
    setPage(page);
    getAlerts(value);
  };

  useEffect(()=> {
      getAlerts(value);
 },[])
  return (<Box>
  <AppBar position="static"  sx={{backgroundColor:'transparent', boxShadow:'none'}}>
    <Tabs
      value={value}
      centered
      className="alertFaultTab"
      onChange={handleChange}
      aria-label="alets tabls"
    >
      <Tab label="Alerts" style={value === 0 ? {borderBottom:'2px solid #FC4C4C'} :  {borderBottom:'2px solid #C3C3C9'}} sx={{fontSize:'18px',fontWeight:400, color:'tabColors.slectedText', minWidth:'130px'}} {...a11yProps(0)} />
      <Tab label="Faults" style={value === 1 ? {borderBottom:'2px solid #FC4C4C'} :  {borderBottom:'2px solid #C3C3C9'}} sx={{fontSize:'18px',fontWeight:400, color:'tabColors.slectedText',minWidth:'130px'}} {...a11yProps(1)} />
    </Tabs>
  </AppBar>
    <TabPanel value={value} index={0}>
      <Box textAlign={'center'}>
      {alertsList.length === 0 ? <Typography variant="h6" sx={{p: 3, color: 'text.secondary'}}>No Alerts Presents</Typography> : <Carousel cols={5} rows={1} gap={10} pages={4} arrowRight={<NxtButton type="right" />} arrowLeft={<PrevButton type="left" />}>
         {alertsList.map((item, index) => ( 
         <Carousel.Item key={`${item.alertUID}-alert-@@`}>
            <Card sx={{width:'18rem',height:'100px',backgroundColor:'#FFFFFF', boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.05)'}}>
              <List sx={{ width: '100%'}}>
              <ListItem disableGutters sx={{p: 1}}  secondaryAction={<IconButton sx={{m:0.5, position:'relative',top:'-15px'}} edge="end" aria-label="location" onClick={() => goToLocation(item.data.latitude, item.data.longitude)}>
                                                                          <LocationPeg />
                                                                        </IconButton>}>
                <ListItemAvatar sx={{ml:1, mr:0.5, position:'relative',top:'-15px'}}>
                  <Avatar sx={{ width: 17, height: 17 }}>
                    <Alert />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={ <React.Fragment>
                <Typography
                  component="span"
                  sx={{fontSize:'12px', fontWeight:400}}
                  color="table.headerText"
                >
                  {item.alertID}
                </Typography>
                <Typography
                  component="div"
                  sx={{fontSize:'10px', fontWeight:400}}
                  color="subtitle1.main"
                >
              {fDateTime(item.timeStamp)}
             </Typography>
                    </React.Fragment>} 
                    secondary={
                      <React.Fragment>
                          <Stack direction="row" spacing={1} alignItems="center" justifyContent={'space-between'}>
                            <Typography
                              sx={{ display: 'inline',fontSize:'10px', fontWeight:400 }}
                              component="span"
                              color="subtitle1.main"
                            >
                              Drive Mode: <Typography variant='span' sx={{fontSize:'10px', fontWeight:400, color:'table.headerText',textTransform:'uppercase' }}>{vehicleDriveModes[parseInt(item.data.value) - 1]}</Typography> 
                            </Typography>
                            {item.alertState !== 'READ' ? <Button sx={{ ml:2, fontSize:'10px', fontWeight:400, color:'sidebar.text', textTransform:'uppercase' }} size={'small'} variant="text" onClick={()=> markAlertFaultRead(item.alertUID, 'READ')}>Mark As Read</Button> : <Button sx={{ ml:2,fontSize:'10px', fontWeight:400, color:'sidebar.text', textTransform:'uppercase' }} size={'small'} variant="text" onClick={()=> markAlertFaultRead(item.alertUID, 'UNREAD')}>Mark As Unread</Button>}
                          </Stack>
                            </React.Fragment>
                                }/>
               </ListItem>
             </List>
          </Card>
          </Carousel.Item>
            ))}
        </Carousel> }
      </Box>
    </TabPanel>
    <TabPanel value={value} index={1}>
    <Box textAlign={'center'}>
      {faultsList.length === 0 ? <Typography variant="h6" sx={{p: 3, color: 'text.secondary'}}>No Faults Presents</Typography> : <Carousel cols={5} rows={1} gap={10} pages={4} arrowRight={<NxtButton type="right" />} arrowLeft={<PrevButton type="left" />}>
         {faultsList.map((item, index) => ( 
         <Carousel.Item key={`${item.alertUID}-fault-%%`}>
            <Card sx={{width:'18rem',height:'100px',backgroundColor:'#FFFFFF', boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.05)'}}>
              <List sx={{ width: '100%'}}>
              <ListItem disableGutters sx={{p: 1}}  secondaryAction={<IconButton sx={{m:0.5, position:'relative',top:'-15px'}} edge="end" aria-label="location" onClick={() => goToLocation(item.data.latitude, item.data.longitude)}>
                                                                          <LocationPeg />
                                                                        </IconButton>}>
                <ListItemAvatar sx={{ml:1, mr:0.5, position:'relative',top:'-15px'}}>
                  <Avatar sx={{ width: 17, height: 17 }}>
                    <Alert />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={ <React.Fragment>
                <Typography
                  component="span"
                  sx={{fontSize:'12px', fontWeight:400}}
                  color="table.headerText"
                >
                  {item.alertID}
                </Typography>
                <Typography
                  component="div"
                  sx={{fontSize:'10px', fontWeight:400}}
                  color="subtitle1.main"
                >
              {fDateTime(item.timeStamp)}
             </Typography>
                    </React.Fragment>} 
                    secondary={
                      <React.Fragment>
                          <Stack direction="row" spacing={1} alignItems="center" justifyContent={'space-between'}>
                            <Typography
                              sx={{ display: 'inline',fontSize:'10px', fontWeight:400 }}
                              component="span"
                              color="subtitle1.main"
                            >
                              Drive Mode: <Typography variant='span' sx={{fontSize:'10px', fontWeight:400, color:'table.headerText',textTransform:'uppercase' }}>{vehicleDriveModes[parseInt(item.data.value) - 1]}</Typography> 
                            </Typography>
                            {item.alertState !== 'READ' ? <Button sx={{ ml:2, fontSize:'10px', fontWeight:400, color:'sidebar.text', textTransform:'uppercase' }} size={'small'} variant="text" onClick={()=> markAlertFaultRead(item.alertUID, 'READ')}>Mark As Read</Button> : <Button sx={{ ml:2,fontSize:'10px', fontWeight:400, color:'sidebar.text', textTransform:'uppercase' }} size={'small'} variant="text" onClick={()=> markAlertFaultRead(item.alertUID, 'UNREAD')}>Mark As Unread</Button>}
                          </Stack>
                            </React.Fragment>
                                }/>
               </ListItem>
             </List>
          </Card>
          </Carousel.Item>
            ))}
        </Carousel> }
      </Box>
     {/* <Paper style={{maxHeight: '500px', overflow: 'auto', textAlign: 'center'}}>
      {faultsList.length === 0 ? <Typography variant="h6" sx={{p: 3, color: 'text.secondary'}}>No Faults Presents</Typography> : <List>
      {faultsList.map((item, index) => (
                    <ListItem disableGutters sx={{p: 1}} key={`${item.alertUID}-fault`} secondaryAction={<IconButton sx={{m:0.5}} edge="end" aria-label="location" onClick={() => goToLocation(item.data.latitude, item.data.longitude)}>
                                                                                <LocationOnIcon />
                                                                              </IconButton>}>
                    <ListItemAvatar sx={{ml:1, mr:0.5}}>
                      <Avatar sx={{ width: 35, height: 35 }} className='alert-avatar'>
                        <AddAlertIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={ <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                         {item.alertID}
                        </Typography>
                      </React.Fragment>} 
                     secondary={
                      <React.Fragment>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.secondary"
                          >
                          {fDateTime(item.timeStamp)}
                          </Typography>
                         {item.alertState !== 'READ' ? <Button sx={{ ml:2 }} size={'small'} variant="text" onClick={()=> markAlertFaultRead(item.alertUID, 'READ')}>Mark As Read</Button> : <Button sx={{ ml:2 }} size={'small'} variant="text" onClick={()=> markAlertFaultRead(item.alertUID, 'UNREAD')}>Mark As Unread</Button>}
                        </Stack>
                       
                      </React.Fragment>
                    }/>
                  </ListItem>
                ))}
      </List>
      }
      {faultsList.length !== 0 ? <Pagination count={totalCount} color="primary" page={page} onChange={handlePaginationChange} /> : ''}
      </Paper> */}
    </TabPanel>
</Box> )
}
