import React, { useEffect, useState } from "react";
// material
import {
  Grid,
  Card,
  Stack,
  Typography,
  Paper,
  Box,
  Switch,
  Divider,
  Slider
} from '@mui/material';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
// components
import { useNavigate, useParams } from 'react-router-dom';
import Dashboard from '../../Services/dashboard';
import BatteryMixLineChart from "../../components/common/BatteryMixLineChart";
import TemperatureSlider from "../../components/common/TemperatureSlider";
import { styled } from '@mui/material/styles';
import BatteryCharging50Icon from '@mui/icons-material/BatteryCharging50';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import { useSelector } from "react-redux";
import { makeStyles } from '@mui/styles';
import BatteryLineChart from "../../components/common/BatteryLineChart";
import BatteryDoubleLineChart from "../../components/common/BatteryDoubleLineChart";
import AlertFaults from "../../components/common/AlertFaults";
import BoltIcon from '@mui/icons-material/Bolt';
import ElectricMeterIcon from '@mui/icons-material/ElectricMopedOutlined';
import VibrationIcon from '@mui/icons-material/VibrationOutlined';
import BatterySaverIcon from '@mui/icons-material/BatterySaverOutlined';
import EarbudsBatteryIcon from '@mui/icons-material/EarbudsBatteryOutlined';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import { ReactComponent as BatteryCycleCount } from "../../Assets/batteryCycleCount.svg";
import { ReactComponent as BatteryChargeCapacity } from "../../Assets/batteryChargeCapacity.svg";
import { ReactComponent as BatteryDischargeCapacity } from "../../Assets/batteryDischargeCapacity.svg";
import { ReactComponent as BatteryPackVoltage } from "../../Assets/batteryPackVoltage.svg";
import { ReactComponent as BatteryUsefulLife } from "../../Assets/batteryUsefulLife.svg";
import { ReactComponent as BatteryChargeMode } from "../../Assets/batteryChargeMode.svg";


import { ReactComponent as BatteryPack } from "../../Assets/AssetDashboard/icon_battery_pack.svg";
import { ReactComponent as EcuTemp } from "../../Assets/AssetDashboard/icon_ecu_temp.svg";
import { ReactComponent as MotorCurrent } from "../../Assets/AssetDashboard/icon_motor_current.svg";
import { ReactComponent as MotorTorque } from "../../Assets/AssetDashboard/icon_motor_torque.svg";
import { ReactComponent as MotorTemp } from "../../Assets/AssetDashboard/icon_motor_temp.svg";
import { ReactComponent as Session } from "../../Assets/AssetDashboard/icon_seesion.svg";
import { ReactComponent as Connected } from "../../Assets/AssetDashboard/icon_connected.svg";
import { ReactComponent as DriveMode } from "../../Assets/AssetDashboard/icon_drive_mode.svg";
import { ReactComponent as DistanceToEmpty } from "../../Assets/AssetDashboard/icon_distance_to_empty.svg";
import { ReactComponent as ChargingCurrent } from "../../Assets/AssetDashboard/icon_charging_current.svg";
import { ReactComponent as Current } from "../../Assets/chargingIcon.svg";
import { ReactComponent as IconOn } from "../../Assets/AssetDashboard/icon_on.svg";



import BatteryBg from "../../Assets/batteryBg.png";
import BatteryBackground from "../../Assets/batteryBg.svg";


import BatteryCell from "../../Assets/batteryCell.png";
import CellGroup from "../../Assets/cell_group.png";

import { withStyles } from '@mui/styles';


import BatteryHealthIcon from '@mui/icons-material/BatterySaverOutlined';
import Iconify from "../../components/common/Iconify";

const IconMapping = {
  percentage: <BatteryCharging50Icon color="info" />,
  health: <HealthAndSafetyIcon color="success" />,
  distance: <SocialDistanceIcon color="info" />,
  motorCurrent: <MotorCurrent width='30px' height='30px' />,
  motorCurrentSmall: <MotorCurrent width='30px' height='30px' />,
  session: <Session />,
  connected: <Connected />,
  charge: <BoltIcon />,
  distanceToEmpty: <DistanceToEmpty />,
  motorTorque: <MotorTorque width='30px' height='30px' />,
  motorTemp: <MotorTemp />,
  battery_saver_outlined: <BatteryHealthIcon />,
  ecuTemp: <EcuTemp width='30px' height='30px' />,
  batteryPack: <BatteryPack />,
  driveMode: <DriveMode />,
  chargingCurrent: <ChargingCurrent width='25px' height='25px' />,
  batteryCycleCount: <BatteryCycleCount width='25px' height='25px' />,
  chargingCapacity: <BatteryChargeCapacity width='30px' height='30px' />,
  disChargingCapacity: <BatteryDischargeCapacity width='30px' height='30px' />,
}

const CustomSlider = withStyles({
  root: {
    height: 3,
    padding: '4px 0'
  },
  thumb: {
    height: 0,
  },
  track: {
    height: 3,
  },
  rail: {
    color: "#DCDCDC"
  }
})(Slider);

function valuetext(value, unit) {
  return `${value}${unit}`;
}

function DtoE({ title, value, unit }) {
  return (
    <>
      <Box sx={{ width: '60%', margin: '5px auto 0px auto' }}>
        <Stack direction="row" alignItems='center' justifyContent={'space-between'}>
          <Box>
            <Box component={'span'}><Typography component={'span'} sx={{ fontSize: '11px', fontWeight: 400, color: 'subtitle1.main' }}>{title}</Typography></Box>
          </Box>
          <Typography component={'span'} sx={{ fontSize: '13px', fontWeight: 400, color: 'table.headerText' }}>{value} {unit}</Typography>
        </Stack>
        <Box>
          <CustomSlider
            aria-label={title}
            value={value}
            color={'slider'}
            getAriaValueText={(value) => valuetext(value, unit)}
            step={1}
            valueLabelDisplay="auto"
          // marks={marksForTemperatureSlider[iconName]}
          />
        </Box>
      </Box>
    </>
  );
}

const batteryModes = ['Discharging', 'Charging', 'Idle', 'Balancing'];
const batteyStyle = makeStyles({
  batteryOuter: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    height: '35px',
    width: '100%',
    border: '1px solid #DCDCDC',
    padding: '3px',
    borderRadius: '10px',
  },
  batteryInner: {
    width: '100%',
    margin: '0 2px',
    backgroundColor: '#FFFFFF',
  },
  batteryStrokeFill: {
    width: '100%',
    margin: '0 2px 0 1px',
    backgroundColor: '#07B27F',
    flex: '1 1 auto',
    zIndex: 500
  },
  batteryStrokeEmpty: {
    width: '100%',
    margin: '0 2px 0 1px',
    backgroundColor: 'transparent',
    flex: '1 1 auto',
    zIndex: 500
  },
  curverdLeftEdge: {
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px'
  },
  curverdRightEdge: {
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px'
  },
  batteryValue: {
    position: 'absolute',
    right: '0px',
    top: '6px',
    zIndex: 1000
  }
});

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const mainStyle = makeStyles({
  batterymainDiv: {
    height: '34rem',
    backgroundColor: 'transparent',
    backgroundImage: `url(${BatteryBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto',
    backgroundPosition: 'center',
    position:'relative'
  },
  bikeImg: {
    display: 'inline-block',
    width: '45px',
    height: '45px'
  }
})



function OneCell(props) {
  const classes = mainStyle();
  const MyComponent = React.forwardRef(function MyComponent(props, ref) {
    return (
      <div {...props} ref={ref}>
        <Box component={'span'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography component={'span'} sx={{ fontSize: '9px', fontWeight: 400, color: 'table.headerText' }}>Cell {props.id + 1}</Typography>
          <img className={classes.bikeImg} src={CellGroup} />
          <Typography component={'span'} sx={{ fontSize: '9px', fontWeight: 400, color: 'table.headerText' }}>{Number(props.voltage).toFixed(2)} V</Typography>
        </Box>
      </div>
    );
  });
  return (
    <>
      <BootstrapTooltip title={<React.Fragment>
        <Typography component={'p'} sx={{ fontSize: '11px', fontWeight: 400 }}>{props.name}</Typography>
        <Typography component={'p'} sx={{ fontSize: '11px', fontWeight: 400 }}>{Number(props.voltage).toFixed(1)} V</Typography>
      </React.Fragment>
      } placement="bottom" TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
        <MyComponent {...props} />
      </BootstrapTooltip>
    </>
  );
}
function BatteryStroke({ visible, item }) {
  const classes = batteyStyle();
  return (
    <div
      className={`${visible ? classes.batteryStrokeFill : classes.batteryStrokeEmpty
        } ${item === 0 ? classes.curverdLeftEdge : ''} ${item === 19 ? classes.curverdRightEdge : ''}`}

    ></div>
  );
}
function TopInfoSetion({ name, value, border }) {
  const RootStyle = styled('div')(({ theme }) => ({
    boxShadow: 'none',
    padding: theme.spacing(1, 4),
    color: theme.palette.primary,
    borderRight: 'none',
  }));
  return (
    <RootStyle>
      <Typography mb={1} variant="subtitle1" component={'div'} sx={{ textTransform: 'capitalize', color: 'subtitle1.main', fontSize: '12px', fontWeight: 400 }}>
        {name}
      </Typography>
      <Typography variant="subtitle1" component={'div'} sx={{ textTransform: 'capitalize', color: 'subtitle1.secondary', fontSize: '13px', fontWeight: 400 }}>{value}</Typography>
    </RootStyle>
  );
}


function FlexDetailsItem({ title, value, iconName, custom, unit, width = '100%', height = '100px', isMotor, isCapacity }) {
  return (
    <>
      <Paper variant="outlined" component={'div'} sx={{ py: isMotor ? 0 : 1, textAlign: 'center', width: width, height: height }}>
        <Box>{custom ? IconMapping[iconName] : <Iconify icon={iconName} width="11px" height="11px" />}</Box>
        <Typography variant="body2" sx={{ fontSize: '10px', fontWeight: 400, color: 'subtitle1.main', mt: isMotor ? 0 : 0.5 }}>
          {title}
        </Typography>
        {isCapacity ? <Typography sx={{ fontSize: '13px', fontWeight: 400, color: 'subtitle1.secondary' }}>{value}</Typography> :
          <Typography sx={{ fontSize: '13px', fontWeight: 400, color: 'subtitle1.secondary' }}>{value === 'N/A' ? value : `${Number(value).toFixed(2)} ${unit}`}</Typography>}
      </Paper>
    </>
  );
}


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 43,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: '#9391A0',
      '& + .MuiSwitch-track': {
        backgroundColor: '#FFFFFF',
        opacity: 1,
        border: '1px solid #9391A0',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        // opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#9391A0',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    backgroundColor: '#C4C4C4',
    opacity: 1,
    border: '1px solid #9391A0',
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function BatteryDashboard() {
  const classes = mainStyle();
  const batteryClasses = batteyStyle();
  let { id } = useParams();
  let navigate = useNavigate();
  const [batteryStrokes, setBatteryStrokes] = useState(Array(20).fill(true));
  const [batteryState, setBatteryState] = useState(0);
  const dashboardData = useSelector(state => state.batteryDashboard);
  const [assetData, setAssetData] = useState({});
  const [eventData, setEventData] = useState({});
  const [batteryFixData, setBatteryFixData] = useState(null);
  const [dischargeCurrentData, setDischargeCurrentData] = useState([]);
  const [cellData, setCellData] = useState([]);
  const [packVoltageData, setPackVoltageData] = useState([]);
  const [packTempData, setPackTempData] = useState([]);
  const [disCurrentVsTempData, setDisCurrentVsTempData] = useState([]);
  function getAssetDashboardData() {
    Dashboard.getAssetDashboardDetails(id).then((result) => {
      setAssetData(result.assetData);
      setEventData(result.eventData);
      setBatteryState(result.eventData?.batterySoc?.value);
      let cellData = [];
      for (const [key, value] of Object.entries(result.eventData.cellVoltage)) {
        cellData.push({
          name: key,
          voltage: value
        });
      }
      setCellData(cellData);
      let obj = {};
      result.assetData.metaData.forEach((v) => {
        obj[v.fieldName] = v.fieldValue;
      });
      setBatteryFixData(obj);
    },
      () => {

      });
  }

  async function getDischargeCurrentVsTempData(assetId, startTime, endTime){
    let params = {
      assetID: assetId,
      startTime: startTime,
      endTime: endTime,
      pageSize: 1000,
      pageNumber: 0, 
      direction: 'ASC'
    }
    return new Promise((resolve) => {
      Dashboard.getDischargeCurrentVsTempData(params).then((result) => {
        resolve(result.eventData);
        },
        () => {
          resolve([]);
        });
    });
  }

  function  getSessionData(){
    let params = {
      numberOfSessions: 5
    }
    Dashboard.getTripsData(id, params).then((result) => {
       if(result.sessions[0].sessionStatus === 30 || result.sessions[0].sessionStatus === 40){
            let startTime =  result.sessions[0].sessionEndTime;
            let endTime =  Date.now();
            graphDataMapping(startTime, endTime);
       }else {
        // console.log('in else');
          let endTime = result.sessions[0].sessionStartTime;
          let filteredResult = result.sessions.filter(session => (session.sessionStatus === 30 || session.sessionStatus === 40));
          let startTime =  filteredResult[0].sessionEndTime;  
          // console.log('in else',filteredResult);
          // console.log('endTime',endTime);
          // console.log('startTime',startTime);
          graphDataMapping(startTime, endTime);
       }
    },
    () => {
     
    });
  }

  const calculateBatteryStrokes = level => {
    if (level > 0) {
      let totalStrokesCount = Math.ceil((level * 20) / 100) - 1;
      setBatteryStrokes(
        Array(20)
          .fill(true)
          .map((d, i) => (i <= totalStrokesCount ? true : false))
      );
    }
  };

  async function graphDataMapping(startTime, endTime) {
    let disCurrentVsTemp = await getDischargeCurrentVsTempData(id,startTime,endTime);
      let dataArr = [];
      let currVsTime = [];
      let packVoltage = [];
      let packTemp = [];
      disCurrentVsTemp.dischargingCurrent.forEach((v,i)=>{
           packVoltage.push([disCurrentVsTemp.timeStamp[i], disCurrentVsTemp.packVoltage[i]]);
           packTemp.push([disCurrentVsTemp.timeStamp[i], disCurrentVsTemp.batteryPackTemp[i]]);
           currVsTime.push([disCurrentVsTemp.timeStamp[i], v]);
           dataArr.push({
            x: disCurrentVsTemp.batteryPackTemp[i],
            y: v,
           })
      })
      setDisCurrentVsTempData(dataArr);
      setDischargeCurrentData(currVsTime);
      setPackVoltageData(packVoltage);
      setPackTempData(packTemp);
  };

  useEffect(() => {
    getAssetDashboardData();
    getSessionData();
  }, [])
  useEffect(() => {
    const interval = setInterval(async () => {
      getAssetDashboardData();
    }, 10000);

    return () => {
      clearInterval(interval);
    }
  }, []);
  useEffect(() => {
    calculateBatteryStrokes(batteryState);
  }, [batteryState]);
  return (
    <Grid container spacing={2} alignItems={'center'}>
      <Grid item xs={12} md={12} lg={12} display={'flex'} justifyContent={'center'}>
        <TopInfoSetion name={assetData?.assetCategory} value={assetData?.assetType} border={false}></TopInfoSetion>
        <Divider sx={{ my: 0.5, opacity: 0.2, borderRadius: '14px', borderWidth: '1px', backgroundColor: 'subtitle1.main' }} variant="vertical" flexItem></Divider>
        <TopInfoSetion name={'Battery Make'} value={batteryFixData?.batteryMake} border={false}></TopInfoSetion>
        <Divider sx={{ my: 0.5, opacity: 0.2, borderRadius: '14px', borderWidth: '1px', backgroundColor: 'subtitle1.main' }} variant="vertical" flexItem></Divider>
        <TopInfoSetion name={'RSN'} value={dashboardData?.rsn} border={false}></TopInfoSetion>
        <Divider sx={{ my: 0.5, opacity: 0.2, borderRadius: '14px', borderWidth: '1px', backgroundColor: 'subtitle1.main' }} variant="vertical" flexItem></Divider>
        <TopInfoSetion name={'IMEI'} value={assetData?.imei} border={false}></TopInfoSetion>
        <Divider sx={{ my: 0.5, opacity: 0.2, borderRadius: '14px', borderWidth: '1px', backgroundColor: 'subtitle1.main' }} variant="vertical" flexItem></Divider>
        <TopInfoSetion name={'Service Status'} value={assetData?.commGatewayStatus?.serviceStatus} border={false}></TopInfoSetion>
      </Grid>
      <Grid item xs={12} md={12} lg={12} display={'flex'} justifyContent={'center'} className={classes.batterymainDiv}>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Box textAlign={'center'} mt={7}>
              <Typography component="div" sx={{ fontSize: '12px', fontWeight: 400, color: 'subtitle1.main', textTransform: 'capitalize' }}>{assetData?.assetFriendlyName}</Typography>
              <Typography component="div" sx={{ mt: 0.2, fontSize: '13px', fontWeight: 500, color: 'subtitle1.secondary', textTransform: 'capitalize' }}>Battery ID : {batteryFixData?.batteryID}</Typography>
            </Box>
            <Box sx={{ width: '60%', margin: '48px auto 32px auto' }} display={'flex'} justifyContent={'space-evenly'}>
              {cellData.map((item, idx) => (
                <OneCell key={`${idx}--%@#$`} id={idx} name={item.name} voltage={item.voltage} />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={12} display="flex" justifyContent="center" alignItems="center" mr={3}>
            <Box sx={{ p: 1, backgroundColor: 'transparent', width:'15%',height:'40vh'}}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}  mb={7}>
                  <Stack direction={'row'} justifyContent="space-between" spacing={2}>
                    <FlexDetailsItem title="Charging Current" width="70%" value={eventData?.chargingStatus?.value ? eventData?.chargingCurrent?.value : 'N/A'} iconName={'chargingCurrent'} custom={true} unit={'mA'} />
                    <FlexDetailsItem title="Discharging Current" width="70%" value={!eventData?.chargingStatus?.value ? eventData?.dischargingCurrent?.value : 'N/A'} iconName={'chargingCurrent'} custom={true} unit={'mA'} />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TemperatureSlider title={'Battery Pack Temp'} iconName={'batteryPack'} value={eventData?.batteryPackTemp ? eventData?.batteryPackTemp?.value : 0} unit={'°C'} />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ p: 1, width:'32%',height:'40vh',backgroundColor: 'transparent'}}>
               <Grid container>
                  <Grid item xs={12} md={12} lg={12} mb={7}>
                    <table style={{ width: '90%', margin: '0 auto', border: '1px solid rgba(0, 0, 0, 0.12)', borderCollapse: 'collapse', fontSize: '12px', fontWeight:400, color:'#484859', textAlign:'center'}}>
                    <tr>
                      <td style={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderCollapse: 'collapse' }}>Chemistry : {batteryFixData?.chemistry}</td>
                      <td style={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderCollapse: 'collapse' }}>Energy Rating : {batteryFixData?.energyRating}</td>
                      <td style={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderCollapse: 'collapse' }}>Cell Form Factor : {batteryFixData?.chemistry}</td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderCollapse: 'collapse' }}>Cell Count : {batteryFixData?.cellCount}</td>
                      <td style={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderCollapse: 'collapse' }}>Battery ID : {eventData?.batteryId?.value}</td>
                      <td style={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderCollapse: 'collapse' }}>Cell Capacity : {batteryFixData?.cellCapacity} </td>
                    </tr>
                  </table>
                   <Box sx={{ width: '90%', margin: '0 auto' }}>
                        <Typography variant="subtitle1" component={'span'} sx={{ fontSize: '11px', fontWeight: 400, color: 'subtitle1.main' }}>Battery Charge</Typography>
                        <div className={batteryClasses.batteryOuter}>
                          {batteryStrokes.map((flag, idx) => (
                            <BatteryStroke key={`${idx}--%%`} item={idx} visible={flag} />
                          ))}
                          <div className={batteryClasses.batteryValue}>
                            <Stack direction="row" alignItems='center' justifyContent="space-between">
                              <Current style={{ width: '6px', height: '13px' }} className={eventData?.chargingStatus?.value ? 'pulse-animation' : ''} />
                              <Typography component={'span'} sx={{ mx: 0.7, fontSize: '14px', fontWeight: '500', color: 'slider.light' }}>{batteryState}%</Typography>
                            </Stack>
                          </div>
                        </div>
                    </Box>
                  </Grid>
                   <Grid item xs={12} md={12} lg={12}>
                      <Box sx={{ width:'90%', mx:'auto'}}>
                          <Stack direction="row" alignItems='center' justifyContent={'space-evenly'}>
                            <Box sx={{ px: 2 }} textAlign='center'>
                              <BatteryCycleCount width="25px" height="25px"/>
                              <Typography component="div" sx={{ mt:0.5, fontSize: '11px', fontWeight: 400, color: 'subtitle1.main', textTransform: 'capitalize' }}>Battery Cycle Count</Typography>
                              <Typography component="div" sx={{ fontSize: '13px', fontWeight: 400, color: 'table.headerText', textTransform: 'capitalize' }}>{batteryFixData?.cellCount}</Typography>
                            </Box>
                            <Divider sx={{ my: 1, opacity: 0.2, borderRadius: '14px', borderWidth: '1px', backgroundColor: 'subtitle1.main' }} variant="vertical" flexItem></Divider>
                            <Box sx={{ px: 2 }} textAlign='center'>
                              <BatteryChargeMode width="25px" height="25px" />
                              <Typography component="div" sx={{ mt:0.5, fontSize: '11px', fontWeight: 400, color: 'subtitle1.main', textTransform: 'capitalize' }}>Current Battery Mode</Typography>
                              <Typography component="div" sx={{ fontSize: '13px', fontWeight: 400, color: 'table.headerText', textTransform: 'capitalize' }}>{eventData?.batteryMode ? batteryModes[eventData.batteryMode.value] : 'N/A'}</Typography>
                            </Box>
                            <Divider sx={{ my: 1, opacity: 0.2, borderRadius: '14px', borderWidth: '1px', backgroundColor: 'subtitle1.main' }} variant="vertical" flexItem></Divider>
                            <Box sx={{ px: 2 }} textAlign='center'>
                              <BatteryUsefulLife width="25px" height="25px" />
                              <Typography component="div" sx={{mt:0.5, fontSize: '11px', fontWeight: 400, color: 'subtitle1.main', textTransform: 'capitalize' }}>Remaining Useful Life</Typography>
                              <Typography component="div" sx={{ fontSize: '13px', fontWeight: 400, color: 'table.headerText', textTransform: 'capitalize' }}>{'55 Cycles'}</Typography>
                            </Box>
                            <Divider sx={{ my: 1, opacity: 0.2, borderRadius: '14px', borderWidth: '1px', backgroundColor: 'subtitle1.main' }} variant="vertical" flexItem></Divider>
                            <Box sx={{ px: 2 }} textAlign='center'>
                              <BatteryPackVoltage width="25px" height="25px" />
                              <Typography component="div" sx={{mt:0.5, fontSize: '11px', fontWeight: 400, color: 'subtitle1.main', textTransform: 'capitalize' }}>Battery Pack Voltage</Typography>
                              <Typography component="div" sx={{ fontSize: '13px', fontWeight: 400, color: 'table.headerText', textTransform: 'capitalize' }}>{Number(eventData?.packVoltage?.value).toFixed(1)}</Typography>
                            </Box>
                          </Stack>
                      </Box>
                    </Grid>
                  </Grid>
            </Box>
            <Box sx={{ p: 1,height:'40vh', backgroundColor: 'transparent', width:'15%'}}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12} mb={7}>
                  <Stack direction={'row'} justifyContent="space-between" spacing={2}>
                    <FlexDetailsItem title="Total Charge Capacity" width="70%" isCapacity={true} isMotor={true} value={eventData?.totalChargeCapacity ? eventData?.totalChargeCapacity?.value : 0} custom={true} iconName={'chargingCapacity'} unit={'Ah'} />
                    <FlexDetailsItem title="Total Discharge Capacity" isCapacity={true} isMotor={true} width="70%" value={eventData?.totalDischargeCapacity ? eventData?.totalDischargeCapacity?.value : 0} custom={true} iconName={'disChargingCapacity'} unit={'Ah'} />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12} lg={12} >
                  <TemperatureSlider title={'Battery State of Health'} iconName={'batteryHealth'} value={eventData?.batterySoh ? eventData?.batterySoh.value : 0} unit={'%'} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <AlertFaults assetId={id} />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Card sx={{ px: 1, py: 1, height:'40vh' }}>
          <Grid container sx={{ height:'100%' }}>
            <Grid item xs={6} md={6} lg={6} sx={{ borderRight: `3px solid rgb(195, 195, 201, 0.1)` }}>
              <BatteryLineChart data={dischargeCurrentData} name={'Discharge Current'} />
            </Grid>
             <Grid item xs={6} md={6} lg={6}>
                <BatteryDoubleLineChart data={disCurrentVsTempData} name1={'Discharge Current'} name2={'Pack Temp'} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Card sx={{ px: 1, py: 1, height:'40vh' }}>
             <BatteryMixLineChart title={'Discharge Current'} chargingCurrent={dischargeCurrentData} batteryPackTempData={packTempData} batteryPackVoltage={packVoltageData} />
        </Card>
      </Grid>
    </Grid>
  );
}

export default BatteryDashboard;
