import React, {useState, useEffect} from 'react';

import ReactApexChart from 'react-apexcharts';

// material
import {
  Grid,
  Box,
  Typography,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Stack,
  TextField,
  MenuItem
} from '@mui/material';

//Services
import CommGateway from '../../../Services/devices';

export default function Inventory({openForm, id, polarSeriesData, handleEditModal, changePolarChartData}) {
  const [selectedAssetCategory, setSelectedAssetCategory] = useState('');
  const [filterCategoryList, setFilterCategoryList] = useState([]);
  let state = {
    options: {
      chart: {
        type: 'polarArea',
      },
      labels: ['Enterprise Inventory','User Provisioned','Asset Provisioned','Total'],
      stroke: {
        colors: ['#fff']
      },
      fill: {
        opacity: 0.8
      },
      legend: {
        position: 'right',
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex]
        }
      },
      yaxis:{
        forceNiceScale: true,
        labels:{
          formatter: function(val, index) {
            return val.toFixed(1);
          }
        }
       
      },
    },
  };

  const handleClose = () => {
    handleEditModal(false);
  };

  const handleAssetCategory = (event) => {
    setSelectedAssetCategory(event.target.value);
    changePolarChartData(event.target.value, id);
  };

  function getAssetCategoryList(){
    CommGateway.getAssetCategoryList().then((result) => {
      let filterCategoryList = [{assetCategory: 'All', assetType: 'All'},...result.AssetCategories];
      setFilterCategoryList(filterCategoryList);
    },
    () => {
      setFilterCategoryList([]);
      setSelectedAssetCategory({});
    });
  }

  useEffect(()=>{
    getAssetCategoryList();
  },[]);

  useEffect(()=>{
    if(filterCategoryList.length > 0){
      setSelectedAssetCategory(filterCategoryList[0]);
    }
  },[id]);


  return (
    <Dialog fullWidth maxWidth={'sm'} open={openForm} onClose={handleClose} onBackdropClick={handleClose} transitionDuration={{ enter: 500, exit: 500 }}>
        <DialogTitle>Inventory</DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
               <Stack>
                 <Stack direction={{ xs: 'column', sm: 'row' }}>
                       <TextField
                        select
                        sx={{ width: 150, mr: 1 , mt: 1 }}
                        size={'small'}
                        label="Select Asset Category"
                        variant="outlined"
                        value={selectedAssetCategory}
                        onChange={handleAssetCategory}
                      >
                        {filterCategoryList.map((option, index) => (
                          <MenuItem key={index} value={option}>{ option.assetCategory === 'All' ? `All`:`${option.assetCategory} | ${option.assetType}`}</MenuItem>
                        ))}
                      </TextField>
                 </Stack>
               </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <ReactApexChart  options={state.options} series={polarSeriesData} type="polarArea" />
            </Grid>
            </Grid>
        </DialogContent>
        <Box sx={{px:1, py:1 ,backgroundColor:'transparent'}}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} align='center'>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
  );
}
