import auth from './auth';
import axiosApiInstance from './axiosInstance';
import { dev } from '../config';

const getEnterpriseStats = () => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAAAURL}/enterprisestat`, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getEnterpriseList = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAAAURL}/enterprises`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getTotalEnterpriseList = () => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAAAURL}/enterprises/searchall`, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getEnterpriseSearch = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAAAURL}/enterprises/search`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const createEnterprise = (body) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .post(`${dev.loggedInAAAURL}/enterprises`, body, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const allocateDevice = (body) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .put(`${dev.loggedInAssetURL}/logistic/commgateway`, body, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const allocateDeviceToUser = (body) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .put(`${dev.loggedInAssetURL}/commgateway`, body, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const createEnterpriseAdmin = (enterpeiseId, body) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .post(`${dev.loggedInAAAURL}/${enterpeiseId}/users`, body, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getEnterpriseDetails = (enterpriseId) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAAAURL}/enterprises/${enterpriseId}`, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const deleteEnterprise = (enterpriseId) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .delete(`${dev.loggedInAAAURL}/enterprises/${enterpriseId}`, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const updateEnterprise = (enterpriseId, body) => {
  let accessToken = auth.getCurrentAccessToken();

  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .put(`${dev.loggedInAAAURL}/enterprises/${enterpriseId}`, body, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getIndividualEnterpriseStats = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/commgateway/summary`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};


const getStateSummary = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/commgateway/state/summary`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getCommGatewayList = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/search/commgateway`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getAllEnterpriseStats = (enterpriseId, params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/commgateway/summary/${enterpriseId}`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};


let modules  =  {
  getEnterpriseSearch,
  createEnterpriseAdmin,
  getEnterpriseList,
  createEnterprise,
  getEnterpriseStats,
  getEnterpriseDetails,
  deleteEnterprise,
  updateEnterprise,
  getIndividualEnterpriseStats,
  getAllEnterpriseStats,
  allocateDevice,
  getTotalEnterpriseList,
  getCommGatewayList,
  allocateDeviceToUser,
  getStateSummary
};
export default modules