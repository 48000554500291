import {
  SET_TITLE,
} from "./type";


export const getTitle = (title) => (dispatch) => {
  dispatch({
    type: SET_TITLE,
    payload: title
  });
}

