import React from 'react';
// material
import {
  Grid,
  Box,
  Typography,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Paper
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import {map} from '../../../config';


function DetailsItem({ name, value }) {
  return (
    <>
    <Paper elevation={24} sx={{width:'100%', px:2, py:2, mt:1}}>
        <Grid container> 
            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="subtitle">{name}</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>{value}</Typography>
            </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default function EnterpriseDetails({ openForm, handleCreateModal, details, goToCreateAdmin }) {
  const handleClose = () => {
    handleCreateModal(false);
  };
  const handleCreateAdmin = ()=> {
    handleClose();
   goToCreateAdmin(details.enterpriseDetails.enterpriseID, details.enterpriseDetails.enterpriseType, details.enterpriseDetails.enterpriseName);
  }
  const openMap = ()=>{
    let lat = details.enterpriseDetails.latitude;
    let long = details.enterpriseDetails.longitude;
    let url = `${map.commonMapUrl}${lat},${long}`;
    window.open(url, '_blank');
  }
  return (
    <Dialog fullWidth maxWidth={'md'} open={openForm} onClose={handleClose} onBackdropClick={handleClose} transitionDuration={{ enter: 500, exit: 500 }}>
      <DialogTitle>
      <Box my={2} sx={{backgroundColor:'transparent'}}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} align='right'>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleCreateAdmin}
                  >
                    Create Enterprise Admin
                  </Button>
                </Grid>
              </Grid>
            </Box>
      </DialogTitle>
      <DialogContent sx={{overflow:'hidden'}}>
        {details.enterpriseDetails ? <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={6} sx={{ overflow: 'hidden' }}>
            <Card sx={{ px: 2, py: 2 }}>
              <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>Enterprise Details</Typography>
              {/* <Grid container sx={{ textAlign: 'center', border: 1 }}> */}
                <DetailsItem name={'Enterprise ID'} value={details.enterpriseDetails.enterpriseID || ''} />
                <DetailsItem name={'Enterprise Name'} value={details.enterpriseDetails.enterpriseName || ''} />
                <DetailsItem name={'Enterprise Type'} value={details.enterpriseDetails.enterpriseType || ''} />
                <DetailsItem name={'Phone Number'} value={details.enterpriseDetails.phoneNumber || ''} />
                <Paper elevation={24} sx={{width:'100%', px:2, py:2, mt:1}}>
                  <Grid container> 
                    <Grid item xs={6} md={6} lg={6} >
                      <Typography variant="subtitle">Address</Typography>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} >
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>{`${details.enterpriseDetails.address},${details.enterpriseDetails.city}`}</Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>{`${details.enterpriseDetails.country},${details.enterpriseDetails.pinCode}`}</Typography>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper elevation={24} sx={{width:'100%', px:2, py:2, mt:1}}>
                  <Grid container> 
                    <Grid item xs={6} md={6} lg={6}>
                      <Typography variant="subtitle">Location</Typography>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} >
                      <LocationOnIcon onClick={openMap} style={{fontSize: '34px'}}/>
                     </Grid>
                  </Grid>
                </Paper>
              {/* </Grid> */}
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sx={{ height: '70vh', overflowY: 'auto' }}>
            {details.adminDetails.length === 0 ? <Typography variant="h6" sx={{ mb: 2, textAlign: 'center', alignItems: 'center' }}>No Admin Present</Typography> : ''}
            {details.adminDetails.map((admin, index) => (
              <Card key={index} sx={{ mb: 2, px: 2, py: 2 }}>
                <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>{`Admin:${index + 1}`}</Typography>
                {/* <Grid container sx={{ textAlign: 'center', border: 1 }}> */}
                  <DetailsItem name={'Email ID'} value={admin.emailID || ''} />
                  <DetailsItem name={'First Name'} value={admin.firstName || ''} />
                  <DetailsItem name={'Last Name'} value={admin.lastName || ''} />
                  <DetailsItem name={'Mobile Number'} value={admin.mobileNumber || ''} />
                {/* </Grid> */}
              </Card>
            ))}
            
          </Grid>
        </Grid> : ''}
      </DialogContent>
        <Box sx={{px:1, py:1 ,backgroundColor:'transparent'}}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} align='center'>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
    </Dialog>
  );
}
