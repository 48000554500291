import { dev } from "../config";
import axios from "axios";
import qs from 'qs';

let headers = {
  'Content-Type': 'application/x-www-form-urlencoded'
}

const login = (orgId, body) => {
  let clientSecret = `Org` + orgId.substring(0, 6) + `@123#`;
  return axios
    .post(dev.login, qs.stringify(body), {
      auth: {
        username: orgId,
        password: clientSecret
      },
      headers: headers
    })
    .then((response) => {
      if (response.data) {
        localStorage.setItem('orgId', orgId);
        sessionStorage.setItem('pw', body.password);
        sessionStorage.setItem('otpToken', response.data.otpToken);
      }
      return response.data;
    })
};

const getDomainList = (orgId, email) => {
  let body = {
    username: email
  }
  let organizationId = `${orgId}200601`;
  return axios
    .post(dev.domain, qs.stringify(body), {
      auth: {
        username: organizationId,
        password: `Org${orgId}@123#`
      },
      headers: headers
    })
    .then((response) => {
      if (response.data) {
        localStorage.setItem('orgId', organizationId);
        sessionStorage.setItem('email', email);
        sessionStorage.setItem('domains', JSON.stringify(response.data.userDomains));
      }
      return response.data;
    })
};

const verifyOtp = (body) => {
  let organizationId = localStorage.getItem('orgId');
  let clientSecret = `Org` + organizationId.substring(0, 6) + `@123#`;
  return axios
    .post(dev.verify, qs.stringify(body), {
      auth: {
        username: organizationId,
        password: clientSecret
      },
      headers: headers
    })
    .then((response) => {
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
       sessionStorage.clear();
      }
      return response.data;
    })
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("ordId");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getCurrentOrgId = () => {
  return localStorage.getItem("orgId");
};

const getCurrentAccessToken = () => {
  let user = JSON.parse(localStorage.getItem("user"))
  return user.access_token;
};

const getRefreshToken = () => {
  let clientId = getCurrentOrgId();
  let clientSecret = `Org${clientId.substring(0, 6)}@123#`;
  let user = JSON.parse(localStorage.getItem('user'));
  let refreshToken = user.refresh_token;
  let body = {
    refresh_token: refreshToken,
    grant_type: 'refresh_token'
  }
  return axios
    .post(dev.refreshToken, qs.stringify(body), {
      auth: {
        username: clientId,
        password: clientSecret
      },
      headers: headers
    })
    .then((response) => {
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    })
};

let modules  =  {
  getDomainList,
  verifyOtp,
  login,
  logout,
  getCurrentUser,
  getCurrentAccessToken,
  getRefreshToken,
  getCurrentOrgId
};
export default modules