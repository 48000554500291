import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';

import { useDispatch } from "react-redux";
import {verifyOtp, login} from '../../actions/auth';

import {getProfileData} from '../../actions/profile';

import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  TextField,
} from '@mui/material';

const useStyles = makeStyles({
  form:{
    width:'70%'
  }
 });
export default function Verify() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [apiError, setApiError] = useState('');
  const [isResendDisabled, setResendDisabled] = useState(true);
  const [counter, setCounter] = React.useState(60);
  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .required('OTP is required')
      .min(6, 'OTP must be at least 6 characters')
      .max(6, 'OTP must not exceed 6 characters'),
  });
  const onSubmit = (data) => {
    setApiError('');
    let body = {
      otpToken: sessionStorage.getItem('otpToken'),
      otpCode: data.otp,
    }
    dispatch(verifyOtp(body))
    .then(() => {
      dispatch(getProfileData())
      .then((data) => {
        let currentRole = data.roles[0].roleName;
        if(currentRole === 'ROLE_SYS_FACTORY_MANAGER'){
        navigate('/dashboard/devices', { replace: true });
        } else if(currentRole === 'ROLE_SYS_LOGISTIC_MANAGER') {
          navigate('/dashboard/logisticDashboard', { replace: true });
        }
        else if(currentRole === 'ROLE_SYS_ORG_ADMIN') {
          navigate('/dashboard/organization', { replace: true });
        }
        else if(currentRole === 'ROLE_SYS_B2B2C_ADMIN' || currentRole === 'ROLE_SYS_B2B_ADMIN') {
          navigate('/dashboard/enterprise', { replace: true });
        } 
        else {
        navigate('/dashboard/app', { replace: true });
       }
      })
       .catch((error) => {
       });
    })
    .catch((error) => {
      setApiError(error);
    });
  };
  const formik = useFormik({
    initialValues: {
      otp: '',
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleResend = ()=>{
    let selectedDoamin = localStorage.getItem('orgId');
    let body = {
      username: sessionStorage.getItem('email'),
      password: sessionStorage.getItem('pw'),
      grant_type: 'password'
    }
    dispatch(login(selectedDoamin, body))
    .then(() => {
      setCounter(60);
      setResendDisabled(true);
    })
    .catch((error) => {
      setApiError(error);
    });

  };
  useEffect(() => {
    if(counter === 0){
      setResendDisabled(false);
    }
   const timer =  counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
   return () => clearInterval(timer);
  }, [counter]);
  return (
    <Fragment>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit} className={classes.form}>
           <Stack spacing={3}  sx={{ my: 2 }}>
            <TextField
              fullWidth
              autoComplete="otp"
              type="text"
              label="Enter OTP"
              {...getFieldProps('otp')}
              error={Boolean(touched.otp && errors.otp)}
              helperText={touched.otp && errors.otp}
            />
           </Stack>
           <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <Button variant="text" color="error" disabled={false}> Didn't Received OTP ?</Button>
              <Button sx={{marginLeft:2}} variant="text" color="info" disabled={isResendDisabled} onClick={handleResend}>
              <Typography variant="inherit" marginRight={2} color="error">
                {counter !== 0 ? counter: ''}
              </Typography>  Resend OTP</Button>
           </Stack>
           <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} align='center'>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Verify
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} align='center'>
                <Typography variant="inherit" color="error">
                  {apiError ? apiError : ''}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </Fragment>
  );
};