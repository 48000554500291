import {
  GET_DOMAIN_SUCCESS,
  GET_DOMAIN_FAIL,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  GET_REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_EXPIRED
} from "./type";

import AuthService from '../Services/auth';

export const getDomainList = (orgId, email) => (dispatch) => {
  return AuthService.getDomainList(orgId, email).then(
    () => {
      dispatch({
        type: GET_DOMAIN_SUCCESS,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.error_description) ||
        error.response.data.message ||
        error.toString();
      dispatch({
        type: GET_DOMAIN_FAIL,
      });
      return Promise.reject(message);
    }
  );
};

export const login = (orgId, body) => (dispatch) => {
  return AuthService.login(orgId, body).then(
    (response) => {
      dispatch({
        type: SEND_OTP_SUCCESS,
      });
      return Promise.resolve(response.message);
    },
    (error) => {
      const message =
      (error.response && error.response.data && error.response.data.error_description) ||
        error.response.data.message ||
        error.toString();

      dispatch({
        type: SEND_OTP_FAIL,
      });
      return Promise.reject(message);
    }
  );
};


export const verifyOtp = (body) => (dispatch) => {
  return AuthService.verifyOtp(body).then(
    (response) => {
      let payload = {
        user: response,
        orgId: localStorage.getItem('orgId')
      }
      dispatch({
        type: LOGIN_SUCCESS,
        payload: payload
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
      (error.response && error.response.data && error.response.data.error_description) ||
        error.response.data.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      return Promise.reject(message);
    }
  );
};


export const refreshToken  = (user) => (dispatch) => {

  dispatch({
    type: GET_REFRESH_TOKEN_SUCCESS,
    payload: user,
  })
};

export const refreshTokenExpired  = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: REFRESH_TOKEN_EXPIRED,
  });
};

export const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
};