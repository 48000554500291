import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function convertToKiloMeters(meters) {
  let result = (meters / 1000).toFixed(2);
  return `${result} KM`
}

export function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return (
    seconds === 60 ?
    (minutes+1) + ":00" + " mins" :
    minutes + ":" + (seconds < 10 ? "0" : "") + seconds + " mins"
  );
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fShortStartDate(date) {
  return format(new Date(date), 'eee MMM dd yyyy');
}

export function fShortTime(date) {
  return format(new Date(date), 'p');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy p');
}

export function fDateTimeWithSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}
