import React, { useEffect, useState } from "react";
// material
import {
  Grid,
  Stack,
  Card,
  CardHeader,
  Box,
  Typography,
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  ListSubheader,
  Collapse,
  Step,
  Stepper,
  StepContent,
  StepLabel,
  StepConnector
} from '@mui/material';

import { withStyles } from '@mui/styles';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { SvgIcon } from '@mui/material';
import { ReactComponent as BatteryChargeStart } from '../../Assets/batteryChargeStart.svg';
import { ReactComponent as BatteryChargeStop } from '../../Assets/batteryChargeStop.svg';

import {fShortStartDate, fShortTime, convertToKiloMeters, millisToMinutesAndSeconds} from '../../utils/formatTime';


const BatteryOnIcon = () =>{
  return (<SvgIcon><BatteryChargeStart /></SvgIcon>)
}
const BatteryOffIcon = () =>{
  return (<SvgIcon><BatteryChargeStop /></SvgIcon>)
}

const MuiListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#EBECFO",
    },
  },
  selected: {}
})(ListItem);

const sessionStatus = {
  '10': 'In Progress',
  '20': 'In Progress',
  '21': 'Abruptly Completed',
  '41': 'Abruptly Completed',
  '30': 'Completed',
  '40': 'Completed',
};

function TripDetailsItem({ title, value, type}) {
  return (
    <>
                                  <Stack direction={'row'} sx={{my:0.2, mx:1}}display="flex" alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography
                                      sx={{ display: 'inline' }}
                                      component="span"
                                      variant="body2"
                                      color="common.black"
                                    >
                                     {title}
                                    </Typography>
                                    <Typography
                                      sx={{ display: 'inline'}}
                                      component="span"
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                     {type === 'current' ? `${value.toFixed(1)}  Amp` : ''}
                                     {type === 'battery' ? `${value.toFixed(1)}  °C` : ''}
                                     {type === 'voltage' ? `${value.toFixed(1)}  V` : ''}
                                     {type === 'driveMode' ? millisToMinutesAndSeconds(value) : ''}
                                    </Typography>
                                   </Stack>
    </>
  );
}


export default function BatteryListItem({sessionData, selectedIndex, itemIndex, changeSelection}) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };  

  return (
        <MuiListItem disableGutters  selected={selectedIndex === itemIndex} sx={{p: 1, mb:1,border: '1px solid lightgray'}} >
                <Grid container>
                      <Grid item xs={10} md={10} lg={10} display="flex" alignItems={'center'} justifyContent={'space-between'}>
                      <ListItemText
                            primary={
                              <React.Fragment>
                                <Stack direction={'row'} display="flex" alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography
                                      sx={{ display: 'inline', fontWeight: '600' }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      {fShortStartDate(sessionData.sessionStartTime)}
                                    </Typography>
                                    <Typography
                                      sx={{ display: 'inline', fontWeight: '600' }}
                                      component="span"
                                      variant="body2"
                                      color={(sessionData.sessionStatus === 21) || (sessionData.sessionStatus === 41) ? 'error.light' : 'success.light'}
                                    >
                                      {sessionStatus[sessionData.sessionStatus]}
                                    </Typography>
                                </Stack>
                              </React.Fragment>
                            }
                          />
                        </Grid>
                        <Grid item xs={2} md={2} lg={2} display="flex" alignItems={'center'} justifyContent={'space-between'}>
                          <IconButton edge="end" aria-label="delete">
                                  {open ? <ExpandLess onClick={handleClick} /> : <ExpandMore onClick={handleClick} />}
                          </IconButton>
                        </Grid>                      
                      <Grid item xs={12} md={12} lg={12} onClick={(event)=> changeSelection(itemIndex, sessionData)} sx={{cursor: 'pointer'}}>
                           <Stepper activeStep={0} orientation="vertical" className="tripCollapsedStepper" connector={
                                  <StepConnector
                                    classes={{
                                      completed: { borderColor: "red" },
                                      lineVertical: { borderColor: "red" }
                                    }}
                                  />
                                }>
                              <Step index={0}>
                                <StepLabel StepIconComponent={BatteryOnIcon}>
                                   <Typography component={'span'} sx={{mr: 1, opacity: 0.7}} variant="span" >{fShortTime(sessionData.sessionStartTime)}</Typography>
                                </StepLabel>
                                <StepContent>
                                   <Stack direction={'column'}>
                                     <Box>
                                      <Typography component={'span'} sx={{mr: 1, color: 'success.light', fontSize: '14px', fontWeight: '600'}} variant="span" >Total Duration</Typography>
                                      <Typography component={'span'} sx={{mr: 1, color: 'success.light', fontSize: '14px', fontWeight: '600'}} variant="span" > | </Typography>
                                      <Typography component={'span'} sx={{color: 'success.light', fontSize: '14px', fontWeight: '600'}} variant="span" >{millisToMinutesAndSeconds(sessionData?.sessionDuration)}</Typography>
                                    </Box>
                                    <Box>
                                      <Typography component={'span'} sx={{mr: 1, color: 'info.main', fontSize: '14px', fontWeight: '600'}} variant="span" >Charging Duration</Typography>
                                      <Typography component={'span'} sx={{mr: 1, color: 'info.main', fontSize: '14px', fontWeight: '600'}} variant="span" > | </Typography>
                                      <Typography component={'span'} sx={{color: 'info.main', fontSize: '14px', fontWeight: '600'}} variant="span" >{sessionData?.sessionRunDuration > 0 ? millisToMinutesAndSeconds(sessionData?.sessionRunDuration) : '0 mins'}</Typography>
                                    </Box>
                                    <Box>
                                      <Typography component={'span'} sx={{mr: 1, color: 'error.light', fontSize: '14px', fontWeight: '600'}} variant="span" >Halt Duration</Typography>
                                      <Typography component={'span'} sx={{mr: 1, color: 'error.light', fontSize: '14px', fontWeight: '600'}} variant="span" > | </Typography>
                                      <Typography component={'span'} sx={{color: 'error.light', fontSize: '14px', fontWeight: '600'}} variant="span" >{sessionData?.sessionHaltDuration > 0 ? millisToMinutesAndSeconds(sessionData?.sessionHaltDuration) : '0 mins'}</Typography>
                                    </Box>
                                   </Stack>
                                </StepContent>
                              </Step>
                               <Step index={0}>
                                <StepLabel StepIconComponent={BatteryOffIcon} className="MuiStepLabel-label Mui-active">
                                  <Typography component={'span'} sx={{mr: 1, opacity: 0.7}} variant="span" >{fShortTime(sessionData.sessionEndTime)}</Typography>
                                </StepLabel>
                              </Step>
                           </Stepper>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} display="flex" alignItems={'center'} justifyContent={'space-between'}>
                      <Collapse in={open} timeout="auto" unmountOnExit sx={{width: '100%'}}>
                        <Grid container>
                            <Grid item xs={12} md={12} lg={12} sx={{textAlign: 'center'}}>
                              <Typography sx={{ display: 'inline', fontWeight: '600' }}  component="span"  variant="subtitle" color="success.light">Charge Session Details</Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}  sx={{my:1}}>
                               <Card>
                                <Box sx={{p:1,bgcolor:'common.black'}}>
                                   <Typography
                                      component="span"
                                      variant="body2"
                                      color="common.white"
                                    >Charging Current</Typography>
                                </Box>
                               <TripDetailsItem type={'current'} title={'Max'} value={sessionData?.sessionSpecifics?.chargingCurrentMax}/>
                               <TripDetailsItem type={'current'} title={'Min'} value={sessionData?.sessionSpecifics?.chargingCurrentMin}/>
                               <TripDetailsItem type={'current'} title={'Average'} value={sessionData?.sessionSpecifics?.chargingCurrentAverage}/>
                               </Card>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}  sx={{my:1}}>
                               <Card>
                                <Box sx={{p:1,bgcolor:'common.black'}}>
                                   <Typography
                                      component="span"
                                      variant="body2"
                                      color="common.white"
                                    >Battery Pack Voltage</Typography>
                                </Box>
                               <TripDetailsItem type={'voltage'} title={'Max'} value={sessionData?.sessionSpecifics?.packVoltageAverage}/>
                               <TripDetailsItem type={'voltage'} title={'Min'} value={sessionData?.sessionSpecifics?.packVoltageAverage}/>
                               <TripDetailsItem type={'voltage'} title={'Average'} value={sessionData?.sessionSpecifics?.packVoltageAverage}/>
                               </Card>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}  sx={{my:1}}>
                               <Card>
                                <Box sx={{p:1,bgcolor:'common.black'}}>
                                   <Typography
                                      component="span"
                                      variant="body2"
                                      color="common.white"
                                    >Battery Pack Temp</Typography>
                                </Box>
                                <TripDetailsItem type={'battery'} title={'Max'} value={sessionData?.sessionSpecifics?.batteryPackTempMax}/>
                                <TripDetailsItem type={'battery'} title={'Min'} value={sessionData?.sessionSpecifics?.batteryPackTempMin}/>
                                <TripDetailsItem type={'battery'} title={'Average'} value={sessionData?.sessionSpecifics?.batteryPackTempAverage}/>
                               </Card>
                            </Grid>
                        </Grid>         
                      </Collapse>
                      </Grid>
                </Grid>
              
        </MuiListItem>
  );
}
