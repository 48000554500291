const ROLES_INFO = {
  ROLE_SYS_ORG_ADMIN: 'Organization Admin',
  ROLE_SYS_FACTORY_MANAGER: 'Factory Manager',
  ROLE_SYS_LOGISTIC_MANAGER: 'Logistic Manager',
  ROLE_SYS_ORG_SUPPORT: 'Organization Support',
  ROLE_SYS_B2B2C_SUPPORT: 'Support Manager',
  ROLE_SYS_B2B_SUPPORT: 'Support Manager',
  ROLE_SYS_B2B2C_USER:'User',
  ROLE_SYS_B2B_USER:'User',
  ROLE_SYS_B2B2C_ADMIN:'Admin',
  ROLE_SYS_B2B_ADMIN:'Admin'
};

export function roleDisplayName(role) {
  return ROLES_INFO[role];
}

