import React, { useEffect, useState } from "react";
// material
import {
  Grid,
  Card,
  Stack,
  Typography,
  Paper,
  Box,
  Button,
  CardContent,
  Avatar,
  CardActions,
  List,
  ListSubheader,
  Collapse,
  CircularProgress,
  TextField,
  Pagination,
  SvgIcon
} from '@mui/material';


import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// components
import { useNavigate, useParams } from 'react-router-dom';
import {fDateTimeWithSuffix } from '../../utils/formatTime';
import BatteryListItem from "../../components/common/BatteryListItem";
import BatteryLineChart from '../../components/common/BatteryLineChart';
import BatteryDoubleLineChart from "../../components/common/BatteryDoubleLineChart";

import BatteryMixLineChart from "../../components/common/BatteryMixLineChart";
import Dashboard from '../../Services/dashboard';
import { makeStyles } from '@mui/styles';
import { forEach } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "75vh"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  }
}));




function DetailsItem({ category, value, lat, long}) {
  return (
    <>
     <Paper variant="outlined" component={'div'} sx={{p: 1}}>
        <Typography variant="p" sx={{ opacity: 0.72, fontSize: '14px'}}>
            {category}
        </Typography>
        <Stack direction={'row'} spacing={1} justifyContent="space-around">
          <Typography  sx={{ fontSize: '10px', fontWeight: '600' }}>{lat}</Typography> 
          <Typography  sx={{ fontSize: '10px', fontWeight: '600' }}>{long}</Typography> 
        </Stack>
      </Paper>
    </>
  );
}

function ChargeSessions() {
  const [pageSize, setPageSize] = useState(5);
  const [displayPagination, setShowPagination] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sessionList, setSessionList] = useState([]);
  const [sessionData, setSessionData] = useState({});
  const [isSessionItemSelected, setSessionItem] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [chargingCurrentData, setChargingCurrentData] = useState([]);
  const [packVoltageData, setPackVoltageData] = useState([]);
  const [packTempData, setPackTempData] = useState([]);
  const [currentVsTempData, setCurrentVsTempData] = useState([]);

  const classes = useStyles();
  let { id } = useParams();
    async function getCurrentVsTempData(assetId, startTime, endTime){
      let params = {
        assetID: assetId,
        startTime: startTime,
        endTime: endTime,
        pageSize: 1000,
        pageNumber: 0, 
        direction: 'ASC'
      }
      return new Promise((resolve) => {
        Dashboard.getCurrentVsTempData(params).then((result) => {
          resolve(result.eventData);
          },
          () => {
            resolve([]);
          });
      });
    }
  async function makeEventDataCall(session){
    let currentVsTemp = await getCurrentVsTempData(id,session.sessionStartTime,session.sessionEndTime);
      let dataArr = [];
      let currVsTime = [];
      let packVoltage = [];
      let packTemp = [];
      currentVsTemp.chargingCurrent.forEach((v,i)=>{
           packVoltage.push([currentVsTemp.timeStamp[i], currentVsTemp.packVoltage[i]]);
           packTemp.push([currentVsTemp.timeStamp[i], currentVsTemp.batteryPackTemp[i]]);
           currVsTime.push([currentVsTemp.timeStamp[i], v]);
           dataArr.push({
            x: currentVsTemp.batteryPackTemp[i],
            y: v,
           })
      })
      setCurrentVsTempData(dataArr);
      setChargingCurrentData(currVsTime);
      setPackVoltageData(packVoltage);
      setPackTempData(packTemp);
  }
  function  getSessionData(){
    let params = {
      numberOfSessions: 10
    }
    Dashboard.getTripsData(id, params).then((result) => {
      setSessionList(result.sessions);
      if(!isSessionItemSelected){
        tripSelected(result.sessions[0].sessionSpecifics);
        makeEventDataCall(result.sessions[0]);
        setSelectedIndex(0);
      }
    },
    () => {
      setSessionList([]);
      setSessionItem(true);
    });
  }

  function  getPaginatedTripData(){
    let params = {
      startTime: new Date(startDate).getTime(),
      endTime : new Date(endDate).getTime(),
      pageSize: pageSize,
      pageNumber: page - 1
    }
    Dashboard.getPaginatedTripsData(id, params).then((result) => {
     setShowPagination(true);
     setTotalCount(Math.ceil(result.page.totalRecords / pageSize));
     setSessionList(result.Sessions);
     tripSelected(result.Sessions[0].sessionSpecifics);
     makeEventDataCall(result.Sessions[0]);
     setSelectedIndex(0);
    },
    () => {
      setSessionList([]);
      setSessionItem(true);
    });
  }
  const tripSelected = (data) =>{
   setSessionItem(false);
    let timeOut =  setTimeout(() => {
      setSessionData(data);
      setSessionItem(true);
      clearInterval(timeOut);
    },500);
  }
  
  const getNewData = (index, sessionData) => {
    setSelectedIndex(index);
    tripSelected(sessionData.sessionSpecifics);
    makeEventDataCall(sessionData);
  };

  
  const handlePaginationChange = (event, page) => {
    setPage(page);
  };

  const handleDatePicker = () =>{
    setSessionItem(false);
    setShowPagination(false);
    setPage(1);
    setTotalCount(0);
    setStartDate(null);
    setEndDate(null);
  }

  useEffect(()=>{
    if(startDate && endDate){
      getPaginatedTripData();
    } else {
      getSessionData();
    }
  },[startDate, endDate, page])

  return (
        <Grid container spacing={2}>
           <Grid item xs={8} md={8} lg={8}>
             <Grid container spacing={2}>
                 <Grid item xs={12} md={12} lg={12}>
                     <Card  variant="outlined" sx={{px: 1, py: 1, height: '40vh', width:'100%'}}>
                           <Grid container  sx={{ height: '100%', width:'100%'}}>
                                <Grid item xs={6} md={6} lg={6} sx={{borderRight: `3px solid rgb(195, 195, 201, 0.1)`}}>
                                {sessionList.length > 0 ?  <Box  variant="outlined" sx={{ height: '100%'}}>
                                      {isSessionItemSelected ?  <BatteryLineChart  data={chargingCurrentData} name={'Charging Current'} /> : <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent:"center", alignItems: 'center'}}> 
                                      <CircularProgress /></Box>}
                                  </Box> : ''}
                                </Grid>
                                <Grid item xs={6} md={6} lg={6}>
                                {sessionList.length > 0 ?  <Box  variant="outlined" sx={{ height: '100%'}}>
                                      {isSessionItemSelected ? <BatteryDoubleLineChart data={currentVsTempData} name1={'Charging Current'} name2={'Pack Temp'} /> : <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent:"center", alignItems: 'center'}}> 
                                      <CircularProgress /></Box>}
                                  </Box> : ''}
                                </Grid>
                           </Grid>
                     </Card>
                 </Grid>
                <Grid item xs={12} md={12} lg={12}>
                 {sessionList.length > 0 ? <Card  variant="outlined" sx={{px: 1, py: 1,height: '40vh', width:'100%', p:1}}>
                      {isSessionItemSelected ? <BatteryMixLineChart title={'Charging Current'} chargingCurrent={chargingCurrentData} batteryPackTempData={packTempData} batteryPackVoltage={packVoltageData} /> : <Box sx={{width: '100%', height: '40vh', display: 'flex', justifyContent:"center", alignItems: 'center'}}> 
                      <CircularProgress /></Box>}
                  </Card> : ''}
                </Grid>
                {!sessionList.length > 0 ? <Grid item xs={12} md={12} lg={12}>
                        <Box sx={{width: '100%', height: '82vh', display:"flex", justifyContent: 'center', alignItems:'center'}}>
                               <Typography  component="h2"  variant="div" color="text.primary">No Data Present</Typography>
                         </Box>
                </Grid> : '' }
             </Grid>
            
          </Grid> 
          <Grid item xs={4} md={4} lg={4}>
             <Card  variant="outlined" className={classes.root} style={{px:1, py:1, display: "flex", flexDirection: "column", height: '82vh' }}>
                <CardContent style={{ marginTop: "auto", height: '92%', overflow: 'auto', padding: 0, borderBottom: '0.5px solid lightgray'}}>
              {sessionList.length > 0 ?  <List subheader={<React.Fragment>
                                    <ListSubheader sx={{fontSize: '18px',color:'text.primary', fontWeight: '600'}}>Charge Sessions</ListSubheader>
                                    <Stack direction="row" justifyContent="space-between" spacing={2} sx={{mb:1.5}}>
                                       <LocalizationProvider dateAdapter={AdapterDateFns}>
                                          <DatePicker
                                            label="Start Date"
                                            value={startDate}
                                            onChange={(newValue) => {
                                              setStartDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField size="small" {...params} />}
                                          />
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                          <DatePicker
                                            label="End Date"
                                            value={endDate}
                                            onChange={(newValue) => {
                                              setEndDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField size="small" {...params} />}
                                          />
                                        </LocalizationProvider>
                                        <Button disabled={startDate === null || endDate === null} size="small" onClick={handleDatePicker}>Clear</Button>
                                      </Stack>
                                </React.Fragment>} sx={{p: 1}}>
                          
                           {sessionList.map((tripItem, i) => (
                                 <BatteryListItem key={`${tripItem.sessionID}`} itemIndex={i} changeSelection={getNewData} selectedIndex={selectedIndex} sessionData={tripItem} tripSelected={tripSelected}/>
                            ))}  
                    </List>:  <Box sx={{width: '100%', height: '82vh', display:"flex", justifyContent: 'center', alignItems:'center'}}>
                               <Typography  component="h2"  variant="div" color="text.primary">No Charge Sessions Present</Typography>
                            </Box> }
                   </CardContent>
                   <CardActions sx={{ height: '8%' }}>
                     {displayPagination ? <Pagination count={totalCount} color="primary" page={page} onChange={handlePaginationChange} /> : '' }
                   </CardActions>
             </Card>
          </Grid> 
      </Grid>
  );
}

export default ChargeSessions;
