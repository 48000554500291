import auth from './auth';
import axiosApiInstance from './axiosInstance';
import { dev } from '../config';

const getCommGatewayList = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/logistic/commgateway`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getLogisticSummary = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/commgateway/summary`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};


const getLogisticStats = () => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAAAURL}/enterprisestat`, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getCommGatewayDetails = (rsn) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/factory/commgateway/rsn/${rsn}`, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getAssetCategoryList = () => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/assetCategory`, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getPlanList = () => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/connection/plan`, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getFactoryStatus = (rsn) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInDataURL}/factorystatus`, {
      params:{rsn:rsn},
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};


const getDeviceSearch = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/logistic/search/commgateway`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getFactoryStats = (selectedYear, params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/commgateway/factorystats/${selectedYear}`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};


let modules  =  {
  getPlanList,
  getLogisticSummary,
  getLogisticStats,
  getCommGatewayDetails,
  getAssetCategoryList,
  getCommGatewayList,
  getFactoryStatus,
  getDeviceSearch,
  getFactoryStats
};
export default modules