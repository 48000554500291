import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Enterprise from '../../Services/enterprise';


export default function Asynchronous({handleEnterpriseChange, open, handleAutoCompleteState}) {
  // const [open, setOpen] = React.useState(true);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;


  function getData() {
    return new Promise((resolve) => {
      Enterprise.getTotalEnterpriseList().then((result) => {
        resolve(result.Enterprises);
        },
        () => {
          resolve([]);
        });
    });
  }
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

       
    (async () => {
     let list = await  getData(); // For demo purposes.
      if (active) {
        setOptions([...list]);
      }
    })();


    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="searchable-select"
      sx={{ width: 160 }}
      open={open}
      onOpen={() => {
        handleAutoCompleteState(true)
      }}
      onClose={() => {
        handleAutoCompleteState(false)
      }}
      onChange={handleEnterpriseChange}
      isOptionEqualToValue={(option, value) => (option.enterpriseName === value.enterpriseName) || (option.enterpriseID === value.enterpriseID) }
      getOptionLabel={(option) => `${option.enterpriseName} | ${option.enterpriseID}`}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder={'All'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
