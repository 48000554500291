import React, { useState, useEffect } from 'react';

// ----------------------------------------------------------------------
import ReactApexChart from 'react-apexcharts';
// material
import {Box, Typography} from '@mui/material';


export default function BatteryDoubleLineChart({data, name1, name2}) {
  let state = {
    series: [
    {
      name: `${name1} Vs ${name2}`,
      type: 'line',
      data: data
    }],
    options: {
      chart: {
        type: 'line',
        stacked: false
      },
      colors: ['rgb(0, 227, 150)'],
      noData: {
        text: 'Loading',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: 'gray',
          fontSize: '26px',
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [2, 2, 2, 2, 2],
        curve: 'smooth',
      },
      xaxis: {
        type: 'numeric',
        formatter: function(value){
          return value.toFixed(2)
        }
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
  
          },
          labels: {
          formatter: function(value){
            return value.toFixed(2)
          }
          },
          title: {
            text: name1,
            style: {
              fontFamily:'Rubik',
              fontSize: '14px',
              fontWeight: 400,
              color:'#4A484A'
            }
          },
          tooltip: {
            enabled: true
          }
        }],
      tooltip: {
      },
      legend: {
        horizontalAlign: 'center',
        itemMargin: {
          vertical: 15
      },
      }
    }
  }
  return (<Box sx={{height:'100%'}}>
          <Typography variant='h6'  sx={{mb:2,mx:3, borderBottom: `3px solid rgb(195, 195, 201, 0.1)`}}>{name1} Vs {name2}</Typography>
            <Box sx={{height:'85%'}}>
              <ReactApexChart options={state.options} series={state.series} type="line" height={'100%'}/>
            </Box>
        </Box>)
}

