import auth from './auth';
import axiosApiInstance from './axiosInstance';
import { dev } from '../config';

const getProfileData = () => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAAAURL}/users/myprofile`, {headers: headers})
    .then((response) => {
      return response.data;
    })
};

const updateProfileData = (body) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .put(`${dev.loggedInAAAURL}/users/myprofile`,body, {headers: headers})
    .then((response) => {
      if (response.data) {
        console.log('response.data',response.data);
      }
      return response.data;
    })
};

let modules  =  {
  getProfileData,
  updateProfileData
};
export default modules