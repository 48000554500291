import React, { useState, useEffect } from 'react';

// ----------------------------------------------------------------------
import ReactApexChart from 'react-apexcharts';


export default function StackedBarChart({year, seriesData}) {
  let state = {
    options: {
      chart: {
        type: 'bar',
        height: 250,
        stacked: true,
        stackType: '100%'
      },
      xaxis: {
        categories: [`Jan-${year.toString().slice(-2)}`,`Feb-${year.toString().slice(-2)}`,`Mar-${year.toString().slice(-2)}`,`Apr-${year.toString().slice(-2)}`,`May-${year.toString().slice(-2)}`,`Jun-${year.toString().slice(-2)}`,`Jul-${year.toString().slice(-2)}`,`Aug-${year.toString().slice(-2)}`,`Sep-${year.toString().slice(-2)}`,`Oct-${year.toString().slice(-2)}`,`Nov-${year.toString().slice(-2)}`,`Dec-${year.toString().slice(-2)}`],
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'right',
        offsetX: 0,
        offsetY: 0
      },
    },

  };

  return (<ReactApexChart options={state.options} series={seriesData}  type="bar" height={250} />)
}
