import React, { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';

// material-lab
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import enterprise from '../../../Services/enterprise'
import { useTheme } from '@emotion/react';
// material
import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  TextField,
  Card,
  CardHeader,
  MenuItem,
  Dialog,
  DialogContent
} from '@mui/material';

export default function CreateEnterpriseAdmin({ openAdminForm, handleAdminModal, selectedEnterprise, role, name}) {
  const theme = useTheme();
  const [isMarried, setMarried] = useState(false);
  const [dobself, setDobSelf] = useState(new Date());
  const [dobspouse, setDobSpouse] = useState('');
  const [dobAnniversary, setDobAnniversary] = useState('');
  const marriedStatusOptions = ['MARRIED', 'SINGLE'];
  const [selectedStatus, setSelectedStatus] = useState('');
  const [apiError, setApiError] = useState('');
  const [apiSuccess, setApiSuccess] = useState('');
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required').max(10, 'First Name must not exceed 10 characters').min(3, 'First Name must be atleast 3 characters').matches(/^[a-zA-Z]/, "Only alphabets are allowed"),
    lastName: Yup.string().required('Last Name is required').max(10, 'Last Name must not exceed 10 characters').min(3, 'Last Name must be atleast 3 characters').matches(/^[a-zA-Z]/, "Only alphabets are allowed"),
    emailID: Yup.string().required('Email is required').email('Email is invalid'),
    mobileNumber: Yup.string().required('Mobile No. is required').max(15, 'Mobile Number must not exceed 15 digits').min(10, 'Mobile Number must be at least 10 digits').matches(/^[0-9-+]/, "Only numeric characters are allowed"),
  });

  const handleClose = () => {
    handleAdminModal(false);
    resetForm();
    setMarried(false);
    setSelectedStatus('SINGLE');
    setDobSpouse('');
    setDobAnniversary('');
    setApiError('');
    setApiSuccess('');
  };

  const handleChange = (event) => {
    setSelectedStatus(event.target.value);
    if (event.target.value === 'MARRIED') {
      setMarried(true);
      setDobSpouse(new Date());
      setDobAnniversary(new Date());
    } else {
      setMarried(false);
      setDobSpouse('');
      setDobAnniversary('');
    }
  };

  const onSubmit = (data) => {
    setApiError('');
    setApiSuccess('');
    let userData = {
      firstName: data.firstName,
      lastName: data.lastName,
      emailID: data.emailID,
      mobileNumber: data.mobileNumber,
      maritalStatus: selectedStatus,
      dateOfBirthSelf: dobself.toISOString(),
      dateOfBirthSpouse: dobspouse ? dobspouse.toISOString(): '',
      dateAnniversary: dobAnniversary?  dobAnniversary.toISOString(): ''
    };
    let roleData = {
      username: data.emailID,
      roles:[
        {
          roleName: `ROLE_SYS_${role}_ADMIN`
        }
      ],
      is2FEnabled: true
    }
    let body = {
     ...roleData,
     userProfile: {...userData}
    }
    console.log('body',body);
    enterprise.createEnterpriseAdmin(selectedEnterprise, body).then((result) => {
      console.log('result', result);
      setApiSuccess('Enterprise Admin Created Successfully');
      handleClose();
    },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.error_description) ||
          error.response.data.message ||
          error.toString();
        console.log('resMessage', resMessage);
        setApiError(resMessage);
      });
  };
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      emailID: '',
      mobileNumber: ''
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: onSubmit
  });
  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;

 useEffect(()=>{
  setSelectedStatus('SINGLE');
  setMarried(false);
 },[])
  return (
    <Dialog open={openAdminForm} onClose={handleClose} onBackdropClick={handleClose} transitionDuration={{ enter: 500, exit: 500 }}>
      <DialogContent>
        <Card>
          <CardHeader title={`Create Admin For ${name} Enterprise`} subheader="" />
          <Box sx={{ mx: 3, my: 3 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormikProvider value={formik}>
                <Form onSubmit={handleSubmit}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        autoComplete="firstname"
                        type="text"
                        label="First Name"
                        {...getFieldProps('firstName')}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                      />
                      <TextField
                        fullWidth
                        autoComplete="lastname"
                        type="text"
                        label="Last Name"
                        {...getFieldProps('lastName')}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        label="Email"
                        {...getFieldProps('emailID')}
                        error={Boolean(touched.emailID && errors.emailID)}
                        helperText={touched.emailID && errors.emailID}
                      />
                      <TextField
                        fullWidth
                        autoComplete="mobileNumber"
                        type="text"
                        label="Mobile Number"
                        {...getFieldProps('mobileNumber')}
                        error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                        helperText={touched.mobileNumber && errors.mobileNumber}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        select
                        label="Marital status"
                        value={selectedStatus}
                        onChange={handleChange}
                        fullWidth
                      >
                        {marriedStatusOptions.map((option) => (
                          <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                      </TextField>
                      <DatePicker
                        disableFuture
                        label="Date Of Birth Self"
                        openTo="day"
                        views={['year', 'month', 'day']}
                        value={dobself}
                        onChange={(newValue) => {
                          setDobSelf(newValue);
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </Stack>
                    {isMarried ? <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <DatePicker
                        disableFuture
                        label="Date Of Birth Spouse"
                        openTo="day"
                        views={['year', 'month', 'day']}
                        value={dobspouse}
                        onChange={(newValue) => {
                          setDobSpouse(newValue);
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                      <DatePicker
                        disableFuture

                        label="Anniversary Date"
                        openTo="day"
                        views={['year', 'month', 'day']}
                        value={dobAnniversary}
                        onChange={(newValue) => {
                          setDobAnniversary(newValue);
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </Stack> : ''}
                  </Stack>
                  <Box mt={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} align='center'>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={6} sm={6} align='center'>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} align='center'>
                        <Typography variant="inherit" color="error">
                          {apiError ? apiError : ''}
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: theme.palette.success.light }}>
                          {apiSuccess ? apiSuccess : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              </FormikProvider>
            </LocalizationProvider>
          </Box>

        </Card>
      </DialogContent>
    </Dialog>
  );
}
