import {
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL
} from "../actions/type";



const initialState = { "2FAEnabled": true,
                        enterpriseID: '',
                        orgID: '',
                        roles:[
                          {roleName: ''}
                        ],
                        userProfile:{
                          dateAnniversary:'',
                          dateOfBirthSelf: '',
                          dateOfBirthSpouse: '',
                          emailID: '',
                          firstName: '',
                          lastName: '',
                          maritalStatus: '',
                          countryCode: '',
                          mobileNumber: '',
                          profilePicUrl: null,
                        },
                        sosContacts: '',
                        uniqueUserID:'',
                        username:''
                    }


  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_USER_DETAILS_SUCCESS:
        return {
          ...state,
          ...payload
        };
      case GET_USER_DETAILS_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
  }
