import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, Box, Stack, TextField, MenuItem, Grid, Typography } from '@mui/material';

import Logistic from '../../../Services/logistic';
// ----------------------------------------------------------------------



export default function LogisticStats({ assetCategoryList }) {
  const [polarChartData, setPolarChartData] = useState([0, 0, 0, 0, 0]);
  const [selectedAssetCategory, setSelectedAssetCategory] = useState('');
  const [currentAssetCategoryList, setCurrentAssetCategoryList] = useState([]);
  const [radialData, setRadialData] = useState([0, 0]);

  let state = {
    options: {
      chart: {
        type: 'polarArea',
      },
      labels: ['Factory Inventory', 'Enterprise Allocation', 'User Allocation', 'Asset Provisioned', 'Total'],
      stroke: {
        colors: ['#fff']
      },
      legend: {
        position: 'right',
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex]
        }
      },
      fill: {
        opacity: 0.8
      },
      yaxis: {
        forceNiceScale: true,
        labels: {
          formatter: function (val, index) {
            return val.toFixed(1);
          }
        }

      }
    },
  };

  let donutChartData = {
    options: {
      chart: {
        type: 'donut',
      },
      plotOptions: {
      },
      dataLabels: {
        enabled: true,
      },
      fill: {
        type: 'gradient',
      },
      legend: {
        position: 'right',
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex]
        }
      },
      labels: ['B2B Enterprises', 'B2B2C Enterprises'],
    },
  }


  function getPolarChartData() {
    let params = {};
    if(selectedAssetCategory !== 'All'){
       params.assetCategory = selectedAssetCategory.assetCategory;
       params.assetType = selectedAssetCategory.assetType
    }
    Logistic.getLogisticSummary(params).then((result) => {
      let seriesData = [];
      seriesData.push(result.orgProvisioned);
      seriesData.push(result.enterpriseProvisioned);
      seriesData.push(result.userProvisioned);
      seriesData.push(result.assetProvisioned);
      seriesData.push(result.total);
      setPolarChartData(seriesData);
    },
      () => {
        setPolarChartData([0, 0, 0, 0, 0]);
      });
  }

  function getLogisticStatData() {

    Logistic.getLogisticStats().then((result) => {
      setRadialData([result.totalB2BEnterprises, result.totalB2B2CEnterprise]);
    },
      () => {
        setRadialData([0, 0]);
      });
  }

  const handleAssetCategory = (event) => {
    setSelectedAssetCategory(event.target.value);
  };

  useEffect(() => {
    if (assetCategoryList.length > 0) {
      setCurrentAssetCategoryList(['All',...assetCategoryList])
      setSelectedAssetCategory('All');
    }
  }, [assetCategoryList])
  useEffect(() => {
    if (Object.keys(selectedAssetCategory).length > 0) {
      getPolarChartData();
      getLogisticStatData();
    }
  }, [selectedAssetCategory])

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={6} md={6} lg={6}>
          <Card sx={{px: 2, py:2}}>
            <Stack sx={{height: 40}}>
              <Stack direction="row"  justifyContent="space-between">
                <Typography variant='h6'>Device Summary</Typography>
                  <TextField
                  select
                  sx={{ width: 150, mr: 1, mt: 1 }}
                  size={'small'}
                  label="Select Asset Category"
                  variant="outlined"
                  value={selectedAssetCategory}
                  onChange={handleAssetCategory}
                >
                  {currentAssetCategoryList.map((option, index) => (
                    <MenuItem key={`${index}-`} value={option}>{option === 'All' ? 'All' :`${option.assetCategory} | ${option.assetType}`}</MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Stack>
           
            <ReactApexChart options={state.options} series={polarChartData} type="polarArea" height={250} />
          </Card>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <Card sx={{px: 2, py:2}}>
          <Stack sx={{height: 40}}>
              <Stack direction="row">
                <Typography variant='h6'>Enterprise Summary</Typography>
              </Stack>
            </Stack>
            <ReactApexChart options={donutChartData.options} series={radialData} type="donut" height={249} />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
