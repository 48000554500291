import React, { useEffect, useState } from 'react';
// material
import {
  Grid,
  Box,
  Typography,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  Paper
} from '@mui/material';



function DetailsItem({ name, value }) {
  return (
    <>
      <Paper elevation={24} sx={{width:'100%', px:2, py:2, mt:1}}>
       <Grid container> 
        <Grid item xs={6} md={6} lg={6}>
          <Typography variant="subtitle" sx={{ textTransform: 'capitalize'}}>{name}</Typography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>{value}</Typography>
        </Grid>
        </Grid>
      </Paper>
      
    </>
  );
}

export default function FactoryStatus({ openForm, handleCreateModal, details }) {
  const [formattedDetails, setFormattedDetails] = useState([]);
  const handleClose = () => {
    handleCreateModal(false);
  }
  useEffect(()=>{
    if(Object.keys(details).length > 0){
      let newArr = [];
      for (const [key, value] of Object.entries(details)) {
        if(key === 'statusDetails'){
          for (const [key1, value1] of Object.entries(value)) {
            if(value){
              newArr.push({
                name: key1,
                value: value1
              })
            }
          }
        }
      }
      setFormattedDetails(newArr);
    } else {
      setFormattedDetails([]);
    }
  },[details])
  return (
    <Dialog fullWidth maxWidth={'sm'} open={openForm} onClose={handleClose} onBackdropClick={handleClose} transitionDuration={{ enter: 500, exit: 500 }}>
       <DialogTitle>Factory Status</DialogTitle>
      <DialogContent>
         <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={12}>
           {formattedDetails.length > 0 ? <Box sx={{ px: 2, py: 2 }}>
              {/* <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>Factory Status</Typography> */}
              
                {details.imei ? <DetailsItem name={'IMEI'} value={details.imei} /> : ''}
                {details.macid ? <DetailsItem name={'MACID'} value={details.macid} /> : ''}
                {details.rsn ? <DetailsItem name={'RSN'} value={details.rsn} /> : ''}
                {details.statusTime ? <DetailsItem name={'Status Time'} value={ new Date(details.statusTime).toLocaleString() } /> : ''}
                {details.wakeupReason ? <DetailsItem name={'Wakeup Reason'} value={details.wakeupReason} /> : ''}
                       {formattedDetails.map((option) => (
                               <DetailsItem key={option.name} name={option.name} value={option.value || ''} />
                         ))}
            </Box> : <Box sx={{ px: 2, py: 2 }}>
              <Typography variant="subtitle" sx={{ mb: 2, textAlign: 'center' }}>Factory Staus Not Found</Typography>
            </Box>}
          </Grid>
        </Grid> 
      </DialogContent>
        <Box sx={{px:1, py:1 ,backgroundColor:'transparent'}}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} align='center'>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
    </Dialog>
  );
}
