/*global google*/
import ReactDOM from "react-dom";
import React, { useEffect, useState, useCallback } from 'react';
import { GoogleMap, Marker, Polyline, useGoogleMap  } from "@react-google-maps/api";

import Card from '@mui/material/Card';

let mapOptions = {
  fullscreenControl: false,
  streetViewControl: false,
  zoomControl: false,
  minZoom: 4,
  maxZoom: 14
}

const TempMap = ({latitude, longitude})=> { 
  const [myMap, setMyMap] = useState(null);
  const [tripStarted, setTripStarted] = useState(false);  
  const [pathArray, setPathArray] = useState([]);
  const [directPathArray, setDirectPathArray] = useState(null);
  let initialDate;
  let interval = null;
  const icon1 = {
      url: "https://images.vexels.com/media/users/3/154573/isolated/preview/bd08e000a449288c914d851cb9dae110-hatchback-car-top-view-silhouette-by-vexels.png",
      scaledSize: new window.google.maps.Size(40, 40),
      anchor: new window.google.maps.Point(20, 20),
      scale: 0.5,
    };

  
  useEffect(()=>{
    if(latitude && longitude){
      let pathArr = [...pathArray];
      pathArr.push({lat: latitude, lng: longitude});
      console.log('pathArr',pathArr);
      setPathArray(pathArr);
      setDirectPathArray(pathArr);
      if(pathArr.length >= 2){
       console.log('trip started');
       setTripStarted(true);
       
      const point1LatLng = new window.google.maps.LatLng(pathArr[0].lat, pathArr[0].lng);
      const point2LatLng = new window.google.maps.LatLng(pathArr[pathArr.length - 1].lng, pathArr[pathArr.length - 1].lng);

      const angle = window.google.maps.geometry.spherical.computeHeading(
          point1LatLng,
          point2LatLng
      );
      const actualAngle = parseInt(angle - 90);
        console.log('actualAngle',actualAngle);
      const marker = document.querySelector(`[src="${icon1.url}"]`);

      if (marker) {
          marker.style.transform = `rotate(${actualAngle}deg)`;
      }
      if(myMap){
        const bounds = new google.maps.LatLngBounds();
        bounds.extend({lat: latitude, lng: longitude});
        myMap.fitBounds(bounds);
        const zoom = myMap.getZoom();
        if(zoom){
          myMap.setZoom(zoom);
        }
      }
      }
    }
  },[latitude, longitude])


  const handleOnLoad = (map) => {
    setMyMap(map);
    const bounds = new google.maps.LatLngBounds();
    bounds.extend({lat: latitude, lng: longitude});
    map.fitBounds(bounds);
  };


  
  return(
    <Card variant="outlined">
      <div className='gMapCont'>
           <GoogleMap
                id="marker-example"
                onLoad={handleOnLoad}
                options={mapOptions}
                mapContainerStyle={{ height: "40vh", width: "100%" }}
               >
              {/* {tripStarted ? <Polyline
                    path={pathArray}
                    options={{
                      strokeColor: "#0088FF",
                      strokeWeight: 6,
                      strokeOpacity: 0.6,
                      defaultVisible: true,
                    }}
                />: ''} */}
                  {directPathArray ? <Polyline
                       path={directPathArray}
                       options={{
                        strokeColor: "#0088FF",
                        strokeWeight: 6,
                        strokeOpacity: 0.6,
                        defaultVisible: true,
                    }}
                    /> : '' }
                  <Marker
                    icon={icon1}
                    position={{lat: latitude, lng: longitude}}
                  />
           </GoogleMap>
      </div>
    </Card>  
  )
};

export default TempMap
