import {
  GET_BATTERY_DASHBOARD_SUCCESS,
  GET_BATTERY_DASHBOARD_FAIL,
} from "./type";

import Dashboard from '../Services/dashboard';

export const  getBatteryDashboardDetails = (rsn ,params) => (dispatch) => {
  return Dashboard.getAssetId(rsn, params).then(
    (response) => {
      console.log('response',response.CommGatewayAssetInfos[0])
      dispatch({
        type: GET_BATTERY_DASHBOARD_SUCCESS,
        payload: response.CommGatewayAssetInfos[0]
      });
      return Promise.resolve(response.CommGatewayAssetInfos[0].assetID);
    },
    (error) => {
      const message =
      (error.response && error.response.data && error.response.data.error_description) ||
        error.response.data.message ||
        error.toString();

      dispatch({
        type: GET_BATTERY_DASHBOARD_FAIL,
      });

      return Promise.reject();
    }
  );
};