import React from "react";
// material
import { Grid, Container } from '@mui/material';
// components
import Page from '../../components/Page';
import {
  AppNewUsers,
  AppWeeklySales,
} from '../../components/_dashboard/app';

function Dashboard() {
  return (
    <Page title="Dashboard">
    <Container maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <AppWeeklySales />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppNewUsers />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppWeeklySales />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppNewUsers />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppWeeklySales />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppNewUsers />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppWeeklySales />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppNewUsers />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppWeeklySales />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppNewUsers />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppWeeklySales />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppNewUsers />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppWeeklySales />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppNewUsers />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppWeeklySales />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppNewUsers />
        </Grid>
      </Grid>
    </Container>
  </Page>
  );
}

export default Dashboard;
