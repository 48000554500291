import React, { useEffect, useState } from "react";

// material
import {
  Grid,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  MenuItem,
  InputBase,
  Paper
} from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import FactoryIcon from '@mui/icons-material/Factory';
import AddCardIcon from '@mui/icons-material/AddCard';

//Services
import LogisticGateway from '../../Services/logistic';
import Enterprise from '../../Services/enterprise';


// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { EnterpriseListHead } from '../../components/_dashboard/enterprise';
import DeviceDetails from '../../components/_dashboard/devices/DeviceDetails';
import AllocateDevice from "../../components/_dashboard/devices/AllocateDevice";
import FactoryStatus from '../../components/_dashboard/devices/FactoryStatus';
import LogisticStats from '../../components/_dashboard/logistic/LogisticStats';
import AutoFetchList from '../../components/common/AutoFetchList';

import { useDispatch } from "react-redux";
import { getTitle } from '../../actions/dashboardTitle';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'commGatewayBID', label: 'Comm Gateway BID', alignRight: false },
  { id: 'rsn', label: 'RSN', alignRight: false },
  { id: 'enterpriseID', label: 'Enterprise ID', alignRight: false },
  { id: 'assetCategory', label: 'Asset Category', alignRight: false },
  { id: 'assetType', label: 'Asset Type', alignRight: false },
  { id: 'service status', label: 'Service Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------



function LogisticsDashboard() {
  const dispatch = useDispatch();
  const [autoFetchState, setAutoFetchState] = useState(false);
  const [totalEnterprises, setTotalEnterprises] = useState([]);
  const [filterType, setFilterType] = useState('assetCategory');
  const [selectedEnterpriseId, setSelectedEnterpriseID] = useState('All');
  const [searchText, setSearchText] = useState('');
  const statusList = ['ACTIVE', 'SUSPEND', 'RESUME', 'DEACTIVATE'];
  const [assetCategoryList, setAssetCategoryList] = useState([]);
  const [filterCategoryList, setFilterCategoryList] = useState([]);
  const [selectedAssetCategory, setSelectedAssetCategory] = useState({});
  const [selectedStatus, setSelectedStatus] = useState('');
  const filterTypeOptions = [{ name: 'Asset Category', value: 'assetCategory' }, { name: 'RSN', value: 'rsn' }, { name: 'IMEI', value: 'imei' }, { name: 'MACID', value: 'macid' }, { name: 'Service Status', value: 'serviceStatus' }];
  const [details, setDetails] = useState({});
  const [factoryDetails, setFactoryDetails] = useState({});
  const [openDetailsModal, setDetailsModal] = useState(false);
  const [openAllocateModal, setAllocateModal] = useState(false);
  const [openStatusModal, setStatusModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deviceList, setDeviceList] = useState([]);


  const handleFilterType = (event) => {
    setSearchText('');
    setFilterType(event.target.value);
    setSelectedStatus('');
    setSelectedAssetCategory(filterCategoryList[0]);
  };

  const handleServiceStatus = (event) => {
    setSelectedStatus(event.target.value);
    setSearchText(event.target.value);
  };

  const handleAssetCategory = (event) => {
    setSelectedAssetCategory(event.target.value);
    if (event.target.value.assetCategory === 'All') {
      setSearchText('');
    } else {
      setSearchText(event.target.value.assetCategory);
    }
  };

  const handleDetailsModal = (state) => {
    setDetailsModal(state);
  }
  const handleAllocateModal = (state) => {
    setTotalEnterprises([]);
    setDetails({});
    setAllocateModal(state);
  }
  const handleStatusModal = (state) => {
    setStatusModal(state);
  }
  const handleSearchChange = (event) => {
    setSearchText(event.target.value)
  }
  const goToDetails = (details) => {
    setDetails(details);
    setDetailsModal(true);
  }

  const handleEnterpriseChange = (event, newValue) => {
    if (newValue) {
      setSelectedEnterpriseID(newValue.enterpriseID)
    } else {
      setSelectedEnterpriseID('All');
    }
  }

  const handleAutoCompleteState = (state) =>{
    setAutoFetchState(state);
  }

  const openFactoryStatus = (rsn) => {
    LogisticGateway.getFactoryStatus(rsn).then((result) => {
      setFactoryDetails(result);
      setStatusModal(true);
    },
      () => {
        setFactoryDetails({});
        setStatusModal(true);
      });
  }
  const openAllocateDevice = (details) => {
    getTotalEnterprise(details);
  }

  function getAssetCategoryList() {
    LogisticGateway.getAssetCategoryList().then((result) => {
      setAssetCategoryList(result.AssetCategories);
      let filterCategoryList = [{ assetCategory: 'All', assetType: 'All' }, ...result.AssetCategories];
      setFilterCategoryList(filterCategoryList);
      setSelectedAssetCategory(filterCategoryList[0]);
    },
      () => {
        setAssetCategoryList([]);
        setFilterCategoryList([]);
        setSelectedAssetCategory({});
      });
  }
  function getTotalEnterprise(details) {
   Enterprise.getTotalEnterpriseList().then((result) => {
        setTotalEnterprises(result.Enterprises);
        setDetails(details);
        setAllocateModal(true);
    });
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  function getSearchResults() {
    let params = {};
    if (searchText !== '') {
      params[filterType] = searchText;
    }
    if (selectedEnterpriseId !== 'All') {
      params['enterpriseID'] = selectedEnterpriseId;
    }
    params.pageSize = rowsPerPage;
    params.pageNumber = 0;

    LogisticGateway.getDeviceSearch(params).then((result) => {
      setDeviceList(result.CommGateways);
      setTotalCount(result.page.totalRecords);
    },
      () => {
        console.log('error');
        setDeviceList([]);
      });
  }
  function updateDeviceManagement() {
    getSearchResults()
  }
  useEffect(() => {
    setFilterType(filterTypeOptions[0].value);
    getAssetCategoryList();
  }, [])
  useEffect(() => {
    getSearchResults();
  }, [page, searchText, selectedEnterpriseId, rowsPerPage])
  useEffect(()=>{
    dispatch(getTitle('Dashboard'))
  },[])
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Dashboard
          </Typography>
        </Stack> */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <LogisticStats assetCategoryList={assetCategoryList} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography sx={{fontSize:'15px', fontWeight:'400' }} color={'subtitle1.main'} variant="subtitle3" gutterBottom>
              Select Enterprise
            </Typography>
            <Stack direction="row" alignItems="center">
              <Paper
                component="form"
                sx={{ display: 'flex', alignItems: 'center', mr: 10 }}
              >
                <AutoFetchList open={autoFetchState} handleAutoCompleteState={handleAutoCompleteState} handleEnterpriseChange={handleEnterpriseChange} />
              </Paper>
              <Paper
                component="form"
                sx={{ display: 'flex', alignItems: 'center', width: 320}}
              >
                <TextField
                  sx={{ flex: 1 }}
                  select
                  value={filterType}
                  onChange={handleFilterType}
                  size="small"
                >
                  {filterTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                  ))}
                </TextField>
                {filterType === 'serviceStatus' ?
                  <TextField
                    sx={{ flex: 1 }}
                    select
                    value={selectedStatus}
                    onChange={handleServiceStatus}
                    size="small"
                  >
                    {statusList.map((option, index) => (
                      <MenuItem key={index} value={option}>{option}</MenuItem>
                    ))}
                  </TextField> : ''}
                {filterType === 'assetCategory' ?
                  <TextField
                    sx={{ flex: 1 }}
                    select
                    value={selectedAssetCategory}
                    onChange={handleAssetCategory}
                    size="small"
                  >
                    {filterCategoryList.map((option, index) => (
                      <MenuItem key={index} value={option}>{option.assetCategory === 'All' ? `All` : `${option.assetCategory} | ${option.assetType}`}</MenuItem>
                    ))}
                  </TextField> : ''}
                {filterType !== 'serviceStatus' && filterType !== 'assetCategory' ?
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search"
                    value={searchText}
                    inputProps={{ 'aria-label': 'Search' }}
                    onChange={handleSearchChange}
                  /> : ''}
              </Paper>
            </Stack>
          </Grid>
        </Grid>
        <Card sx={{ my: 3 }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <EnterpriseListHead
                  headLabel={TABLE_HEAD}
                />
                <TableBody>
                  {deviceList.map((row) => {
                    const { commGatewayID, commGatewayBID, assetInfo, connectionDetail } = row;

                    return (
                      <TableRow
                        key={commGatewayID.rsn}
                      >
                        <TableCell align="center">{`${commGatewayBID}`}</TableCell>
                        <TableCell align="center">{`${commGatewayID.rsn}`}</TableCell>
                        <TableCell align="center">{`${row.enterpriseID}`}</TableCell>
                        <TableCell align="center">{`${assetInfo.assetCategory}`}</TableCell>
                        <TableCell align="center">{assetInfo.assetType}</TableCell>
                        <TableCell align="center">{connectionDetail.serviceStatus}</TableCell>
                        <TableCell align="center" style={{ width: 50 }}>
                          <Stack direction="row" spacing={1}>
                            <Button size="small" onClick={() => goToDetails(row)} variant="outlined" color="success" endIcon={<InfoIcon />}>
                              Details
                            </Button>
                            <Button size="small" onClick={() => openFactoryStatus(commGatewayID.rsn)} variant="outlined" color="primary" endIcon={<FactoryIcon />}>
                              Status
                            </Button>
                            <Button size="small" onClick={() => openAllocateDevice(row)} variant="outlined" color="secondary" endIcon={<AddCardIcon />}>
                              Reallocate
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {deviceList.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={12}>No Device Present</TableCell>
                    </TableRow>
                  ) : ''}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <FactoryStatus openForm={openStatusModal} handleCreateModal={handleStatusModal} details={factoryDetails} />
        <DeviceDetails openForm={openDetailsModal} handleCreateModal={handleDetailsModal} details={details} />
        <AllocateDevice totalEnterprises={totalEnterprises} title="Reallocate" openForm={openAllocateModal} handleCreateModal={handleAllocateModal} details={details} updateData={() => updateDeviceManagement()} />
      </Container>
    </Page>
  );
}

export default LogisticsDashboard;
