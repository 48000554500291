import React, { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';


// material
import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  TextField,
  Card,
  CardHeader,
  MenuItem,
  InputAdornment
} from '@mui/material';

// material-lab
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import MuiPhoneNumber from 'material-ui-phone-number';


import { useTheme } from '@emotion/react';

import { useDispatch } from "react-redux";
import { updateProfileData } from '../../../actions/profile';

export default function ProfileUserForm({ formData }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [countryCode, setCountryCode] = useState('+91');
  const [formDisabled, setFormDisabled] = useState(true);
  const [isMarried, setMarried] = useState(false);
  const [dobself, setDobSelf] = useState(new Date());
  const [dobspouse, setDobSpouse] = useState('');
  const [dobAnniversary, setDobAnniversary] = useState('');
  const marriedStatusOptions = ['MARRIED', 'SINGLE'];
  const [selectedStatus, setSelectedStatus] = useState('');
  const [apiError, setApiError] = useState('');
  const [apiSuccess, setApiSuccess] = useState('');
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required').max(10, 'First Name must not exceed 10 characters'),
    lastName: Yup.string().required('Last Name is required').max(10, 'Last Name must not exceed 10 characters'),
    emailID: Yup.string().required('Email is required').email('Email is invalid'),
    mobileNumber: Yup.string().required('Mobile No. is required').max(10, 'Mobile Number must not exceed 10 digits').min(10, 'Mobile Number must be at least 10 digits')
  });
  const handleChange = (event) => {
    setSelectedStatus(event.target.value);
    if (event.target.value === 'MARRIED') {
      setMarried(true);
      setDobSpouse(new Date());
      setDobAnniversary(new Date());
    } else {
      setMarried(false);
      setDobSpouse('');
      setDobAnniversary('');
    }
  };
  const handleEdit = () => {
    setFormDisabled(!formDisabled);
    setApiError('');
    setApiSuccess('');
  };

  const handleCountryCodeChange = (countryCode) => {
    setCountryCode(countryCode);
  }

  const onSubmit = (data) => {
    setApiError('');
    setApiSuccess('');
    let body = {
      firstName: data.firstName,
      lastName: data.lastName,
      emailID: data.emailID,
      countryCode: countryCode,
      mobileNumber: data.mobileNumber,
      maritalStatus: selectedStatus,
      dateOfBirthSelf: dobself.toISOString(),
      dateOfBirthSpouse: dobspouse ? dobspouse.toISOString(): '',
      dateAnniversary: dobAnniversary?  dobAnniversary.toISOString(): ''
    }
    dispatch(updateProfileData(body))
      .then((data) => {
        setApiSuccess('Profile Updated Successfully');
        setFormDisabled(true);
      })
      .catch((error) => {
        setApiError(error);
      });
  };
  const formik = useFormik({
    initialValues: {
      firstName: formData.userProfile.firstName,
      lastName: formData.userProfile.lastName,
      emailID: formData.userProfile.emailID,
      mobileNumber: formData.userProfile.mobileNumber
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: onSubmit
  });
  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;
  useEffect(() => {
    if (formData) {
      let countryCode = formData.userProfile.countryCode ? formData.userProfile.countryCode : '+91';
      setCountryCode(countryCode);
      setSelectedStatus(formData.userProfile.maritalStatus);
      if (formData.userProfile.maritalStatus === 'MARRIED') {
        setMarried(true);
        setDobSpouse(new Date(formData.userProfile.dateOfBirthSpouse));
        setDobAnniversary(new Date(formData.userProfile.dateAnniversary));
      } else {
        setMarried(false);
      }
      setDobSelf(new Date(formData.userProfile.dateOfBirthSelf));
    }
  }, [formData])
  return (
    <Card>
      <CardHeader title="Profile"
        subheader=""
        action={formDisabled ? <Button
          variant="outlined"
          color="primary"
          onClick={handleEdit}>
          Edit
        </Button> : '' }/>
      <Box sx={{ mx: 3, my: 3 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    disabled={formDisabled}
                    autoComplete="firstname"
                    autoFocus={true}
                    type="text"
                    label="First Name"
                    {...getFieldProps('firstName')}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                  <TextField
                    fullWidth
                    disabled={formDisabled}
                    autoFocus={true}
                    autoComplete="lastname"
                    type="text"
                    label="Last Name"
                    {...getFieldProps('lastName')}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    disabled={true}
                    autoFocus={true}
                    autoComplete="username"
                    type="email"
                    label="Email"
                    {...getFieldProps('emailID')}
                    error={Boolean(touched.emailID && errors.emailID)}
                    helperText={touched.emailID && errors.emailID}
                  />
                  <TextField
                    fullWidth
                    disabled={true}
                    autoFocus={true}
                    autoComplete="mobileNumber"
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                         <MuiPhoneNumber sx={{width: 40}} disableDropdown={true}  disabled={true} value={countryCode} countryCodeEditable={false} inputClass="mobile__country--code" defaultCountry={'in'} onClick={handleCountryCodeChange}/>
                        </InputAdornment>
                      ),
                    }}
                    label="Mobile Number"
                    {...getFieldProps('mobileNumber')}
                    error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                    helperText={touched.mobileNumber && errors.mobileNumber}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    select
                    disabled={formDisabled}
                    label="Marital status"
                    value={selectedStatus}
                    onChange={handleChange}
                    fullWidth
                  >
                    {marriedStatusOptions.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </TextField>
                  <DatePicker
                    disableFuture
                    disabled={formDisabled}
                    label="Date Of Birth Self"
                    openTo="day"
                    views={['year', 'month', 'day']}
                    value={dobself}
                    onChange={(newValue) => {
                      setDobSelf(newValue);
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </Stack>
                {isMarried ? <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <DatePicker
                    disableFuture
                    disabled={formDisabled}
                    label="Date Of Birth Spouse"
                    openTo="day"
                    views={['year', 'month', 'day']}
                    value={dobspouse}
                    onChange={(newValue) => {
                      setDobSpouse(newValue);
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                  <DatePicker
                    disableFuture
                    disabled={formDisabled}
                    label="Anniversary Date"
                    openTo="day"
                    views={['year', 'month', 'day']}
                    value={dobAnniversary}
                    onChange={(newValue) => {
                      setDobAnniversary(newValue);
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </Stack> : ''}
              </Stack>
              <Box mt={3}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} align='center'>
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={formDisabled}
                        onClick={() => { resetForm(); setFormDisabled(true);}}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  <Grid item xs={6} sm={6} align='center'>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={formDisabled}
                    >
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} align='center'>
                    <Typography variant="inherit" color="error">
                      {apiError ? apiError : ''}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: theme.palette.success.light }}>
                      {apiSuccess ? apiSuccess : ''}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </FormikProvider>
        </LocalizationProvider>
      </Box>
    </Card>
  );
}
