import React from 'react';
// material
import {
  Grid,
  Box,
  Typography,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Paper
} from '@mui/material';



function DetailsItem({ name, value }) {
  return (
    <>
     <Paper elevation={24} sx={{width:'100%', px:2, py:2, mt:1}}>
      <Grid container> 
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="subtitle">{name}</Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>{value}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default function DeviceDetails({ openForm, handleCreateModal, details }) {
  const handleClose = () => {
    handleCreateModal(false);
  }
  return (
    <Dialog fullWidth maxWidth={'sm'} open={openForm} onClose={handleClose} onBackdropClick={handleClose} transitionDuration={{ enter: 500, exit: 500 }}>
       <DialogTitle>CommGateway Details</DialogTitle>
      <DialogContent>
         <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={12}>
           {details.assetInfo ? <Card sx={{ px: 2, py: 2 }}>
              <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>Asset Information</Typography>
              {/* <Grid container sx={{ textAlign: 'center', border: 1 }}> */}
                 <DetailsItem name={'Asset Category'} value={details.assetInfo.assetCategory || ''} />
                 <DetailsItem name={'Asset Type'} value={details.assetInfo.assetType || ''} />
              {/* </Grid> */}
            </Card> : ''}
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
          {details.commGatewayID ? <Card sx={{ px: 2, py: 2 }}>
              <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>Comm Gateway</Typography>
              {/* <Grid container sx={{ textAlign: 'center', border: 1 }}> */}
                 <DetailsItem name={'Comm Gateway BID'} value={details.commGatewayBID || ''} />
                 { details.commGatewayID.imei ? <DetailsItem name={'IMEI'} value={details.commGatewayID.imei || ''} /> : ''}
                 { details.commGatewayID.macid ? <DetailsItem name={'MACID'} value={details.commGatewayID.macid || ''} /> : ''}
                 <DetailsItem name={'RSN'} value={details.commGatewayID.rsn || ''} />
              {/* </Grid> */}
            </Card> : ''}
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
           {details.connectionDetail ? <Card sx={{ px: 2, py: 2 }}>
              <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>Connection Details</Typography>
              {/* <Grid container sx={{ textAlign: 'center', border: 1 }}> */}
                 <DetailsItem name={'IMSI'} value={details.connectionDetail.imsi || ''} />
                 <DetailsItem name={'MSISDN'} value={details.connectionDetail.msisdn || ''} />
                 <DetailsItem name={'Plan Name'} value={details.connectionDetail.planName || ''} />
                 <DetailsItem name={'Plan Code'} value={details.connectionDetail.planCode || ''} />
                 <DetailsItem name={'Plan Description'} value={details.connectionDetail.planDescription || ''} />
                 <DetailsItem name={'Plan Start Date'} value={new Date(parseInt(details.connectionDetail.planStartDate)).toLocaleDateString() || ''} />
                 <DetailsItem name={'Plan End Date'} value={ new Date(parseInt(details.connectionDetail.planEndDate)).toLocaleDateString() || ''} />
                 <DetailsItem name={'Service Status'} value={details.connectionDetail.serviceStatus || ''} />
              {/* </Grid> */}
            </Card> : ''}
          </Grid>
        </Grid> 
      </DialogContent>
        <Box sx={{px:1, py:1 ,backgroundColor:'transparent'}}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} align='center'>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
    </Dialog>
  );
}
