// material
import {Box, Grid, Card, Paper, Typography, CardContent } from '@mui/material';
//icons
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import {map} from '../../../config';

function SiteItem(props) {
    return (
        <Grid item xs={props.size}>
        <Typography variant="subtitle">{props.name}</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {props.value}
          </Typography>
       </Grid>
    );
  }


export default function ProfileUserDetails({formData}) {
  const openMap = ()=>{
    let lat = formData.organization.latitude;
    let long = formData.organization.longitude;
    if(formData.enterprise){
      lat = formData.enterprise.latitude;
      long = formData.enterprise.longitude
    }
    let url = `${map.commonMapUrl}${lat},${long}`;
    window.open(url, '_blank');
  }
  console.log('formData.organization',formData.organization);
    return (
      <Card>
        {/* <CardHeader title="Organization Details" /> */}
        <CardContent>
        <Paper variant="outlined" sx={{ py: 1, textAlign: 'center' }}>
          <Typography variant="h6" sx={{ mb: 2,ml:2.5, textAlign: 'center' }}>Organization Details</Typography>
          <Grid container spacing={2}>                  
             <SiteItem size={6} key={'Org ID'} name={'Organization ID'} value={formData.organization.orgID} />
             <SiteItem size={6} key={'Org Name'} name={'Organization Name'} value={formData.organization.orgName} />
            <SiteItem size={12} key={'Address'} name={'Address'} 
              value={`${formData.organization.address}, ${formData.organization.city},
              ${formData.organization.country}, ${formData.organization.pinCode}`}
              />
          </Grid>
          </Paper>
        {formData.enterprise ? <Paper variant="outlined" sx={{ my:2, py: 1, textAlign: 'center' }}>
            <Typography variant="h6" sx={{ mb: 2,ml:2.5, textAlign: 'center' }}>Enterprise Details</Typography>
            <Grid container spacing={2}>                  
              <SiteItem size={6} key={'enterprise ID'} name={'Enterprise ID'} value={formData.enterprise.enterpriseID} />
              <SiteItem size={6} key={'enterprise Name'} name={'Enterprise Name'} value={formData.enterprise.enterpriseName} />
              <SiteItem size={12} key={'Address'} name={'Address'} 
                value={`${formData.enterprise.address}, ${formData.enterprise.city},
                ${formData.enterprise.country}, ${formData.enterprise.pinCode}`}
                />
            </Grid>
          </Paper> : ''}
        {formData.enterprise ? <Paper variant="outlined" sx={{ my:2, py: 2.5, textAlign: 'center' }}>
             <Grid container spacing={2}>
               <Grid item xs={12}>
                 <Box sx={{ mb: 0.5 }}>
                  <Typography variant="h6"> <PhoneIcon sx={{mx:1,verticalAlign:'middle',fontSize: '32px'}} />Phone Number</Typography>
                 </Box>
                 <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {formData.enterprise.phoneNumber}
                </Typography>
               </Grid>
             </Grid>
          </Paper> : ''}
          <Paper variant="outlined" sx={{ my:2, py: 2.5, textAlign: 'center' }}>
             <Grid container spacing={2} onClick={openMap}>
               <Grid item xs={12}>
                 <Box sx={{ mb: 0.5 }}>
                  <LocationOnIcon  style={{fontSize: '32px'}}/>
                 </Box>
                 <Typography variant="h6">Location</Typography>
               </Grid>
             </Grid>
          </Paper>
        </CardContent>
      </Card>
    );
  }