import {
  GET_ENTERPRISE_DETAILS_SUCCESS,
  GET_ENTERPRISE_DETAILS_FAIL
} from "../actions/type";



const initialState = { adminDetails: [],
                       enterpriseDetails: {
                        address: '',
                        city: '',
                        country: '',
                        enterpriseID: '',
                        enterpriseName: '',
                        enterpriseType: '',
                        isEnterpriseDeleted: false,
                        latitude: '',
                        longitude: '',
                        orgID: '',
                        phoneNumber: '',
                        pinCode: '',
                       }
  
                    }


  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_ENTERPRISE_DETAILS_SUCCESS:
        return {
          ...state,
          ...payload
        };
      case GET_ENTERPRISE_DETAILS_FAIL:
        return {
          ...state,
        };
      default:
        return state;
    }
  }
