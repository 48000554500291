import React, {useState, useEffect} from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';

import Devices from '../../../Services/devices';
import { useTheme } from '@emotion/react';
// material
import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  TextField,
  Card,
  CardHeader,
  MenuItem,
  Dialog,
  DialogContent,
} from '@mui/material';


export default function CreateDevice({openForm, handleCreateModal, updateData, assetCategoryList}) {
  const theme = useTheme();
  const [apiError, setApiError] = useState('');
  const [apiSuccess, setApiSuccess] = useState('');
  const statusList = ['ACTIVE','SUSPEND','RESUME','DEACTIVATE'];
  const [selectedStatus, setSelectedStatus] = useState('ACTIVE');
  const [selectedAssetCategory, setSelectedAssetCategory] = useState('');
  const [planList, setPlanList] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const validationSchema = Yup.object().shape({
    msisdn:  Yup.string().max(14, 'MSISDN must not exceed 14 characters').min(10, 'MSISDN must be atleast 10 characters').matches(/^[0-9]/, "Only numeric characters are allowed"),
    imsi:  Yup.string().max(20, 'IMSI must not exceed 20 characters').min(10, 'IMSI must be atleast 10 characters').matches(/^[0-9]/, "Only numeric characters are allowed"),
    macid:  Yup.string().max(50, 'MACID must not exceed 50 characters').min(8, 'MACID must be atleast 8 characters').matches(/^[a-zA-Z0-9\s]/, "Only alphanumeric characters are allowed"),
    imei:  Yup.string().max(16, 'IMEI must not exceed 16 characters').min(15, 'IMEI must be atleast 15 characters'),
    rsn:  Yup.string().required('RSN is required').max(50, 'RSN must not exceed 50 characters').min(8, 'RSN must be atleast 8 characters').matches(/^[a-zA-Z0-9\s]/, "Only alphanumeric characters are allowed"),
  });

  const handleClose = () => {
    handleCreateModal(false);
    resetForm();
    setApiError('');
    setApiSuccess('');
  };

  const handleAssetCategory = (event) => {
    setSelectedAssetCategory(event.target.value);
  };

  const handleServiceStatus = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handlePlanList = (event) => {
    setSelectedPlan(event.target.value);
  };
  
  function getPlanList(){
    Devices.getPlanList().then((result) => {
      setPlanList(result.connectionPlans);
      setSelectedPlan(result.connectionPlans[0]);
    },
    () => {
      setPlanList([]);
      setSelectedPlan('');
    });
  }

  const onSubmit = (data) => {
    setApiError('');
    setApiSuccess('');
    let body = {
      commGatewayBID:`BID${data.rsn}`,
      commGatewayID: {
         imei: data.imei || null ,
         rsn: data.rsn,
         macid: data.macid || null
      },
      connectionDetail: {
        imsi: data.imsi || null,
        msisdn: data.msisdn || null,
        planCode: selectedPlan.planCode,
        // planName: selectedPlan.planName,
        // planDescription: selectedPlan.planDescription,
        // planStartDate: selectedPlan.planStartDate,
        // planEndDate: selectedPlan.planEndDate,
        serviceStatus: selectedStatus
      },
      assetInfoModel: {
         category: selectedAssetCategory.assetCategory ,
         type: selectedAssetCategory.assetType
      }
    }

    Devices.createDevice(body).then((result) => {
      setApiSuccess('Device Created Successfully');
      updateData();
      handleClose();
    },
      (error) => {
        const resMessage =
        (error.response && error.response.data && error.response.data.error_description) ||
         error.response.data.message ||
          error.toString();
        setApiError(resMessage);
      });
  };
  const formik = useFormik({
    initialValues:{
      msisdn: '',
      imsi:'',
      macid:'',
      imei: '',
      rsn: '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: onSubmit
  });
  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(()=>{
    getPlanList();
  },[]);

  useEffect(()=>{
    if(assetCategoryList.length > 0){
      setSelectedAssetCategory(assetCategoryList[0]);
    }
  },[assetCategoryList])
  return (
      <Dialog open={openForm} onClose={handleClose} onBackdropClick={handleClose}  transitionDuration={{ enter: 500, exit: 500 }}>
        <DialogContent>
          <Card>
          <CardHeader title="Create Device" subheader=""/>
            <Box sx={{ mx: 3, my: 3 }}>
              <FormikProvider value={formik}>
                 <Form onSubmit={handleSubmit}>
                     <Stack spacing={3}>
                          <Stack  direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <TextField
                                select
                                label="Asset Category"
                                value={selectedAssetCategory}
                                onChange={handleAssetCategory}
                                fullWidth
                              >
                                {assetCategoryList.map((option, index) => (
                                  <MenuItem key={index} value={option}>{`${option.assetCategory} | ${option.assetType}`}</MenuItem>
                                ))}
                              </TextField>
                              <TextField
                                select
                                label="Service Status"
                                value={selectedStatus}
                                onChange={handleServiceStatus}
                                fullWidth
                              >
                                {statusList.map((option, index) => (
                                  <MenuItem key={index} value={option}>{option}</MenuItem>
                                ))}
                              </TextField>
                           </Stack>
                        <Stack  direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                  <TextField
                                    fullWidth
                                    autoComplete="rsn"
                                    type="text"
                                    label="RSN"
                                    {...getFieldProps('rsn')}
                                    error={Boolean(touched.rsn && errors.rsn)}
                                    helperText={touched.rsn && errors.rsn}
                                  />
                                   <TextField
                                    fullWidth
                                    autoComplete="imei"
                                    type="text"
                                    label="IMEI"
                                    {...getFieldProps('imei')}
                                    error={Boolean(touched.imei && errors.imei)}
                                    helperText={touched.imei && errors.imei}
                                  />
                        </Stack>
                        <Stack  direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                  <TextField
                                    fullWidth
                                    autoComplete="macid"
                                    type="text"
                                    label="MACID"
                                    {...getFieldProps('macid')}
                                    error={Boolean(touched.macid && errors.macid)}
                                    helperText={touched.macid && errors.macid}
                                  />
                                   <TextField
                                    fullWidth
                                    autoComplete="imsi"
                                    type="text"
                                    label="IMSI"
                                    {...getFieldProps('imsi')}
                                    error={Boolean(touched.imsi && errors.imsi)}
                                    helperText={touched.imsi && errors.imsi}
                                  />
                        </Stack>
                        <Stack  direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                   <TextField
                                    fullWidth
                                    autoComplete="msisdn"
                                    type="text"
                                    label="MSISDN"
                                    {...getFieldProps('msisdn')}
                                    error={Boolean(touched.msisdn && errors.msisdn)}
                                    helperText={touched.msisdn && errors.msisdn}
                                  />
                                  <TextField
                                    select
                                    label="Plan"
                                    value={selectedPlan}
                                    onChange={handlePlanList}
                                    fullWidth
                                  >
                                    {planList.map((option, index) => (
                                      <MenuItem key={index} value={option}>{`${option.planCode} | ${option.planName}`}</MenuItem>
                                    ))}
                                  </TextField>
                        </Stack>
                       
                     </Stack>
                     <Box mt={3}>
                      <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} align='center'>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        <Grid item xs={6} sm={6} align='center'>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} align='center'>
                          <Typography variant="inherit" color="error">
                            {apiError ? apiError : ''}
                          </Typography>
                          <Typography variant="subtitle2" sx={{ color: theme.palette.success.light }}>
                            {apiSuccess ? apiSuccess : ''}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                 </Form>
              </FormikProvider>
            </Box>
          </Card>
        </DialogContent>
      </Dialog>
  );
}
