import { combineReducers } from "redux";
import auth from "./auth";
import loading from "./loading";
import profile from "./profile";
import enterprise from "./enterprise";
import user from "./user";
import device from "./device";
import assetDashboard from "./assetDashboard";
import batteryDashboard from "./batteryDashboard";
import dashboardTitle from "./dashboardTitle";


export default combineReducers({
  auth,
  profile,
  enterprise,
  loading,
  user,
  device,
  assetDashboard,
  dashboardTitle,
  batteryDashboard
});
