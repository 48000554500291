import React from 'react';
// material
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
} from '@mui/material';

import Close from '@mui/icons-material/Close';



export default function ConformationPopUp({ open, id, handleConformClose, handleDelete, titleText }) {
  const handleClose = () => {
    handleConformClose(false);
  };
  const deleteEnterprise = ()=>{
    handleDelete(id)
  }
  return (
    <Dialog open={open} maxWidth="sm" fullWidth  onClose={handleClose} onBackdropClick={handleClose}>
    <DialogTitle sx={{textTransform:'capitalize'}}>Delete {titleText}</DialogTitle>
    <Box position="absolute" top={0} right={0}>
      <IconButton  onClick={handleClose}>
        <Close />
      </IconButton>
    </Box>
    <DialogContent>
      <Typography>Are you sure you want to delete this {titleText} ?</Typography>
    </DialogContent>
    <DialogActions>
      <Button color="primary" variant="outlined" onClick={handleClose}>
        Cancel
      </Button>
      <Button color="primary" variant="contained" onClick={deleteEnterprise}>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
  );
}
