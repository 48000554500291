import {
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
} from "../actions/type";



const initialState = { 
                      roles: [{
                        roleName: ''
                      }],
                      userProfile: 
                      { 
                        firstName :'', 
                        lastName: '',
                        countryCode: '',
                        maritalStatus: '',
                        mobileNumber: '',
                        dateAnniversary: '',
                        dateOfBirthSelf: '',
                        dateOfBirthSpouse: '',
                        emailID:''
                      },
                      organization:{
                        address: '',
                        city: '',
                        country: '',
                        latitude: '',
                        longitude: '',
                        orgID: '',
                        orgName: '',
                        pinCode: '',
                      },
                      username:''
                    }


  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_PROFILE_SUCCESS:
        return {
          ...state,
          ...payload
        };
      case GET_PROFILE_FAIL:
        return {
          ...state,
        };
      case UPDATE_PROFILE_SUCCESS:
          return {
            ...state,
            ...payload
          };
      case UPDATE_PROFILE_FAIL:
          return {
            ...state,
          };
      default:
        return state;
    }
  }
