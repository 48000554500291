import {
  GET_DOMAIN_SUCCESS,
  GET_DOMAIN_FAIL,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  GET_REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_EXPIRED
} from "../actions/type";

const user = JSON.parse(localStorage.getItem("user"));
const orgId = JSON.parse(localStorage.getItem("orgId"));


const initialState = user && orgId
  ? { isLoggedIn: true, user, orgId }
  : { isLoggedIn: false, user: null, orgId: null };


  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_DOMAIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: false,
        };
      case GET_DOMAIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
        };
      case SEND_OTP_SUCCESS:
          return {
            ...state,
            isLoggedIn: false,
          };
      case SEND_OTP_FAIL:
          return {
            ...state,
            isLoggedIn: false,
          };
      case LOGIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: payload.user,
          orgId: payload.orgId
        };
      case LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
          orgId: null
        };
      case LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
          orgId: null
        };

      case REFRESH_TOKEN_EXPIRED:
        return {
          ...state,
        };

      case GET_REFRESH_TOKEN_SUCCESS:
          return {
            ...state,
            user: payload,
          };

      default:
        return state;
    }
  }
