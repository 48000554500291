import React, { useEffect, useState } from "react";
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';

// material
import {
  Grid,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  MenuItem,
  InputBase,
  Box
} from '@mui/material';

// components
import Page from '../../components/Page';
import Stat from '../../components/_dashboard/enterprise/Stat';
import Scrollbar from '../../components/Scrollbar';
import { EnterpriseListHead } from '../../components/_dashboard/enterprise';
import CreateEnterprise from '../../components/_dashboard/enterprise/CreateEnterprise';
import EditEnterprise from '../../components/_dashboard/enterprise/EditEnterprise';
import EnterpriseDetails from '../../components/_dashboard/enterprise/EnterpriseDetails';
import ConformationPopUp from '../../components/_dashboard/enterprise/ConformationPopUp';
import CreateEnterpriseAdmin from "../../components/_dashboard/enterprise/CreateEnterpriseAdmin";
import Inventory from "../../components/_dashboard/enterprise/Inventory";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import BarChartIcon from '@mui/icons-material/BarChart';
import Paper from '@mui/material/Paper';

//Services
import Enterprise from '../../Services/enterprise';
import { useDispatch } from "react-redux";
import { getEnterpriseDetails } from '../../actions/enterprise';

import { useSelector } from "react-redux";
import { getTitle } from '../../actions/dashboardTitle';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'enterpriseID', label: 'Enterprise ID', alignRight: false },
  { id: 'enterpriseName', label: 'Enterprise Name', alignRight: false },
  { id: 'enterpriseType', label: 'Enterprise Type', alignRight: false },
  { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------



function EnterpriseManagement() {
  const profile = useSelector(state => state.profile);
  const currentRole = profile ? profile.roles[0].roleName : ''
  const [filterType, setFilterType] = useState('');
  const [searchText, setSearchText] = useState('');
  const [enterpriseType, setEnterpriseType] = useState('');
  const enterpriseTypeOptions = [{ name: 'Both', value: 'Both' },{ name: 'B2B', value: 'B2B' },{ name: 'B2B2C', value: 'B2B2C' }]
  const filterTypeOptions = [{ name: 'Enterprise Name', value: 'enterpriseName' }, { name: 'Enterprise Type', value: 'enterpriseType' }, { name: 'City', value: 'city' }, { name: 'Country', value: 'country' }, { name: 'Pin Code', value: 'pinCode' }]
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedEnterprise, setSelectedEnterprise] = useState('');
  const [selectedEnterpriseRole, setSelectedEnterpriseRole] = useState('');
  const [selectedEnterpriseName, setSelectedEnterpriseName] = useState('');
  const [activeEnterpriseId, setActiveEnterpriseId] = useState('');
  const [details, setDetails] = useState({});
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdminForm, setAdminForm] = useState(false);
  const [openInventory, setInventory] = useState(false);
  const [openConform, setConform] = useState(false);
  const [openDetailsModal, setDetailsModal] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentStat, setCurrentStat] = useState({});
  const [enterpriseList, setEnterpriseList] = useState([]);
  const [polarSeriesData, setPolarSeriesData] = useState([0,0,0,0]);


  const handleFilterType = (event) => {
    setSearchText('');
    if(event.target.value === 'enterpriseType'){
      setEnterpriseType('')
    }
    setFilterType(event.target.value);
  };

  const handleEnterpriseType = (event) => {
    if(event.target.value === 'Both'){
      setSearchText('');
    } else {
      setSearchText(event.target.value);
    }
    setEnterpriseType(event.target.value);
  };

  const handleCreateModal = (state) => {
    setOpen(state);
  }
  const handleAdminModal = (state) => {
    setAdminForm(state);
  }
  const handleSearchChange = (event) => {
    setSearchText(event.target.value)
  }
  const handleEditModal = (state) => {
    setOpenEdit(state);
  }
  const openEditModal = () => {
    setOpenEdit(true);
  }
  const handleDetailsModal = (state) => {
    setDetailsModal(state);
  }
  const handleInventoryModal = (state) => {
    setInventory(state);
  }
  const openModal = () => {
    setOpen(true);
  }
  const openConformPopUp = (id) => {
    setActiveEnterpriseId(id);
    setConform(true);
  }
  const handleConformClose = (event) => {
    setConform(event);
  }
  const goToCreateAdmin = (id, role, name) => {
    setSelectedEnterprise(id);
    setSelectedEnterpriseRole(role);
    setSelectedEnterpriseName(name);
    handleAdminModal(true);
  }
  const goToDetails = (id) => {
    Enterprise.getEnterpriseDetails(id).then((result) => {
      setDetails(result);
      setDetailsModal(true);
    });
  }

  const editEnterprise = (id) => {
    dispatch(getEnterpriseDetails(id))
      .then((data) => {
        openEditModal(true);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  const goToInventory = (id) => {
     getStatisticsData({assetCategory: 'All', assetType: 'All'} , id);
     setActiveEnterpriseId(id);
     setInventory(true);
  }

  const deleteEnterprise = (id) => {
    Enterprise.deleteEnterprise(id).then((result) => {
      updateEnterpriseManagement();
    });
  }

  const changePolarChartData = (category, id) => {
    getStatisticsData(category, id);
  }

  function getEnterpriseState() {
    Enterprise.getEnterpriseStats().then((result) => {
      setCurrentStat(result)
    });
  }
  function getStatisticsData(category, id){
    let params = {};
    if(category.assetCategory !== 'All'){
      params = {
        assetCategory: category.assetCategory,
        assetType: category.assetType
      };
    }
    Enterprise.getAllEnterpriseStats(id, params).then((result) => {
      let seriesData = [];
      seriesData.push(result.enterpriseProvisioned);
      seriesData.push(result.userProvisioned);
      seriesData.push(result.assetProvisioned);
      seriesData.push(result.total);
      setPolarSeriesData(seriesData);
    },
    () => {
      setPolarSeriesData([0,0,0,0]);
    });
  }
  function getSearchResults() {
    let params = {};
    params[filterType] = searchText;
    Enterprise.getEnterpriseSearch(params).then((result) => {
      setEnterpriseList(result.Enterprises);
      setTotalCount(result.page.totalRecords);
    },
    () => {
        setEnterpriseList([]);
    });
  }
  function getEnterpriseList() {
    let params = {
      pageSize: rowsPerPage,
      pageNumber: page
    }
    Enterprise.getEnterpriseList(params).then((result) => {
      setEnterpriseList(result.Enterprises);
      setTotalCount(result.page.totalRecords);
    },
    () => {
        setEnterpriseList([]);
    });
  }
  const handleDeleteEnterprise = (id) => {
    deleteEnterprise(id);
    handleConformClose(false);
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };


  function updateEnterpriseManagement() {
    getEnterpriseState();
    if (searchText !== '') {
      getSearchResults()
    } else {
      getEnterpriseList()
    }
  }
  useEffect(() => {
    setFilterType(filterTypeOptions[0].value)
    getEnterpriseState();
  }, [])
  useEffect(() => {
    if (searchText !== '') {
      getSearchResults()
    } else {
      getEnterpriseList()
    }
  }, [searchText, page, rowsPerPage])
  useEffect(()=>{
    dispatch(getTitle('Enterprise Management'))
  },[])
  return (
    <Page title="Enterprise Management">
      <Container maxWidth="xl">
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
             Enterprise Management
          </Typography>
        {currentRole !== 'ROLE_SYS_LOGISTIC_MANAGER' ? <Button
            variant="outlined"
            onClick={openModal}
            startIcon={<Icon icon={plusFill} />}
          >
            Create Enterprise
          </Button> : ''}
        </Stack> */}
        <Grid container spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Stat name={'Total Enterprise'} value={currentStat.totalEnterprise} />
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Stat name={'Total B2B Enterprise'} value={currentStat.totalB2BEnterprises} />
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Stat name={'Total B2B2C Enterprise'} value={currentStat.totalB2B2CEnterprise} />
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={12} md={12}>
             <Stack direction="row" alignItems="center" justifyContent="space-between">
                   <Box>
                   <Typography sx={{fontSize:'15px', fontWeight:'400' }} color={'subtitle1.main'} variant="subtitle3" gutterBottom>
                      Filter Type
                    </Typography>
                    <Paper
                      component="form"
                      sx={{ display: 'flex', alignItems: 'center', width: 400 }}
                    >
                      <TextField
                        sx={{ mr: 1, flex: 1 }}
                        select
                        value={filterType}
                        onChange={handleFilterType}
                        size="small"
                      >
                        {filterTypeOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                        ))}
                      </TextField>
                    {filterType === 'enterpriseType' ? <TextField
                        sx={{ flex: 1 }}
                        select
                        value={enterpriseType}
                        onChange={handleEnterpriseType}
                        size="small"
                      >
                        {enterpriseTypeOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                        ))}
                      </TextField> :
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search"
                        value={searchText}
                        inputProps={{ 'aria-label': 'Search' }}
                        onChange={handleSearchChange}
                      /> }
                      </Paper>
                    </Box>
                    <Box>
                         {currentRole !== 'ROLE_SYS_LOGISTIC_MANAGER' ? <Button
                        variant="outlined"
                        onClick={openModal}
                        startIcon={<Icon icon={plusFill} />}
                      >
                        Create Enterprise
                      </Button> : ''}
                    </Box>
             </Stack>
          
          </Grid>
        </Grid>
        <Card sx={{ my: 3 }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <EnterpriseListHead
                  headLabel={TABLE_HEAD}
                />
                <TableBody>
                  {enterpriseList.map((row) => {
                    const { enterpriseID, enterpriseName, enterpriseType, phoneNumber } = row;

                    return (
                      <TableRow
                        key={enterpriseID}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="center">{enterpriseID}</TableCell>
                        <TableCell align="center">{enterpriseName}</TableCell>
                        <TableCell align="center">{enterpriseType}</TableCell>
                        <TableCell align="center">{phoneNumber}</TableCell>
                        <TableCell align="center" style={{ width: 100 }}>
                          <Stack direction="row" spacing={1}>
                            <Button onClick={() => goToDetails(enterpriseID)} size="small" variant="outlined" color="success" endIcon={<InfoIcon />}>
                              Details
                            </Button>
                           {currentRole !== 'ROLE_SYS_LOGISTIC_MANAGER'? <>
                            <Button onClick={() => editEnterprise(enterpriseID)} size="small" variant="outlined" color="secondary" endIcon={<EditIcon />}>
                              Edit
                            </Button>
                            <Button onClick={() => openConformPopUp(enterpriseID)} size="small" variant="outlined" color="error" endIcon={<DeleteIcon />}>
                              Delete
                            </Button>
                            </>
                             : <Button onClick={() => goToInventory(enterpriseID)} size="small" variant="outlined" color="secondary" endIcon={<BarChartIcon />}>
                             Inventory
                           </Button>}
                           {currentRole === 'ROLE_SYS_ORG_ADMIN'? <Button onClick={() => goToInventory(enterpriseID)} size="small" variant="outlined" color="secondary" endIcon={<BarChartIcon />}>
                             Inventory
                           </Button> : ''}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {enterpriseList.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={12}>No Enterprise Present</TableCell>
                    </TableRow>
                  ) : ''}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <CreateEnterprise openForm={open} handleCreateModal={handleCreateModal} goToCreateAdmin={goToCreateAdmin} updateData={() => updateEnterpriseManagement()} />
        <EnterpriseDetails openForm={openDetailsModal} handleCreateModal={handleDetailsModal} details={details} goToCreateAdmin={goToCreateAdmin} />
        <EditEnterprise openForm={openEdit} handleEditModal={handleEditModal} updateData={() => updateEnterpriseManagement()} />
        <Inventory polarSeriesData={polarSeriesData} openForm={openInventory} id={activeEnterpriseId}  handleEditModal={handleInventoryModal} changePolarChartData={changePolarChartData} />
        <ConformationPopUp open={openConform} id={activeEnterpriseId} handleConformClose={handleConformClose} handleDelete={handleDeleteEnterprise} titleText={'enterprise'} />
        <CreateEnterpriseAdmin openAdminForm={openAdminForm} handleAdminModal={handleAdminModal} selectedEnterprise={selectedEnterprise} role={selectedEnterpriseRole} name={selectedEnterpriseName}/>
      </Container>
    </Page>
  );
}

export default EnterpriseManagement;
