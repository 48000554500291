import {
  GET_ENTERPRISE_DETAILS_SUCCESS,
  GET_ENTERPRISE_DETAILS_FAIL,
} from "./type";

import EnterpriseService from '../Services/enterprise';

export const getEnterpriseDetails = (enterpriseId) => (dispatch) => {
  return EnterpriseService.getEnterpriseDetails(enterpriseId).then(
    (response) => {
      dispatch({
        type: GET_ENTERPRISE_DETAILS_SUCCESS,
        payload: response
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
      (error.response && error.response.data && error.response.data.error_description) ||
        error.response.data.message ||
        error.toString();

      dispatch({
        type: GET_ENTERPRISE_DETAILS_FAIL,
      });

      return Promise.reject();
    }
  );
};