import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import AssetLayout from './layouts/asset';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

//
import Login from './Pages/Login/Login';
import Dashboard from './Pages/Dashboard/Dashboard';
import Profile from './Pages/Profile/Profile';
import EnterpriseManagement from './Pages/EnterpriseManagement/EnterpriseManagement';
import UserManagement from './Pages/UserManagement/UserManagement';
import Devices from './Pages/Devices/Devices';
import LogisticsDashboard from './Pages/LogisticsDashboard/LogisticsDashboard';
import InventoryStatistics from './Pages/Devices/InventoryStatistics';
import MainDashboard from './Pages/Dashboard/MainDashboard';
import NewDashboard from './Pages/Dashboard/NewDashboard';

import DashbaordTabs from './Pages/Dashboard/DashboardTabs';
import Settings from './Pages/Settings/Settings';

// ----------------------------------------------------------------------



export default function Router() {
  return useRoutes([
   {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '/dashboard', element: <Navigate to="/dashboard/app" /> },
        { path: 'app', element:<Dashboard /> } ,
        { path: 'profile', element: <Profile /> },
        { path: 'enterpriseManagement', element: <EnterpriseManagement /> },
        { path: 'user', element: <UserManagement /> },
        { path: 'devices', element: <Devices /> },
        { path: 'logisticDashboard', element: <LogisticsDashboard /> },
        { path: 'enterprise', element: <NewDashboard /> },
        { path: 'organization', element: <NewDashboard /> },
        { path: 'inventoryStats', element: <InventoryStatistics /> },
        { path: 'settings', element: <Settings /> },
      ]
    },
    {
      path: '/asset',
      element: <AssetLayout />,
      children: [
        { path: 'dashboard/:id', element: <DashbaordTabs /> },
      ]
    },
    {
      path: '/battery',
      element: <AssetLayout type={'battery'} />,
      children: [
        { path: 'dashboard/:id', element: <DashbaordTabs type={'battery'} /> },
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '/', element: <Navigate to="/login" /> },
        { path: '*', element: <Navigate to="/login" /> }
      ]
    },
    { path: '*', element: <Navigate to="/" replace /> }
  ]);
}
