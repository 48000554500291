import React, { useState, useEffect } from 'react';

import refresh from '@iconify/icons-eva/refresh-outline';
import { Icon } from '@iconify/react';
// material
import { Card, Box, Stack, TextField, MenuItem, Grid, Button, Typography } from '@mui/material';

import PolarChart from '../common/PolarChart';
import ReactApexChart from 'react-apexcharts';


// ----------------------------------------------------------------------



export default function SuperUserStats({serviceSummary, deviceSummaryData, deviceStateSummaryData}) {
  const [noStateData, setStateData] = useState(false);
  const [noSummaryData, setNoSummaryData] = useState(false);
  const [noServiceData, setNoServiceData] = useState(false);
  const [selectedAssetCategory, setSelectedAssetCategory] = useState('');
  const [assetCategoryList, setAssetCategoryList] = useState([]);
  const [summaryData, setSummaryData] = useState([0,0,0,0,0]);
  const [summaryLabels, setSummaryLables] = useState(['Enterprise Inventory', 'User Allocation', 'Asset Provisioned','Org Provisioned']);
  const [summaryStateData, setSummaryStateData] = useState([0,0,0,0,0]);
  const [summaryStateLabels, setSummaryStateLables] = useState(['Connected', 'In Reach', 'Not Reporting', 'Provisioned']);
  const [serviceStatusSummary, setServiceStatusSummary] = useState([0,0,0,0]);
  const [serviceStatusLabels, setServiceStatusLabels] = useState(['Active', 'Resume', 'Suspend', 'Deactive']);

  let donutChartData = {
    options: {
      chart: {
        type: 'donut',
      },
      plotOptions: {
      },
      dataLabels: {
        enabled: true,
      },
      fill: {
        type: 'gradient',
      },
      colors: ['rgb(0, 227, 150)', 'rgb(0, 143, 251)', 'rgb(255, 69, 96)', 'rgb(254, 176, 25)'],
      legend: {
        position: 'right',
        horizontalAlign: 'right', 
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex]
        }
      },
      labels: summaryStateLabels,
    },
  }

 let radialChartOptions = {
    options: {
      chart: {
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 10,
            size: '30%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            }
          }
        }
      },
      colors: ['rgb(0, 227, 150)', '#90EE90', 'rgb(254, 176, 25)', 'rgb(255, 69, 96)'],
      labels: serviceStatusLabels,
      legend: {
        show: true,
        floating: true,
        position: 'left',
        offsetX: 60,
        offsetY: -2,
        markers: {
          size: 0
        },
        formatter: function(seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
        },
        itemMargin: {
          vertical: 0
        }
      },
    },
 }

  function getSummary() {
    setNoSummaryData(false);
    setSummaryData([deviceSummaryData.enterpriseProvisioned, deviceSummaryData.userProvisioned, deviceSummaryData.assetProvisioned, deviceSummaryData.orgProvisioned]);
  }
  function getStateSummary() {
    setStateData(false);
    setSummaryStateData([deviceStateSummaryData.connected, deviceStateSummaryData.inReach, deviceStateSummaryData.notReporting, deviceStateSummaryData.provisioned]);
  }

  function getServiceStateSummary() {
    setNoServiceData(false);
    setServiceStatusSummary([serviceSummary.active, serviceSummary.resume, serviceSummary.suspend, serviceSummary.deactivate]);
  }

  useEffect(() => {
    if(deviceSummaryData){
      getSummary();
    }else{
      setNoSummaryData(true);
    }
  }, [deviceSummaryData])

  useEffect(() => {
    if(deviceStateSummaryData){
      getStateSummary();
    }else{
      setStateData(true);
    }
}, [deviceStateSummaryData])

useEffect(() => {
  if(serviceSummary){
    getServiceStateSummary();
  }else{
    setNoServiceData(true);
  }
}, [serviceSummary])

  return (
        <Card sx={{px: 2, py:2}}>
        <Grid container sx={{mt:0.2}}>
          <Grid item xs={4} md={4} lg={4} sx={{borderRight: `3px solid rgb(195, 195, 201, 0.1)`}}>
                {!noServiceData ? <>
                                <Typography variant='h6'  sx={{mb:2,pb:2, mx:3, borderBottom: `3px solid rgb(195, 195, 201, 0.1)`}}>Service Status Summary</Typography>
                                <ReactApexChart options={radialChartOptions.options} series={serviceStatusSummary} type="radialBar"  height={250} />
                                </>
                                : <Stack direction={'row'} justifyContent="center" alignItems={'center'} sx={{height:'100%'}}>
                                      <Stack>
                                          <Typography variant='h6'>No Data</Typography>
                                      </Stack>
                                </Stack>
                }
          </Grid>
          <Grid item xs={4} md={4} lg={4} sx={{borderRight: `3px solid rgb(195, 195, 201, 0.1)`}}>
             {!noStateData ? <>
                  <Typography variant='h6'  sx={{mb:2,pb:2, mx:3, borderBottom: `3px solid rgb(195, 195, 201, 0.1)`}}>Device State Summary</Typography>
                  <ReactApexChart options={donutChartData.options} series={summaryStateData} type="donut"  height={220} />
                  </>
                                : <Stack direction={'row'} justifyContent="center" alignItems={'center'} sx={{height:'100%'}}>
                                      <Stack>
                                          <Typography variant='h6'>No Data</Typography>
                                      </Stack>
                                </Stack>
                }
          </Grid>
          <Grid item xs={4} md={4} lg={4} >
          {!noSummaryData ? <>
              <Typography  variant='h6' sx={{mb:2,pb:2, mx:3, borderBottom: `3px solid rgb(195, 195, 201, 0.1)`}}>Device Summary</Typography>
              <PolarChart seriesData={summaryData} labels={summaryLabels}/>
              </>
                                : <Stack direction={'row'} justifyContent="center" alignItems={'center'} sx={{height:'100%'}}>
                                      <Stack>
                                          <Typography variant='h6'>No Data</Typography>
                                      </Stack>
                                </Stack>
                }
          </Grid>
          <Grid item xs={12} md={12} lg={12} textAlign='center'>
              {!noSummaryData ? <Typography variant='h6' sx={{mt:3.5}}>Total: {serviceSummary?.total}</Typography> : ''}
          </Grid>
          </Grid>
        </Card>
  );
}
