import React, { useState, useEffect } from 'react';

// material
import { Card, 
  CardContent, 
  Paper, 
  Box, 
  Stack, 
  TextField, 
  MenuItem, 
  Grid, 
  Button, 
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
 } from '@mui/material';

import { useTheme } from '@emotion/react';

import AssetSelection from '../../common/AssetSelection';
import Scrollbar from '../../Scrollbar';

import Settings from '../../../Services/settings';

const TABLE_HEAD = [
  { id: 'cdn', label: 'Command Name', alignRight: false },
  { id: 'payloadName', label: 'Field Name', alignRight: false },
  { id: 'payloadType', label: 'Field Value', alignRight: false },
];

export default function CommandConfiguration(props) {
  const theme = useTheme();
  const [activeAsset, setActiveAsset] = useState(null);
  const [configList, setConfigList] = useState([]);

  const assetChanged = (asset) =>{
    setActiveAsset(asset);
  }
function getCommandConfigurationDetails(){
  if(activeAsset){
    Settings.getCommandConfig(activeAsset.assetCategory, activeAsset.assetType).then((result) => {
      setConfigList(result.cmdRefList);
     },
     () => {
      setActiveAsset(null);
      setConfigList([]);
     });
  }
}
  useEffect(()=>{
    getCommandConfigurationDetails()
  },[activeAsset])

  return (<Card sx={{p:3}}>
    <AssetSelection  assetChanged={assetChanged}/>
    <CardContent sx={{py:1,px:0}}>
       <Scrollbar>
           <TableContainer>
               <Table>
                <TableHead>
                <TableRow sx={{bgcolor: theme.palette.table.bg}}>
                {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={'center'}
                        sx={{color: theme.palette.table.headerText}}
                      >{headCell.label}
                      </TableCell>
                    ))}
                </TableRow>
                </TableHead>
                <TableBody>
                {configList.map((row, index) => {
                    const { cmd, cmdPayloadRef } = row;

                    return (
                      <TableRow
                        key={`${index}+->`}
                      >
                        <TableCell align="center">{`${cmd}`}</TableCell>
                        <TableCell align="center">{`${cmdPayloadRef[0].fieldName}`}</TableCell>
                        <TableCell align="center">{`${cmdPayloadRef[0].fieldType}`}</TableCell>
                      </TableRow>
                    );
                  })}
                  {configList.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={12}>No Configuration Data Present</TableCell>
                    </TableRow>
                  ) : ''}
                </TableBody>
               </Table>
           </TableContainer>
       </Scrollbar>
    </CardContent>
  </Card>)
}
