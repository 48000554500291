/*global google*/
import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";

import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";

const defaultLocation = { lat: 40.756795, lng: -73.954298 };

let directionsService;
function TripMap({tripData}) {
const [state, setState] = useState({ directions: null, bounds: null });
const [origin, setOrigin] = useState({ lat: null, lng: null });
const [destination, setDestination] = useState({ lat: null, lng: null });

let mapOptions = {
  fullscreenControl: false,
  streetViewControl: false,
  zoomControl: false,
}

  const onMapLoad = (map) => {
    directionsService = new google.maps.DirectionsService();
    //load default origin and destination
    changeDirection(origin, destination);
  };

  //function that is calling the directions service
  const changeDirection = (origin, destination) => {
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          //changing the state of directions to the result of direction service
          setState({
            directions: result
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  useEffect(()=>{
     if(tripData){
       setOrigin({ lat: tripData.startLatitude, lng: tripData.startLongitude});
       setDestination({ lat: tripData.endLatitude, lng: tripData.endLongitude});
     }
  },[tripData])

    return (
        <GoogleMap
          center={defaultLocation}
          options={mapOptions}
          zoom={1}
          onLoad={(map) => onMapLoad(map)}
          mapContainerStyle={{ height: "40vh", width: "100%" }}
        >
          {state.directions !== null && (
            <DirectionsRenderer directions={state.directions} />
          )}
        </GoogleMap>
    );
}

export default TripMap;