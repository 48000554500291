import * as React from 'react';
// material
import {
  Grid,
  Stack,
  Typography,
  Box,
  Slider
} from '@mui/material';

import { withStyles } from '@mui/styles';


import { ReactComponent as MotorTemp } from "../../Assets/AssetDashboard/icon_motor_temp.svg";
import { ReactComponent as MotorCurrent } from "../../Assets/AssetDashboard/icon_motor_current.svg";
import { ReactComponent as BatteryPack } from "../../Assets/AssetDashboard/icon_battery_pack.svg";
import { ReactComponent as BatteryHealth } from "../../Assets/AssetDashboard/icon_state_of_health.svg";
import { ReactComponent as EcuIcon } from "../../Assets/AssetDashboard/icon_ecu_temp.svg";



const marksForTemperatureSlider = {
  batteryHealth: [{ value: 0, label: '0%'}, { value: 20, label: '20%'}, { value: 40, label: '40%'}, { value: 60, label: '60%'}, { value: 80, label: '80%'}, { value: 100, label: '100%'}],
  batteryPack: [{ value: 0, label: '0°C'}, { value: 20, label: '20°C'}, { value: 40, label: '40°C'}, { value: 60, label: '60°C'}, { value: 80, label: '80°C'}],
  ecuTemp: [{ value: 0, label: '0°C'}, { value: 20, label: '20°C'}, { value: 40, label: '40°C'}, { value: 60, label: '60°C'}, { value: 80, label: '80°C'}],
  motorTemp: [{ value: 0, label: '0°C'}, { value: 20, label: '20°C'}, { value: 40, label: '40°C'}, { value: 60, label: '60°C'}, { value: 80, label: '80°C'}],
  motorCurrent: [{ value: 0, label: '0 mA'}, { value: 20, label: '20 mA'}, { value: 40, label: '40 mA'}, { value: 60, label: '60 mA'}, { value: 80, label: '80 mA'}]
}

const IconMapping = {
  motorTemp: <MotorTemp/>,
  motorCurrent: <MotorCurrent/>,
  batteryPack: <BatteryPack />,
  ecuTemp: <EcuIcon width="25px" height="25px"/>,
  batteryHealth: <BatteryHealth />,
 }
 

 const CustomSlider = withStyles({
  root: {
    height: 3,
  },
  thumb: {
    height: 0,
  },
  track: {
    height: 3,
  },
  rail: {
    color: "#DCDCDC"
  }
})(Slider);

function valuetext(value, unit) {
  return `${value}${unit}`;
}

export default function TemperatureSlider({title, value, iconName, unit, isExtra}) {
  return (
    <>
    <Box sx={{width:'100%'}}>
                      <Stack direction="row" alignItems='center' justifyContent={'space-between'}>
                         <Box>
                            <Box component={'span'} sx={{position:'absolute'}}>{IconMapping[iconName]}</Box>
                            <Box component={'span'} sx={{ml:isExtra ? 4.5: 2.5}}><Typography  variant="subtitle1" component={'span'} sx={{fontSize:'11px',fontWeight:200, color:'subtitle1.secondary'}}>{title}</Typography></Box>
                         </Box>
                         <Typography component={'span'} variant="subtitle1" sx={{ ml: 2, fontSize:'14px',fontWeight:500, color:'slider.light'}}>{Math.round(value)}{unit}</Typography>
                      </Stack>
                      <Box sx={{mt:0.5}}>
                                  <CustomSlider
                                    aria-label={title}
                                    value={value}
                                    color={'slider'}
                                    getAriaValueText={(value) => valuetext(value, unit)}
                                    step={1}
                                    valueLabelDisplay="auto"
                                    // marks={marksForTemperatureSlider[iconName]}
                                  />
                     </Box>
    </Box>
    </>
  );
}