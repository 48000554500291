import React, { useState, useEffect } from 'react';
// material
import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  List,
  ListItem,
  Paper,
  DialogTitle
} from '@mui/material';

import DevicesService from '../../../Services/devices';


// ----------------------------------------------------------------------
const fullmonthList = [{month: 'Jan',target: 0},{month: 'Feb',target: 0},{month: 'Mar',target: 0},{month: 'Apr',target: 0},{month: 'May',target: 0},{month: 'Jun',target: 0},{month: 'Jul',target: 0},{month: 'Aug',target: 0},{month: 'Sep',target: 0},{month: 'Oct',target: 0},{month: 'Nov',target: 0},{month: 'Dec',target: 0}];

function TextFieldItem({value, month, index, valueChanged}) {
  const [currentValue, setCurrentValue] = useState(0);

  const handleInputValue = (event) =>{
    setCurrentValue(event.target.value);
    if(event.target.value === '' || event.target.value < 0){
      valueChanged(0,month)
    } else {
      valueChanged(parseInt(event.target.value),month)
    }
  }
  useEffect(()=>{
    setCurrentValue(value)
  },[value])
  return (
    <>
      <TextField
      id={`${month}-${index}`}
      sx={{mt:1}}
      type="number"
      label='No of Units'
      value={currentValue}
      size='small'
      onChange={handleInputValue}
      variant="outlined"
      />
    </>
  );
}
let resltObj  = {};
export default function StatsTarget({title, open, handleTargetModal, selectedAssetCategory, statData, selectedYear,isCreate, updateData}) {
  const [monthList, setMonthList] = useState([]);
  


  function createTarget(cgMonthTargets){
    let body = {
      assetCategory : selectedAssetCategory.assetCategory,
      assetType :  selectedAssetCategory.assetType,
      year : parseInt(selectedYear),
      cgMonthTargets : cgMonthTargets
   };
      DevicesService.createTarget(body).then(() => {
        handleClose();
        updateData();
      },
     () => {
       handleClose();
       updateData();
     });
  }

  function editTarget(cgMonthTargets){
    let body = {
      assetCategory : selectedAssetCategory.assetCategory,
      assetType :  selectedAssetCategory.assetType,
      cgMonthTargets : cgMonthTargets
   };
      DevicesService.editTarget(selectedYear, body).then(() => {
        handleClose();
        updateData();
      },
     () => {
       handleClose();
       updateData();
     });
  }

  function getIndividualMonthData(month){
    let units = 0;
    statData.forEach((v)=>{
      if(v.month === month){
          units= v.targetCommGateways;
      }
    });
    return units;
  }

  const handleClose = () => {
    handleTargetModal(false);
  };
  const submitTarget = () =>{  
      let zeroFlag = false;
      for(const [key, value] of Object.entries(resltObj)){
        if(value > 0){
          zeroFlag = true;
        }
      }
      if(zeroFlag){
        let cgMonthTargets = [];
        for(const [key, value] of Object.entries(resltObj)){
          cgMonthTargets.push({
             month: key,
             target: value
          })
        }
        if(isCreate){
          createTarget(cgMonthTargets);
        } else {
          editTarget(cgMonthTargets);
        }
       
      }
  }
  const handleTargetChange = (value, month) => {
    resltObj[month] = parseInt(value);
  }

  useEffect(() => {
    setMonthList([...fullmonthList]);
    resltObj = {};
    fullmonthList.forEach((v)=>{
      resltObj[v.month] = v.target;
    });
    if(!isCreate){
      statData.forEach((v)=>{
        resltObj[v.month] = v.targetCommGateways;
      });
    }
  }, [selectedAssetCategory, selectedYear, statData])

  return (
    <Dialog open={open} onClose={handleClose} fullWidth onBackdropClick={handleClose}  transitionDuration={{ enter: 500, exit: 500 }}>
       <DialogTitle>{title}</DialogTitle>
        <DialogContent>
                        <Paper  elevation={24} sx={{width:'100%', px:2, py:2}}>
                           <Stack spacing={4}   direction="row">
                            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{width:'100%', textAlign:'center'}}>
                            <Box sx={{width:'100%'}}>
                              <Typography variant='subtitle' gutterBottom>Asset Category</Typography>
                              <Typography variant='h5' gutterBottom>{selectedAssetCategory.assetCategory}</Typography>
                            </Box>
                            <Box sx={{width:'100%'}}>
                              <Typography variant='subtitle' gutterBottom>Asset Type</Typography>
                              <Typography variant='h5' gutterBottom>{selectedAssetCategory.assetType}</Typography>
                            </Box>
                            <Box sx={{width:'100%'}}>
                              <Typography variant='subtitle' gutterBottom>Year</Typography>
                              <Typography variant='h5' gutterBottom>{selectedYear}</Typography>
                            </Box>
                            </Stack>
                          </Stack>
                        </Paper>

                        <Paper elevation={24} sx={{width:'100%', px:2, py:2, mt:1}}>
                        <Box sx={{ width: '100%'}}>
                        <List>
                        {monthList.map((option, index) => (
                                    <ListItem key={`${option.month}${index}`} disablePadding alignItems='center' sx={{justifyContent: 'center'}}>
                                          <Box mx={2} sx={{width:'8%'}}>
                                            <Typography variant='subtitle' gutterBottom>{option.month}:</Typography>
                                          </Box>
                                            <TextFieldItem value={isCreate ? 0 : getIndividualMonthData(option.month)} month={option.month}  index={index} valueChanged={handleTargetChange} />
                                  </ListItem>
                          ))}
                        </List>
                        </Box>
                        <Box mt={1}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={6} sm={6} align='center'>
                                        <Button
                                          variant="outlined"
                                          color="primary"
                                          onClick={handleClose}
                                        >
                                          Cancel
                                        </Button>
                                      </Grid>
                                    <Grid item xs={6} sm={6} align='center'>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={submitTarget}
                                      >
                                        Submit
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Box>
                        </Paper>
                      
                       
        </DialogContent>
      </Dialog>
  );
}
