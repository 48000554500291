import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const PRIMARY = {
  lighter: '#C8FACD',
  light: '#373737',
  main: '#13131B',
  dark: '#030303',
  darker: '#005249',
  contrastText: '#FFFFFF'
};
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#a44fbb',
  main: '#8E24AA',
  dark: '#631976',
  darker: '#091A7A',
  contrastText: '#fff'
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#64B5F6',
  main: '#2196F3',
  dark: '#1976D2',
  darker: '#04297A',
  contrastText: '#FFFFFF'
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: 'rgb(95, 164, 99)',
  main: '#388E3C',
  dark: 'rgb(39, 99, 42)',
  darker: '#08660D',
  contrastText: '#FFFFFF'
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFB74D',
  main: '#FF9800',
  dark: '#F57C00',
  darker: '#7A4F01',
  contrastText:'rgba(0, 0, 0, 0.87)'
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#E57373',
  main: '#F44336',
  dark: '#D32F2F',
  darker: '#7A0C2E',
  contrastText: '#FFFFFF'
};

const Slider = {
  lighter: '#07B27F',
  light: '#07B27F',
  main: '#07B27F',
  dark: '#07B27F',
  darker: '#07B27F',
  contrastText: '#07B27F'
};

const table = {
 bg: '#FFFFFF',
 headerText: '#4A484A',
};

const subtitle1 = {
 main: '#9391A0',
 secondary: '#484859'
 };
 const tabColors = {
  slectedText: '#1D1D26',
  selectedMenuBorder: '#FC4C4C',
  normalText: '#4A484A',
  };
const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4']
};

const SIDEBAR = {
  logoText: 'rgb(142, 36, 170)',
  text: '#FC4C4C',
  listMenuText: '#9391A0'
}

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  slider: {...Slider},
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: 'rgba(0, 0, 0, 0.12)',
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#fff', default: '#fff', neutral: GREY[200], current:'#F5F5F5' },
  sidebar: {...SIDEBAR},
  table: {...table},
  subtitle1: {...subtitle1},
  tabColors: {...tabColors},
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

export default palette;
