import React, { useEffect, useState } from "react";
// material
import {
  Grid,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  MenuItem,
  InputBase,
  Paper,
  Box
} from '@mui/material';
// components
import InfoIcon from '@mui/icons-material/Info';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { EnterpriseListHead } from '../../components/_dashboard/enterprise';
import DashboardApi from '../../Services/dashboard';
import AutoFetchList from '../../components/common/AutoFetchList';

import SuperUserStats from '../../components/common/SuperUserStats';
import DeviceDetails from '../../components/_dashboard/devices/DeviceDetails';
import Iconify from "../../components/common/Iconify";
import AutoModeIcon from '@mui/icons-material/ModelTraining';
import RejectedIcon from '@mui/icons-material/SyncDisabled';
import ClosedIcon from '@mui/icons-material/PublishedWithChanges';
import InprogressIcon from '@mui/icons-material/RotateLeft';
import StackedBarChart from "../../components/common/StackedBarChart";

import refresh from '@iconify/icons-eva/refresh-outline';
import FactoryIcon from '@mui/icons-material/Factory';
import { Icon } from '@iconify/react';

import {useNavigate} from "react-router-dom";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getTitle } from '../../actions/dashboardTitle';
import { getAssetDashboardDetails } from '../../actions/assetDashboard';
import { getBatteryDashboardDetails } from '../../actions/batteryDashboard';


const TABLE_HEAD = [
  { id: 'rsn', label: 'RSN', alignRight: false },
  { id: 'assetCategory', label: 'Asset Category', alignRight: false },
  { id: 'assetType', label: 'Asset Type', alignRight: false },
  { id: 'enterpriseID', label: 'Enterprise ID', alignRight: false },
  { id: 'provisioningState', label: 'Provisioning State', alignRight: false },
  { id: 'deviceState', label: 'Device State', alignRight: false },
  { id: '' }
];


function FlexDetailsItem({ title, value, iconName, width='100%', height='100px' }) {
  return (
    <>
     <Paper variant="outlined" component={'div'} sx={{ my:1,py: 1, textAlign: 'center', width: width, height:height }}>
        <Box><Iconify icon={iconName} width="40px" height="40px"/></Box>
        <Typography variant="body2" sx={{ color: 'text.secondary', mt: 0.5 }}>
          {title}
        </Typography>
        <Typography  sx={{ fontSize: '14px', fontWeight: '600' }}>{value}</Typography> 
      </Paper>
    </>
  );
}

function Dashboard() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile);
  const currentRole = profile ? profile.roles[0].roleName : ''
  const [commGatewayList, setDeviceList] = useState([]);
  const [page, setPage] = useState(0);
  const [firstTimeCall, setFirstTimeCall] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [autoFetchState, setAutoFetchState] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedEnterpriseId, setSelectedOrgID] = useState('All');
  const [filterCategoryList, setFilterCategoryList] = useState([]);
  const [assetCategoryList, setAssetCategoryList] = useState([]);
  const [selectedAssetCategory, setSelectedAssetCategory] = useState({});
  const [lastUpdateTime, setLastUpdateTime] = useState(new Date().toLocaleString());
  const [deviceStateSummaryData, setDeviceStateSummaryData] = useState(null);
  const [deviceSummaryData, setDeviceSummaryData] = useState(null);
  const [serviceStatusData, setServiceStatusData] = useState(null);
  const [openDetailsModal, setDetailsModal] = useState(false);
  const [ticketData, setTicketData] = useState(null);
  const [details, setDetails] = useState({});
  const [seriesData, setSeriesData] = useState(Array(1).fill({
    name:'',
    data:[0,0,0,0,0,0,0,0,0,0,0,0]
  }));
  const [filterType, setFilterType] = useState('rsn');
  const [searchText, setSearchText] = useState('');
  const filterTypeOptions = [{ name: 'Device Status', value: 'commGatewayState' }, { name: 'RSN', value: 'rsn' },  { name: 'IMEI', value: 'imei' }, { name: 'MACID', value: 'macid' }, { name: 'CommGatewayBID', value: 'commGatewayBID' }, { name: 'Service Status', value: 'serviceStatus' }];
  const [selectedStatus, setSelectedStatus] = useState('');
  const deviceStatusList = ['PROVISIONED','CONNECTED','INREACH','NOTREPORTING'];
  const statusList = ['ACTIVE','SUSPEND','RESUME','DEACTIVATE'];
  const [selectedDeviceStatus, setSelectedDeviceStatus] = useState('');
  const [noOnboardData, setNoOnboardData] = useState(false);
  const [year, setYear] = useState(null);

  const handleDetailsModal = (state) => {
    setDetailsModal(state);
  }
  const goToDetails = (details) => {
    setDetails(details);
    setDetailsModal(true);
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleAutoCompleteState = (state) =>{
    setAutoFetchState(state);
  }
  const handleEnterpriseChange = (event, newValue) => {
    if (newValue) {
      setSelectedOrgID(newValue.enterpriseID)
    } else {
      setSelectedOrgID('All');
    }
  }
  const handleAssetCategory = (event) => {
    setSelectedAssetCategory(event.target.value);
    setPage(0);
  };
  const handleFilterType = (event) => {
    setPage(0);
    setFilterType(event.target.value);
    setSearchText('');
    setSelectedDeviceStatus('');
    setSelectedStatus('');
  };
  const handleDeviceStatus = (event) => {
    setPage(0);
    setSelectedDeviceStatus(event.target.value);
    setSearchText(event.target.value);
  };
  const handleServiceStatus = (event) => {
    setPage(0);
    setSelectedStatus(event.target.value);
    setSearchText(event.target.value);
  }
  const handleSearchChange = (event) => {
    setSearchText(event.target.value)
  }
  function formatStackedChartData(data){
     setYear(data.year);
      let seriesNames = data.deviceOnboardSummary.map((item)=>{
        return `${item.assetCategory} | ${item.assetType}`
      });
      let seriesArr = [];
      data.deviceOnboardSummary.forEach((element,index) => {
            let values = [];
            element.monthSummary.forEach(item => {
              values.push(item.onBoardCount);
            });
            seriesArr.push({
              name: seriesNames[index],
              data: values
            })
      });
      setSeriesData(seriesArr);
  }
  function getSearchResults(){
    let params = {
      pageSize: rowsPerPage,
      pageNumber: page
    };
    if(selectedAssetCategory?.assetCategory !== 'All' || selectedAssetCategory?.assetType !== 'All'){
      params['assetCategory'] = selectedAssetCategory.assetCategory;
      params['assetType'] = selectedAssetCategory.assetType
    }
    if(searchText){
      params[filterType] = searchText;
    }
    if(selectedEnterpriseId === 'All'){
      DashboardApi.getGlobalDashboardSearchData(params).then((result) => {
        setDeviceList(result.CommGateways);
        setTotalCount(result.page.totalRecords);
      },
      () => {
          setDeviceList([]);
          setTotalCount(0);
      });
    }else{
      DashboardApi.getGlobalDashboardSearchOrgData(params, selectedEnterpriseId).then((result) => {
        setDeviceList(result.CommGateways);
        setTotalCount(result.page.totalRecords);
      },
      () => {
          setDeviceList([]);
          setTotalCount(0);
      });
    }
   
  }
  function getCommGatewayList() {
    let params = {
      pageSize: rowsPerPage,
      pageNumber: page
    };
    if(selectedAssetCategory?.assetCategory !== 'All' || selectedAssetCategory?.assetType !== 'All'){
      params['assetCategory'] = selectedAssetCategory.assetCategory;
      params['assetType'] = selectedAssetCategory.assetType
    }
    if(selectedEnterpriseId === 'All'){
    DashboardApi.getGlobalDashboardData(params).then((result) => {
      setDeviceStateSummaryData(result.deviceStateSummary);
      setDeviceSummaryData(result.deviceSummary);
      setServiceStatusData(result.serviceStatusSummary);
      setTicketData(result.supportTicketSummary);
      if(currentRole === 'ROLE_SYS_ORG_ADMIN'){
        formatStackedChartData(result.onboardSummary);
         setNoOnboardData(true);
      }
       if(firstTimeCall){
        setAssetCategoryList(result.assetCategoryInfo);
        let filterCategoryList = [{assetCategory: 'All', assetType:'All' },...result.assetCategoryInfo];
        setFilterCategoryList(filterCategoryList);
         setSelectedAssetCategory(filterCategoryList[0]);
       }
    },
    () => {
        setDeviceStateSummaryData(null);
        setDeviceSummaryData(null);
        setServiceStatusData(null);
        setTicketData(null);
        setNoOnboardData(false);
    });
    } else {
      DashboardApi.getGlobalDashboardOrgData(params, selectedEnterpriseId).then((result) => {
        setDeviceStateSummaryData(result.deviceStateSummary);
        setDeviceSummaryData(result.deviceSummary);
        setServiceStatusData(result.serviceStatusSummary);
        setTicketData(result.supportTicketSummary);
        if(currentRole === 'ROLE_SYS_ORG_ADMIN'){
          formatStackedChartData(result.onboardSummary);
          setNoOnboardData(true);
        }
      },
      () => {
        setDeviceStateSummaryData(null);
        setDeviceSummaryData(null);
        setServiceStatusData(null);
        setTicketData(null);
        setNoOnboardData(false);
      });
    }
  }
  const goToAssetDashboard = (rsn, enterpriseId, category) =>{
    let params = {
      enterpriseID: enterpriseId
    }
    if(category === 'battery'){
      dispatch(getBatteryDashboardDetails(rsn, params))
      .then((id) => {
        navigate(`/battery/dashboard/${id}`);
      });
    } else {
      dispatch(getAssetDashboardDetails(rsn, params))
      .then((id) => {
        navigate(`/asset/dashboard/${id}`);
      });
    }
   
  }
  const updatePage = () =>{
    setLastUpdateTime(new Date().toLocaleString());
     getCommGatewayList();
     getSearchResults();
  }
  useEffect(() => {
    if(selectedAssetCategory?.assetCategory === 'All' && firstTimeCall){
      setFirstTimeCall(false);
    } else {
      getCommGatewayList();
    }
}, [selectedEnterpriseId, selectedAssetCategory]);

useEffect(() => {
  getSearchResults();
}, [page, rowsPerPage, searchText, selectedAssetCategory, selectedEnterpriseId]);

useEffect(()=>{
  dispatch(getTitle('Dashboard'))
},[])
  return (
    <Page title="Dashboard">
    <Container maxWidth="xl">
        <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center" justifyContent="flex-start">
               {currentRole === 'ROLE_SYS_ORG_ADMIN' ? <Box>
                  <Typography sx={{fontSize:'15px', fontWeight:'400' }} color={'subtitle1.main'} variant="subtitle3" gutterBottom>
                      Select Enterprise
                  </Typography>
                  <Paper
                    component="form"
                    sx={{ display: 'flex', alignItems: 'center', mr: 10 }}
                   >
                   <AutoFetchList open={autoFetchState} handleAutoCompleteState={handleAutoCompleteState} handleEnterpriseChange={handleEnterpriseChange} />
                  </Paper>
                 </Box> : ''}
                  <Box>
                    <Typography sx={{fontSize:'15px', fontWeight:'400' }} color={'subtitle1.main'} variant="subtitle3" gutterBottom>
                          Select Asset Category
                      </Typography>
                    <Paper
                    component="form"
                    sx={{ display: 'flex', alignItems: 'center', width: 320 }}
                    >
                    <TextField
                      sx={{ flex: 1 }}
                      select
                      value={selectedAssetCategory}
                      onChange={handleAssetCategory}
                      size="small"
                    >
                    {filterCategoryList.map((option, index) => (
                      <MenuItem key={index} value={option}>{ option.assetCategory === 'All' ? `All`:`${option.assetCategory} | ${option.assetType}`}</MenuItem>
                      ))}
                    </TextField>
                    </Paper>
                </Box>
              </Stack>
              <Stack direction="column" alignItems={'flex-end'}>
                       <Button
                        sx={{position:'absolute',top: 100}}
                        variant="outlined"
                        onClick={updatePage}
                        startIcon={<Icon icon={refresh} />}
                        >
                        Refresh
                      </Button>
                      <Typography variant='subtitle'  sx={{position:'absolute',top: 140,fontSize: '12px', fontWeight: 400}}>Last Update: {lastUpdateTime}</Typography>
               </Stack>
          </Stack>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <SuperUserStats serviceSummary={serviceStatusData} deviceSummaryData={deviceSummaryData} deviceStateSummaryData={deviceStateSummaryData}/>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Card sx={{px: 2, py:2}}>
            {selectedEnterpriseId === 'All' && currentRole === 'ROLE_SYS_ORG_ADMIN' ? <Grid container  sx={{mt:0.5}}>
                <Grid item xs={8} md={8} lg={8} sx={{px:2,borderRight: `3px solid rgb(195, 195, 201, 0.1)`}}>
                 {noOnboardData ? <>
                                    <Typography  variant='h6' sx={{mb:2,pb:2, mx:3, borderBottom: `3px solid rgb(195, 195, 201, 0.1)`}}>Onboard Summary</Typography>
                                    <StackedBarChart year={year} seriesData={seriesData} />
                                  </> : <Stack direction={'row'} justifyContent="center" alignItems={'center'} sx={{height:'100%'}}>
                                              <Stack>
                                                  <Typography variant='h6'>No Data</Typography>
                                              </Stack>
                                        </Stack>}
                </Grid>
                <Grid item xs={4} md={4} lg={4} sx={{px:2}}>
                  {ticketData ? <>
                                    <Typography  variant='h6' sx={{mb:2,pb:2, mx:3, borderBottom: `3px solid rgb(195, 195, 201, 0.1)`}}>Support Ticket Summary</Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} md={6} lg={6}>
                                            <FlexDetailsItem title="New Ticket"  value={ticketData?.newTickets} iconName={<AutoModeIcon />}/>
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6}>
                                            <FlexDetailsItem title="In Progress Ticket"  value={ticketData?.inProgressTickets} iconName={<InprogressIcon />}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} md={6} lg={6}>
                                            <FlexDetailsItem title="Closed Ticket"  value={ticketData?.closedTickets} iconName={<ClosedIcon />}/>
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6}>
                                            <FlexDetailsItem title="Rejected Ticket"  value={ticketData?.rejectedTickets} iconName={<RejectedIcon />}/>
                                        </Grid>
                                    </Grid>
                                   </> : <Stack direction={'row'} justifyContent="center" alignItems={'center'} sx={{height:'100%'}}>
                                              <Stack>
                                                  <Typography variant='h6'>No Data</Typography>
                                              </Stack>
                                        </Stack>}
                </Grid>
             </Grid> : <Grid container  sx={{mt:0.5}}>
                    <Grid item xs={12} md={12} lg={12} sx={{px:2}}>
                          {ticketData ? <>
                                            <Typography  variant='h6' sx={{mb:2,pb:2, mx:3, borderBottom: `3px solid rgb(195, 195, 201, 0.1)`}}>Support Ticket Summary</Typography>
                                            <Grid container spacing={3}>
                                                <Grid item xs={3} md={3} lg={3}>
                                                    <FlexDetailsItem title="New Ticket"  value={ticketData?.newTickets} iconName={<AutoModeIcon />}/>
                                                </Grid>
                                                <Grid item xs={3} md={3} lg={3}>
                                                    <FlexDetailsItem title="In Progress Ticket"  value={ticketData?.inProgressTickets} iconName={<InprogressIcon />}/>
                                                </Grid>
                                                <Grid item xs={3} md={3} lg={3}>
                                                    <FlexDetailsItem title="Closed Ticket"  value={ticketData?.closedTickets} iconName={<ClosedIcon />}/>
                                                </Grid>
                                                <Grid item xs={3} md={3} lg={3}>
                                                    <FlexDetailsItem title="Rejected Ticket"  value={ticketData?.rejectedTickets} iconName={<RejectedIcon />}/>
                                                </Grid>
                                            </Grid>
                                          </> : <Stack direction={'row'} justifyContent="center" alignItems={'center'} sx={{height:'100%'}}>
                                                      <Stack>
                                                          <Typography variant='h6'>No Data</Typography>
                                                      </Stack>
                                                </Stack>}
                        </Grid>
                    </Grid> }
             </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
            <Paper
                  component="form"
                  sx={{ display: 'flex', alignItems: 'center', width: 320, mt:3 }}
                  >
                  <TextField
                    sx={{ flex: 1 }}
                    select
                    value={filterType}
                    onChange={handleFilterType}
                    size="small"
                  >
                    {filterTypeOptions.map((option) => (
                      <MenuItem key={`${option.value}!@$`} value={option.value}>{option.name}</MenuItem>
                    ))}
                  </TextField>
                  {filterType === 'serviceStatus' ? 
                  <TextField
                    sx={{ flex: 1 }}
                    select
                    value={selectedStatus}
                    onChange={handleServiceStatus}
                    size="small"
                  >
                  {statusList.map((option, index) => (
                        <MenuItem key={`${index}!$`} value={option}>{option}</MenuItem>
                    ))}
                  </TextField> : '' }
                  {filterType === 'commGatewayState' ? 
                  <TextField
                    sx={{ flex: 1 }}
                    select
                    value={selectedDeviceStatus}
                    onChange={handleDeviceStatus}
                    size="small"
                  >
                  {deviceStatusList.map((option, index) => (
                        <MenuItem key={`${index}1!$`} value={option}>{option}</MenuItem>
                    ))}
                  </TextField> : '' }
                  {filterType !== 'serviceStatus' && filterType !== 'commGatewayState' ? 
                  <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  value={searchText}
                  inputProps={{ 'aria-label': 'Search' }}
                  onChange={handleSearchChange}
                /> : ''}
                </Paper>
        </Grid>
        <Card sx={{ my: 3 }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <EnterpriseListHead
                  headLabel={TABLE_HEAD}
                />
                <TableBody>
                  {commGatewayList.map((row, index) => {
                    const { commGatewayID, orgID, enterpriseID, onboardingState, commGatewayState, assetInfo, connectionDetail } = row;

                    return (
                      <TableRow
                        key={`${commGatewayID.rsn}->${orgID}->${index}`}
                      >
                        <TableCell align="center">{`${commGatewayID.rsn}`}</TableCell>
                        <TableCell align="center">{`${assetInfo.assetCategory}`}</TableCell>
                        <TableCell align="center">{assetInfo.assetType}</TableCell>
                        <TableCell align="center">{enterpriseID}</TableCell>
                        <TableCell align="center">{onboardingState}</TableCell>
                        <TableCell align="center">{commGatewayState}</TableCell>
                        <TableCell align="center" style={{ width: 100 }}>
                          <Stack direction="row" spacing={1}>
                             <Button size="small" onClick={() => goToDetails(row)} variant="outlined" color="success" endIcon={<InfoIcon />}>
                              Details
                            </Button>
                            {currentRole !== 'ROLE_SYS_B2B2C_ADMIN' ? //<Link  target="_blank" to={"/dashboard/asset/123"}>
                            <Button onClick={()=> goToAssetDashboard(commGatewayID.rsn, enterpriseID, assetInfo.assetCategory)} sx={{width: 155 }} disabled={onboardingState !== 'ASSETPROVISIONED'}  size="small" variant="outlined" color="primary" endIcon={<FactoryIcon />}>
                              Asset Dashboard
                            </Button> : ''}
                             {/* </Link> : ''} */}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {commGatewayList.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={12}>No Device Present</TableCell>
                    </TableRow>
                  ) : ''}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <DeviceDetails openForm={openDetailsModal} handleCreateModal={handleDetailsModal} details={details}/>
    </Container>
  </Page>
  );
}

export default Dashboard;
