import React, { useState, useEffect } from 'react';

// ----------------------------------------------------------------------
import ReactApexChart from 'react-apexcharts';


export default function BatteryMixLineChart({chargingCurrent, batteryPackTempData, batteryPackVoltage, title}) {
  let state = {
    series: [
    {
      name: title,
      type: 'line',
      data: chargingCurrent
    },
    {
      name: 'Battery Pack Temp',
      type: 'line',
      data: batteryPackTempData
    },
    {
      name: 'Battery Pack Voltage',
      type: 'line',
      data: batteryPackVoltage
    }],
    options: {
      chart: {
        type: 'line',
        stacked: false
      },
      colors: ['rgb(0, 227, 150)', 'rgb(255, 69, 96)', 'rgb(254, 176, 25)'],
      noData: {
        text: 'Loading',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: 'gray',
          fontSize: '26px',
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [3, 3, 3, 3, 3],
        curve: 'straight',
      },
      xaxis: {
        type: 'datetime',
        labels: {
          formatter: function(value) {
            let date = new Date(value);
            let yr = date.getFullYear();
            let mo = date.getMonth() + 1;
            let day = date.getDate();
            let hours = date.getHours();
            let hr = hours < 10 ? '0' + hours : hours;
            let minutes = date.getMinutes();
            let min = (minutes < 10) ? '0' + minutes : minutes;
            let newDateString = day + '/' + mo;
            let newTimeString = hr + ':' + min;
            let excelDateString = newDateString + ' ' + newTimeString;
            return excelDateString
          },
        }
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
  
          },
          labels: {
            formatter: function(value){
              return value.toFixed(2)
            }
          },
          title: {
            text: "Value",
            style: {
              fontFamily:'Rubik',
              fontSize: '14px',
              fontWeight: 400,
              color:'#4A484A'
            }
          },
        }
      ],
      legend: {
        horizontalAlign: 'center',
        itemMargin: {
          vertical: 15
      },
      }
    }
  }
  return (<ReactApexChart options={state.options} series={state.series} type="line" height={'100%'} />)
}
