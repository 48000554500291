import auth from './auth';
import axiosApiInstance from './axiosInstance';
import { dev } from '../config';

const getCommGatewayList = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/factory/commgateway`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getCommGatewayDetails = (rsn) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/factory/commgateway/rsn/${rsn}`, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getAssetCategoryList = () => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/assetCategory`, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getPlanList = () => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/connection/plan`, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const createDevice = (body) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .post(`${dev.loggedInAssetURL}/factory/commgateway`, body, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const editTarget = (year, body) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .put(`${dev.loggedInAssetURL}/commgateway/factorytarget/${year}`, body, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};


const createTarget = (body) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .post(`${dev.loggedInAssetURL}/commgateway/factorytarget`, body, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const updateDevice = (body) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .put(`${dev.loggedInAssetURL}/factory/commgateway`, body, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};


const getFactoryStatus = (rsn) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInDataURL}/factorystatus`, {
      params:{rsn:rsn},
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const deleteDevice = (rsn) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .delete(`${dev.loggedInAssetURL}/factory/commgateway/rsn/${rsn}`, {
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getDeviceSearch = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/factory/search/commgateway`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getFactoryStats = (selectedYear, params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/commgateway/factorystats/${selectedYear}`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};

const getInventoryCount = (params) => {
  let accessToken = auth.getCurrentAccessToken();
  let headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axiosApiInstance
    .get(`${dev.loggedInAssetURL}/commgateway/factorystats/inventory`, {
      params:params,
      headers: headers
    })
    .then((response) => {
      return response.data;
    })
};


let modules  =  {
  getPlanList,
  getCommGatewayDetails,
  getAssetCategoryList,
  getCommGatewayList,
  createDevice,
  updateDevice,
  getFactoryStatus,
  deleteDevice,
  getDeviceSearch,
  getFactoryStats,
  getInventoryCount,
  createTarget,
  editTarget
};
export default modules