import React, { useState, useEffect } from 'react';
// material
import { Box, Typography } from '@mui/material';
import SpeedMeter from '../../Assets/SpeedMeter.svg';
import RpmMeter from '../../Assets/RpmMeter.svg';

// ----------------------------------------------------------------------



export default function SpeedRpmMeter({ type, value, unit }) {
  return <Box sx={{backgroundColor:'#FFFFFF',textAlign:'center', border: '1px solid rgba(147, 145, 160, 0.3)', width: '78px', height:'78px', borderRadius:'50%'}}>
          <img src={type === 'speed'? SpeedMeter : RpmMeter } style={{marginLeft:'auto',marginRight:'auto',marginTop:'10px',marginBottom:'3px'}}></img>
          <Typography sx={{fontSize:'13px', fontWeight:700, color:'table.headerText'}}>{value} {unit}</Typography>
  </Box>
}
