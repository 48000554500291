// material
import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------
export default function AppNewUsers({name, value}) {
  const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    padding: theme.spacing(1, 0),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText
  }));
  return (
    <RootStyle>
       <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {name}
      </Typography>
      <Typography variant="h3">{value}</Typography>
    </RootStyle>
  );
}
